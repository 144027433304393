import react from 'react';

const Overview = ({color}) => {

    return (
        <svg width="51" height="60" viewBox="0 0 51 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50.1287 50.7051C50.4904 51.0668 50.4904 51.6544 50.1287 52.0169L48.8161 53.3294C48.4537 53.6904 47.8668 53.6904 47.5043 53.3294L38.3209 44.1445C38.2207 44.0444 38.1542 43.9267 38.1097 43.8017C36.2263 45.1779 33.9133 45.9993 31.4 45.9993C25.1048 45.9993 20 40.896 20 34.6C20 28.304 25.104 23.2 31.4 23.2C37.696 23.2 42.8 28.304 42.8 34.6C42.8 37.1126 41.9779 39.4256 40.6017 41.3097C40.7274 41.3542 40.8444 41.4193 40.9445 41.5209L50.1287 50.7051ZM31.4 27.001C27.2029 27.001 23.8003 30.4037 23.8003 34.6008C23.8003 38.7971 27.2029 42.1998 31.4 42.1998C35.5971 42.1998 38.9998 38.7971 38.9998 34.6008C39.0005 30.4037 35.5978 27.001 31.4 27.001Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.2C0 1.43262 1.43271 0 3.2 0H40.8C42.5673 0 44 1.43262 44 3.2V28.3297C41.8441 23.4203 37.0135 20 31.4 20C23.7785 20 17.6 26.3047 17.6 34.0816C17.6 41.8586 23.7795 47.7951 31.4 47.7951C34.4424 47.7951 36.0047 47.2 38.4047 46.4L39.8557 47.6232L44.0777 51.9322L44 56.8C44 58.5674 42.5673 60 40.8 60H3.2C1.43271 60 0 58.5674 0 56.8V3.2ZM5.59531 18.6395H38.4V15.4242H5.59531V18.6395ZM5.59531 7.12188V10.3371H38.4V7.12188H5.59531ZM5.59531 26.941H16.9105C17.5649 25.765 18.3713 24.6883 19.2978 23.7258H5.59531V26.941ZM5.59531 32.0283V35.2436H15.0065C15.002 35.1539 14.996 35.0646 14.9899 34.9756C14.9778 34.7965 14.9657 34.6176 14.9657 34.4355C14.9657 33.6146 15.0489 32.8146 15.1721 32.0283H5.59531ZM5.6 49.1016H38.4047V52.3168H5.6V49.1016ZM5.6 40.8H16.9152C17.5696 41.976 18.376 43.0527 19.3024 44.0152H5.6V40.8Z" fill={color} />
            <path d="M44 43.6977V39.8068C43.6001 40.7154 43.1088 41.5734 42.539 42.3695C42.6911 42.4246 42.8327 42.5049 42.9539 42.6303L44 43.6977Z" fill={color} />
        </svg>

    )
}

export default Overview