import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import BaseRouter, { InviteRoute, ResetPasswordRoute } from "./routes/Routes";
import { AppContext } from "./routes/ContextLib";
import './App.css';
import "./static/common.css";
import Header from './commonComponents/Header';
import Sidebar from './commonComponents/Sidebar';
import Login from './pages/Login';
import { GetTranslatedConstants, t } from './translations/Translation';
import ResetForgotPassword from './components/molecules/ResetForgotPassword';
import { APICALL as AXIOS } from './services/AxiosServices';
import { GetPermissionsApiUrl } from './routes/ApiEndPoints';


function App() {

   const [auth, setAuth] = useState(localStorage.getItem('auth'));
   const [selectedCompany, setSelectedCompany] = useState("");
   const [companyList, setCompanyList] = useState([]);

   const [company, setCompany] = useState('');
   const [path, setPath] = useState(window.location.pathname)

   const GetPermissions = () => {
      if (auth === 'true' || auth === true) {
         AXIOS.service(GetPermissionsApiUrl, 'GET')
            .then((result) => {
               if (result?.success) {
                  localStorage.setItem('permissions', JSON.stringify(result.data?.permissions))
               }
            })
            .catch((error) => {
               console.log(error);
            })
      }
   }


   useEffect(() => {
      if (localStorage.getItem('auth') === null) {
         localStorage.setItem('auth', false);
      }
      setAuth(localStorage.getItem('auth'));
      GetPermissions();

   }, [auth])

   useEffect(() => {
      GetTranslatedConstants();
      GetPermissions();
      if (!localStorage.getItem('week_tab')) {
         localStorage.setItem('week_tab', 0)
      }
      if (!localStorage.getItem('active_language')) {
         localStorage.setItem('active_language', 'nl')
      }
      if (!localStorage.getItem('dashboard_access_token')) {
         localStorage.setItem('dashboard_access_token', 'null')
      }
   }, [])



   const onCompanySelect = (e, reload) => {
      let last = JSON.parse(localStorage.getItem('company_id'))
      if (e && e.value === undefined) {
         let companyData = companyList.filter(item => { return item.value === e })
         setSelectedCompany(companyData)
         localStorage.setItem('company_id', e);
      } else {
         if (!companyList.includes(e) && reload === 'company-creation') {
            let companies = [...companyList]
            companies.push(e);
            setCompanyList(companies);
         }
         setSelectedCompany(e);
         localStorage.setItem('company_id', e.value);
      }
      if (reload === '' || reload === undefined) {
         window.location.reload();
      } else {
        if (last !== (e.value) && reload !== 'company-creation') {
            window.location.reload();
        }
      }
   }

   useEffect(() => {
      if (company) {
         onCompanySelect(company, 'company-creation')
      }
      GetPermissions();
   }, [company])


   return (
      <div>
         {/* check for authentication of user before displaying anything*/}
         <AppContext.Provider value=''>
            <Router>
               {/* Display the contents with base routes */}
               {/* Common layout with header and sidebar */}
               {auth === 'false' && path.startsWith('/reset-password/') && <ResetPasswordRoute></ResetPasswordRoute>}
               {auth === 'false' && path.startsWith('/employee-invitations/') && <InviteRoute></InviteRoute>}
               {auth === 'true' && !path.startsWith('/employee-invitations/') && <>
                  <Header
                     setAuth={setAuth}
                     selectedCompany={selectedCompany}
                     setSelectedCompany={setSelectedCompany}
                     onCompanySelect={onCompanySelect}
                     companyList={companyList}
                     setCompanyList={setCompanyList}
                  ></Header>
                  <div className='col-md-12 p-0 d-flex'>
                     <Sidebar></Sidebar>
                     <BaseRouter setAuth={setAuth} setCompany={setCompany}></BaseRouter>
                  </div>
               </>}
               {auth === 'true' && path.startsWith('/employee-invitations/')&&<div className='text-center'><h3 className='mt-5' id="text-indii-blue">{t("PLEASE_LOGOUT_TO_OPEN_LINK")}</h3></div>}
               {auth === 'false' && !path.startsWith('/employee-invitations/') && !path.startsWith('/reset-password/') &&
                  <Login setAuth={setAuth}></Login>
               }
            </Router>
         </AppContext.Provider>
      </div>
   );
}

export default App;
