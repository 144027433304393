import React from "react";
import CalendarLayout from "../../utilities/calendar/CalendarLayout";
import CustomButton from "../atoms/CustomButton";
import Table from "../atoms/Table";
import { useNavigate } from "react-router-dom";
import { t } from "../../translations/Translation"

export default function EventPlanning() {

    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];
    const navigate = useNavigate();

    const tableHeaders = [
        {
            title: t("EVENT_NAME"),
            field: "event_name",
            status: "200",
            render: rowData => (
                <a href="/event-employees">{rowData.event_name}</a>
            ),
        },
        {
            title: t("EVENT_LOCATION"),
            field: "location",
            status: "200",
        },
        {
            title: t("FROM_DATE"),
            field: "from_date",
            status: "200",
        },
        {
            title: t("TO_DATE"),
            field: "to_date",
            status: "200",
        },
        {
            title: t("EXTRA_INFO"),
            field: "extra_info",
            status: "200",
        },
    ]

    const viewAction = () => {

    }

    const eventData = [
        { 'id': 1, 'event_name': 'Event 1', 'location': 'Bangalore', 'from_date': '25-02-1014', 'to_date': '16-03-2024', 'extra_info': 'Few tickets left' },
        { 'id': 2, 'event_name': 'Event 2', 'location': 'Belgaum', 'from_date': '25-02-1014', 'to_date': '16-03-2024', 'extra_info': 'Few tickets left' },
        { 'id': 3, 'event_name': 'Event 3', 'location': 'Mumbai', 'from_date': '25-02-1014', 'to_date': '16-03-2024', 'extra_info': 'Few tickets left' },
        { 'id': 4, 'event_name': 'Event 4', 'location': 'Bangalore', 'from_date': '25-02-1014', 'to_date': '16-03-2024', 'extra_info': 'Few tickets left' },
    ]


    return (
        <>
            <div className="d-flex col-md-12 p-0 mt-2">
                <div className="float-left col-md-3 p-0">
                    <CalendarLayout view={'event_planning'}></CalendarLayout>
                    {UserPermissions?.includes('modify_event_plannings') && <CustomButton buttonName={t("ADD_EVENT")+" +"} CustomStyle={'col-md-12 m-0 mt-2 event-button'} ActionFunction={() => navigate('/add-event')}></CustomButton>}
                </div>
                <div className="float-right w-75 p-0 ml-3 bg-white d-flex">
                    <h5 className="mx-auto text-secondary align-self-center">No event is happening</h5>
                </div>
            </div>
            <div className="col-md-12 p-0 mt-3">
                <h4 className="px-3 py-2 mb-2 bg-white" id="text-indii-dark-blue">Events overview</h4>
                <Table columns={tableHeaders} rows={eventData} tableName={"workstation"} viewAction={viewAction} permission={UserPermissions?.includes('modify_event_plannings')} ></Table>
            </div>
        </>
    )
}
