import React from "react";
import { useState, useEffect } from "react";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import FileInput from "../atoms/FileInput";
import BackIcon from "../../static/icons/BackIcon.png";
import DeleteIcon from "../../static/icons/Delete.svg"
import { useNavigate } from "react-router-dom";
import { t } from "../../translations/Translation"
import { StoreFaqFileApiURL, GetFaqFileApiUrl } from "../../routes/ApiEndPoints";
import CustomButton from "../atoms/CustomButton";
import { toast, ToastContainer } from "react-toastify";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import PdfViewer from "../atoms/PdfViewer";
import { APICALL } from "../../services/AxiosServices";
import LoadingIcon from "../../utilities/LoadingIcon";

export default function FaqOverview() {

    const [link, setLink] = useState("")
    const [isFileAvailable, setIsFileAvailable] = useState(false)
    const [formData, setFormData] = useState({
        "file": ""
    })
    const [errors, setErrors] = useState([]);
    const [reRender, setReRender] = useState(false);
    const navigate = useNavigate()
    // const [isPathFaq, setIsPathFaq] = useState(window.location.pathname === '/faq')

    useEffect(() => {
        AXIOS.service(GetFaqFileApiUrl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setLink(result?.data?.url)
                    setIsFileAvailable(true)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [reRender])

    const uploaddFile = () => {
        const fileData = new FormData();
        fileData.append("file", formData.file)
        AXIOS.service(StoreFaqFileApiURL, 'POST', fileData, true)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setReRender(!reRender)
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const setValue = (index, name, value, field) => {

        setFormData((prev) => ({
            ...prev, [name]: value
        }))
    }

    return (
        <div className="right-container">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ('!')}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {<div className=" company-tab-width bg-white m-3 border">
                <h2 id="text-indii-blue" className=" px-4 pt-4 mb-0 ml-2 d-flex align-items-center"><img className="shortcut-icon mr-2 pointer" onClick={() => navigate("/configurations")} src={BackIcon}></img>{t('FAQ_OVERVIEW')}</h2>
                <div className="text-center d-flex justify-content-center mt-5 pt-3">
                    {isFileAvailable && <div className="col-6 d-flex justify-content-center mt-5 mb-5">
                        <a className="underline" href={link} download={true} target="_blank">{t("DOWNLOAD_FAQ")}</a>
                    </div>}
                    {!isFileAvailable && <p>{t("UPLOAD_FAQ_FILE")}</p>}
                </div>
                <div className="mt-3 col-12 text-center d-flex justify-content-center">
                    <div className="col-6 m-0">
                        <FileInput
                            CustomStyle="col-12 m-0 p-0"
                            name="file"
                            setValue={setValue}
                            accept={'.pdf'}
                        ></FileInput>
                        <CustomButton
                            CustomStyle="mt-4"
                            buttonName={t('UPLOAD_NEW')}
                            ActionFunction={() => uploaddFile()}
                        ></CustomButton>
                    </div>
                </div>
            </div>}
            {/* {isPathFaq && <div className="company-tab-width m-3 border ">
                {/* bg-gradient overflow-auto position-relative */}
                {/* {link&&<PdfViewer file={APICALL.proxy_url + link}></PdfViewer>} */}
                {/* <object className="" data={link} type="application/pdf" width="100%" height="100%"></object> */}
                {/* {!link && <div ><LoadingIcon></LoadingIcon></div>}
            </div>} */}
        </div>
    )
} 