import React, { useEffect, useState } from "react";
import { t } from "../translations/Translation";
import FormsNew from "../components/molecules/FormsNew";
import { APICALL as AXIOS } from "../services/AxiosServices";
import { FilterOptionsApiUrl, GetWorkedEmployeesApiUrl, ExportEmployeeRecordApiUrl, ProcessPlanningsApiUrl, GetLatePlanstopRequestsCountApiUrl } from "../routes/ApiEndPoints";
import CustomButton from "../components/atoms/CustomButton";
import DownArrowIcon from "../static/icons/arrow.png"
import Table from "../components/atoms/Table";
import ErrorPopup from "../utilities/popup/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ExportSocialSecretaryPopup from "../components/molecules/ExportSocialSecretaryPopup";
import BulkApproveLateStopPlans from "../components/molecules/BulkApproveLateStopPlans";

export default function MonthlyOverview() {

    const [locationArr, setLocationArr] = useState([]);
    const [employeeTypeArr, setEmployeeTypeArr] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    let filters = JSON.parse(localStorage.getItem('reporting_filters'))

    const [selectedLocation, setSelectedLocation] = useState(filters?.selected_locations);
    const [selectedEmployeeType, setSelectedEmployeeType] = useState(filters?.selected_emp_types);
    const [errors, setErrors] = useState([]);
    const [loadingPopup, setLoadingPopup] = useState(false);

    const navigate = useNavigate();
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];


    const [filterState, setFilterState] = useState(true);
    const [filterDataObject, setFilterDataObject] = useState(localStorage.getItem('reporting_filters') ? JSON.parse(localStorage.getItem('reporting_filters')) : {
        "locations": "",
        "start_date": "",
        "end_date": "",
        "employee_types": "",
        "selected_locations": "",
        "selected_emp_types": "",
    })
    const [showPopup, setShowPopup] = useState(false)
    const [showApprovePlanPopup, setShowApprovePlanPopup] = useState(false)
    const [stopPlanRequestCount, setStopPlanRequestCount] = useState("")

    const getCount = () => {
        console.log(stopPlanRequestCount);
        AXIOS.service(GetLatePlanstopRequestsCountApiUrl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setStopPlanRequestCount(result.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (filters) {
            OnSave();
        }
    }, [])

    useEffect(() => {
        AXIOS.service(FilterOptionsApiUrl, 'POST')
            .then((result) => {
                if (result?.success) {
                    setLocationArr(result.data.locations);
                    setEmployeeTypeArr(result.data.employee_types);
                }
            })
            .catch((error) => {
                console.log(error);
            })
        getCount()
    }, [])

    const SetValues = (index, name, value, field) => {
        let filter_data = { ...filterDataObject }
        if (field !== 'dropdown') {
            filter_data[name] = value
        } else {
            if (name === 'employee_type_id') {
                setSelectedEmployeeType(value);
                filter_data['selected_emp_types'] = value
                let arr = []
                value.map((val, i) => {
                    arr.push(val.value)
                })
                filter_data['employee_types'] = arr;
            } else {
                setSelectedLocation(value);
                filter_data['selected_locations'] = value
                let arr = []
                value.map((val, i) => {
                    arr.push(val.value)
                })
                filter_data['locations'] = arr;
            }
        }
        setFilterDataObject(filter_data)
    }

    const OnSave = () => {
        AXIOS.service(GetWorkedEmployeesApiUrl, 'POST', filterDataObject)
            .then((result) => {
                let filter_data = {
                    'start_date': filterDataObject['start_date'],
                    'end_date': filterDataObject['end_date'],
                }
                localStorage.setItem('reporting_filters', JSON.stringify(filterDataObject))
                if (result?.success) {
                    result.data.map((val, i) => {
                        val['index'] = i
                    })
                    setEmployeeList(result.data)
                    sessionStorage.setItem('reporting_employees', JSON.stringify(result.data))
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleApprove = () => {
        setShowApprovePlanPopup(true)
    }

    const filterData = [
        { title: t('START_DATE'), name: 'start_date', required: true, type: 'date', style: "col-md-3 float-left" },
        { title: t('END_DATE'), name: 'end_date', required: true, type: 'date', style: "col-md-3 float-left" },
        { title: t("LOCATION"), name: 'location_id', required: false, options: locationArr, selectedOptions: selectedLocation, isMulti: true, type: 'dropdown', style: "col-md-3 float-left" },
        { title: t("EMPLOYEE_TYPE"), name: 'employee_type_id', required: false, options: employeeTypeArr, selectedOptions: selectedEmployeeType, isMulti: true, type: 'dropdown', style: "col-md-3 float-left" },
        // { title: t('EMPLOYEES_TITLE'), name: 'employee_id', required: true, options: employeeList, selectedOptions: selectedEmployees, isMulti: true, type: 'dropdown', style: "col-md-3 mt-1 float-left" },
    ]

    const headers = [
        {
            title: t("EMPLOYEE_TITLE"),
            field: 'name',
            sorting: true,
            render: rowData => (
                <p className={rowData.work_status ? "" : "font-weight-bold text-danger mb-0"}>{rowData.name}</p>
            ),
        },
        {
            title: t("PLANNED_HOURS"),
            field: 'planned_hours',
            sorting: true,
        },
        {
            title: t("WORKED_HOURS"),
            field: 'worked_hours',
            sorting: true,
        },
    ];


    const viewAction = (data, action) => {
        // EmployeeDetailView(data.id);
        navigate('/monthly-overview/' + data.id)
    }


    const handleExport = () => {
        setShowPopup(true)
        // AXIOS.service(ExportEmployeeRecordApiUrl, 'POST', filterDataObject)
        //     .then((result) => {
        //         if (result?.success) {
        //             const link = document.createElement('a');
        //             link.href = result.data.url;
        //             link.setAttribute('download', 'ExportedData.zip');
        //             document.body.appendChild(link);
        //             link.click();
        //             link.parentNode.removeChild(link);
        //         } else {
        //             setErrors(result.message)
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     })
    }
    const processPlannings = () => {
        setLoadingPopup(true)
        AXIOS.service(ProcessPlanningsApiUrl, 'POST', filterDataObject)
            .then((result) => {
                if (result?.success) {
                    setLoadingPopup(false)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                    setLoadingPopup(false)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const onHide = () => {
        setShowPopup(false);
    }

    return (
        <div className="right-container">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {showApprovePlanPopup && <BulkApproveLateStopPlans showApprovePlanPopup={showApprovePlanPopup} setShowApprovePlanPopup={setShowApprovePlanPopup} getCount={getCount}></BulkApproveLateStopPlans>}
            {showPopup && <ExportSocialSecretaryPopup show={showPopup} onHide={onHide} employeeTypeArr={employeeTypeArr}></ExportSocialSecretaryPopup>}
            {loadingPopup && <div className="d-flex justify-content-center align-items-center" id="loading-icon">
                <div className="spinner-border custom-loading-icon"></div></div>}
            <div className="company-tab-width">
                {errors !== undefined && errors.length !== 0 && <ErrorPopup
                    title={t("VALIDATION_ERROR") + ("!")}
                    body={(errors)}
                    onHide={() => setErrors([])}
                ></ErrorPopup>}
                <h3 className="text-center mt-2 py-1 text-color bg-white font-weight-bold">{t("MONTHLY_OVERVIEW")}</h3>
                <div className="pl-4 bg-white mb-2">
                    <div className={"d-flex justify-content-between mr-4" + (filterState ? " border-bottom" : '')}>
                        <h5 className="text-left pt-3 pb-1 text-color font-weight-bold">{t('FILTERS')}</h5>
                        <img alt={t("FILTERS")} className="header-icon mt-3 mr-3" src={DownArrowIcon} onClick={() => setFilterState(!filterState)}></img>
                    </div>
                    {filterState && <FormsNew
                        view="filters"
                        formTitle={''}
                        formattedData={filterDataObject}
                        data={filterData}
                        SetValues={SetValues}
                    ></FormsNew>}
                    {filterState && <div className="d-flex justify-content-end pb-2 pr-0 col-md-12">
                        <CustomButton buttonName={t("APPROVE")} notification={stopPlanRequestCount} ActionFunction={() => handleApprove()} CustomStyle="mr-2 float-right"></CustomButton>
                        <CustomButton buttonName={t("PROCESS_PLANNINGS")} ActionFunction={() => processPlannings()} CustomStyle="mr-2 float-right"></CustomButton>
                        <CustomButton buttonName={t("EXPORT")} ActionFunction={() => handleExport()} CustomStyle="mr-2 float-right"></CustomButton>
                        <CustomButton buttonName={t("SEARCH_TEXT")} ActionFunction={() => OnSave()} CustomStyle="mr-5 float-right"></CustomButton>
                    </div>}
                </div>
                <Table columns={headers} rows={employeeList} tableName="documents_overview" viewAction={viewAction} permission={UserPermissions?.includes('monthly_overview_modify')} viewPermission={UserPermissions?.includes('monthly_overview_employee_overview')}></Table>
            </div>
        </div>
    )
}