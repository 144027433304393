import React, { useState, useEffect } from "react";
import { BASE_URL, GetHolidayCodesToLinkApiUrl, SocialSecretaryWorkCodeApiUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import TextInput from "../atoms/formFields/TextInput";
import BackIcon from "../../static/icons/BackIcon.png"
import CustomButton from "../atoms/CustomButton";
import { t } from "../../translations/Translation";
import Dropdown from "../atoms/Dropdown";

export default function LinkHolidayCodeToSocialSecretary() {

    const navigate = useNavigate()
    const params = useParams();
    const [listData, setListData] = useState([])
    const [formData, setFormData] = useState({
        "social_secretary_id": params.id,
        "social_secretary_codes": [],
    })
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [selectedType, setSelectedType] = useState({ value: 'holiday_code', label: t("HOLIDAY_CODES") });

    let redirectURL = "/manage-configurations/social_secretary";

    const linkTypes = [
        { value: 'holiday_code', label: t("HOLIDAY_CODES") },
        { value: 'work_code', label: t("WORKCODES") },
    ]

    useEffect(() => {

        let apiUrl = (selectedType?.value === 'holiday_code' ? GetHolidayCodesToLinkApiUrl : SocialSecretaryWorkCodeApiUrl) + '/' + params.id
        // Api call to get list data
        AXIOS.service(apiUrl, 'GET')
            .then((result) => {
                if (result?.success) {
                    let response = result.data.details
                    setListData(response);
                    //this is to remove holiday_code_name and holiday_code from response to set formData
                    let ss_codes_array = response.map((data) => {
                        if (selectedType?.value === 'holiday_code') {
                            const { holiday_code_name, holiday_code, ...rest } = data;
                            return rest;
                        } else {
                            const { name, ...rest } = data;
                            return rest;
                        }
                    })
                    console.log(ss_codes_array);
                    formData['social_secretary_codes'] = ss_codes_array;
                    setFormData(formData);
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }, [selectedType])

    //setting values
    const SetValues = (id, value, index) => {
        let form_data = { ...formData }
        console.log(form_data, value);
        if (selectedType?.value === 'holiday_code') {
            form_data['social_secretary_codes'][index]['social_secretary_code'] = value
        } else {
            form_data['social_secretary_codes'][index]['external_code'] = value
        }
        setFormData(form_data)
    }

    function OnSave() {

        let apiUrl = selectedType?.value === 'holiday_code' ? GetHolidayCodesToLinkApiUrl : SocialSecretaryWorkCodeApiUrl + '/' + params.id
        // APICall for create and updation of social secretary
        AXIOS.service(apiUrl, 'PUT', formData)
            .then((result) => {
                if (result?.success) {
                    setSuccessMessage(result.message);
                    navigate(redirectURL);
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return (
        <div className="right-container">
            {/* {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={('Validation error!')}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>} */}
            <div className="form-container my-3">
                <h3 className="col-md-12 px-3 pt-2 mb-0 ml-0 bg-white text-color"><img className="shortcut-icon mr-2 mb-1 pointer" onClick={() => navigate(redirectURL)} src={BackIcon}></img>{t("MANAGE_SOCIAL_SECRETARY_CODES")}</h3>
                <div className="bg-white my-2">
                    <Dropdown
                        options={linkTypes}
                        selectedOptions={selectedType}
                        onSelectFunction={(e) => setSelectedType(e)}
                        CustomStyle="my-2 py-3 col-md-3"
                        title={t("LINK_CODE_TO")}
                        required={true}
                        isMulti={false}
                    ></Dropdown>
                    <div className="row m-0 my-2 px-5 pt-3 table-head-bg">
                        <div className="col-md-4 text-start">
                            <h5>{selectedType?.value === 'holiday_code' ? t("HOLIDAY_CODE_NAME") : t('WORKCODE_NAME')}</h5>
                        </div>
                        <div className="col-md-4">
                            <h5>{t("INETRNAL_CODE")}</h5>
                        </div>
                        <div className="col-md-4">
                            <h5>{t("SOCIAL_SECRETARY_CODE")}</h5>
                        </div>
                    </div>
                    {listData.map((val, i) => {
                        return (<div className="row m-0 px-5 mx-3 mb-2" key={val.holiday_code_name}>
                            <div className="col-md-4">
                                <h6 className="p-2">{selectedType?.value === 'holiday_code' ? val.holiday_code_name : val.name}</h6>
                            </div>
                            <div className="col-md-4">
                                <p className="p-2 text-secondary">{selectedType?.value === 'holiday_code' ? val.holiday_code : val.internal_code}</p>
                            </div>
                            {selectedType?.value === 'holiday_code' ?
                                <div className="col-md-4 p-2">
                                    <TextInput setValue={(e) => SetValues(val.id, e, i)} value={formData['social_secretary_codes'][i]['social_secretary_code']} />
                                </div> :
                                <div className="col-md-4 p-2">
                                    <TextInput setValue={(e) => SetValues(val.id, e, i)} value={formData['social_secretary_codes'][i]['external_code']} />
                                </div>
                            }
                        </div>)
                    })}
                    <div className="col-md-12 mb-3 text-right pr-5 pb-3 mt-2">
                        <CustomButton buttonName={t("SAVE")} ActionFunction={() => OnSave()} CustomStyle=""></CustomButton>
                        <CustomButton buttonName={t("BACK_LINK")} ActionFunction={() => navigate(redirectURL)} CustomStyle="mr-3"></CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
}