import React from 'react'

const EmployeeType = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="108.076" height="50.066" viewBox="0 0 108.076 50.066">
        <g id="Group_27817" data-name="Group 27817" transform="translate(-219.443 -276.314)">
          <path id="Employee" d="M3973.918-7663H3939.88v-4.549c0-5.578,2.417-9.847,6.807-12.017a13.893,13.893,0,0,0,10.213,4.44,13.9,13.9,0,0,0,10.213-4.44c4.389,2.174,6.805,6.438,6.805,12.017V-7663Zm-16.91-16.806a10.724,10.724,0,0,1-10.817-10.6A10.718,10.718,0,0,1,3957.008-7701a10.717,10.717,0,0,1,10.815,10.595A10.723,10.723,0,0,1,3957.008-7679.8Z" transform="translate(-3683.399 7989.38)" fill="#073763" />
          <path id="Employee-2" data-name="Employee" d="M3973.918-7663H3939.88v-4.549c0-5.578,2.417-9.847,6.807-12.017a13.893,13.893,0,0,0,10.213,4.44,13.9,13.9,0,0,0,10.213-4.44c4.389,2.174,6.805,6.438,6.805,12.017V-7663Zm-16.91-16.806a10.724,10.724,0,0,1-10.817-10.6A10.718,10.718,0,0,1,3957.008-7701a10.717,10.717,0,0,1,10.815,10.595A10.723,10.723,0,0,1,3957.008-7679.8Z" transform="translate(-3646.399 7977.313)" fill="#073763" />
          <path id="Employee-3" data-name="Employee" d="M3973.918-7663H3939.88v-4.549c0-5.578,2.417-9.847,6.807-12.017a13.893,13.893,0,0,0,10.213,4.44,13.9,13.9,0,0,0,10.213-4.44c4.389,2.174,6.805,6.438,6.805,12.017V-7663Zm-16.91-16.806a10.724,10.724,0,0,1-10.817-10.6A10.718,10.718,0,0,1,3957.008-7701a10.717,10.717,0,0,1,10.815,10.595A10.723,10.723,0,0,1,3957.008-7679.8Z" transform="translate(-3720.437 7977.314)" fill="#073763" />
        </g>
      </svg>
    </>
  )
}

export default EmployeeType