import React, { useState, useEffect } from "react";
import FormsNew from "../molecules/FormsNew";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { TaxesApiUrl } from "../../routes/ApiEndPoints"
import { t } from "../../translations/Translation";

export default function AddTaxes() {

    const [formData, setFormData] = useState({
        "from_date": "",
        "to_date": "",
        "hourly_tax": "",
        "max_per_day": "",
        "employee_tax": "",
        "employer_tax": "",
        "year_end_bonus": "",
        "holiday_pay": "",
        "percentage_on_pay_type": "",
        "sector_id": ''
    })

    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const params = useParams();
    const [sectorList, setSectorList] = useState([])
    const [sector, setSector] = useState([])
    const [Success, setSuccess] = useState(true);


    useEffect(() => {

        AXIOS.service(TaxesApiUrl + '/create', 'GET')
            .then((result) => {
                if (result?.success) {
                    setSectorList(result.data?.sectors !== null ? result.data.sectors:[])
                }
            })
            .catch((error) => {
                console.log(error);
            })

        if (params.id !== undefined) {
            AXIOS.service(TaxesApiUrl + '/' + params.id, 'GET')
                .then((result) => {
                    if (result?.success) {
                        let data = result.data
                        data.sector_id = result.data.sector?.value
                        setFormData(data)
                        setSector(result.data.sector)
                    } else {
                        setErrors(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

        }
    }, [])


    const setValues = (index, name, value, field) => {
        const newData = { ...formData };
        if (field !== 'dropdown') {
            newData[name] = value
        } else {
            setSector(value)
            newData[name] = value.value
        }
        setFormData(newData);
    }


    const onSave = () => {

        let url = TaxesApiUrl
        let method = 'POST'

        // Update url and method
        if (params.id !== undefined) {
            url = TaxesApiUrl + '/' + params.id
            method = 'PUT'
        }
        // APICall for create and update of Taxes
        AXIOS.service(url, method, formData)
            .then((result) => {
                if (result?.success) {
                    navigate('/manage-social-secretary-and-reporting-configurations/taxes');
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSuccess(true)
                } else {
                    setErrors(result.message)
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(true)
            })

    }



    const Taxfields = [
        { title: t("TAX_FROM_DATE"), name: "from_date", required: true, type: "date", style: "col-md-6 mt-4 float-left" },
        { title: t("TAX_TO_DATE"), name: "to_date", type: "date", required: true, style: "col-md-6 float-left mt-4" },
        { title: t("HOURLY_TAX"), name: "hourly_tax", required: true, type: "text", style: "col-md-6 mt-4 float-left" },
        { title: t("DAILY_MAXIMUM_TAX"), name: "max_per_day", required: true, type: "text", style: "col-md-6 mt-4 float-left" },
        { title: t("EMPLOYEE_TAX"), name: "employee_tax", required: true, type: "text", style: "col-md-6 mt-4 float-left" },
        { title: t("EMPLOYER_TAX"), name: "employer_tax", required: true, type: "text", style: "col-md-6 mt-4 float-left" },
        { title: t("YEAR_END_BONUS"), name: "year_end_bonus", required: true, type: "text", style: "col-md-6 mt-4 float-left" },
        { title: t("HOLIDAY_PAY"), name: "holiday_pay", required: true, type: "text", style: "col-md-6 mt-4 float-left" },
        { title: t("PAY_TYPE_TAX_PERCENTAGE"), name: "percentage_on_pay_type", required: true, type: "text", style: "col-md-6 mt-4 float-left" },
        { title: t("SECTOR"), name: "sector_id", options: sectorList, isMulti: false, selectedOptions: sector, required: true, type: "dropdown", style: "col-md-6 mt-2 float-left" },
    ];

    return (
        <div className="right-container add_taxes">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <FormsNew
                view="public_holiday"
                formTitle={t("ADD_TAXES")}
                redirectURL={'/manage-social-secretary-and-reporting-configurations/taxes'}
                formattedData={formData}
                data={Taxfields}
                SetValues={setValues}
                OnSave={onSave}
                setSuccess={setSuccess}
                Success={Success}
            ></FormsNew>
        </div>
    )
}