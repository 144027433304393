import React from 'react'

const SocialSecretary = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="27.895" height="27.986" viewBox="0 0 27.895 27.986">
        <g id="Social_secretary_Bl_" data-name="Social secretary (Bl)" transform="translate(-2.366)">
          <path id="Path_56832" data-name="Path 56832" d="M29.827,433.722a.437.437,0,0,1,.433.433V436.8a.437.437,0,0,1-.433.433H2.8a.437.437,0,0,1-.433-.433v-2.641a.437.437,0,0,1,.433-.433Z" transform="translate(0 -409.244)" fill="#073763" />
          <path id="Path_56833" data-name="Path 56833" d="M29.426,158.886a28.872,28.872,0,0,1,.517,4.934q.074,2.979.147,5.957l.079,3.179A.417.417,0,0,1,30,173.3a.445.445,0,0,1-.218.089l-.043,0H26.392a.436.436,0,0,1-.433-.433v0q.042-1.683.083-3.367.074-2.979.147-5.957c.085-3.415.271-4.741.512-4.741Z" transform="translate(-22.261 -149.919)" fill="#073763" />
          <path id="Path_56834" data-name="Path 56834" d="M402.9,158.886a28.873,28.873,0,0,1,.517,4.934q.074,2.979.148,5.957.039,1.589.079,3.179a.415.415,0,0,1-.165.338.424.424,0,0,1-.23.094l-.038,0h-3.344a.437.437,0,0,1-.433-.433v0q.042-1.683.083-3.367l.147-5.957c.085-3.415.271-4.741.512-4.741Z" transform="translate(-374.659 -149.919)" fill="#073763" />
          <path id="Path_56835" data-name="Path 56835" d="M24.823,0c.173.024.1,0,.224.053l.79.394L38.076,5.426a.429.429,0,0,1,.215.374V7.5a.438.438,0,0,1-.1.274l-.059.059a.453.453,0,0,1-.274.1H11.851a.438.438,0,0,1-.433-.433V5.8a.429.429,0,0,1,.215-.374l.015-.008L24.025.343l.461-.231A.892.892,0,0,1,24.823,0Zm-.006,2.488a1.4,1.4,0,0,0-.218.018,2.048,2.048,0,0,0-1.308.811A1.9,1.9,0,0,0,25.5,6.167a1.942,1.942,0,0,0,1.247-1.734c0-.018,0,0,0-.048a1.931,1.931,0,0,0-1.326-1.809,1.75,1.75,0,0,0-.452-.089Z" transform="translate(-8.541)" fill="#073763" />
          <path id="Path_56836" data-name="Path 56836" d="M143.294,310.2l.871.031-.783,1.531.341,2.392s2.573-3.585,2.533-3.614h0c1.113.474,2.935,3.73,2.936,5.345v2.425c0,.54-.147.51-.763.51H137.193c-.616,0-.763.03-.763-.51v-2.421c0-1.693,1.949-4.926,2.936-5.349h0c-.04.029,2.534,3.614,2.534,3.614l.341-2.392-.764-1.523C142.568,310.192,141.962,310.21,143.294,310.2Z" transform="translate(-126.498 -292.695)" fill="#073763" />
          <path id="Path_56837" data-name="Path 56837" d="M191.893,173.787a3.446,3.446,0,1,1-3.445,3.446,3.446,3.446,0,0,1,3.445-3.446Z" transform="translate(-175.58 -163.979)" fill="#073763" />
        </g>
      </svg>
    </>
  )
}

export default SocialSecretary