
import React from 'react'

const Add = () => {
    return (
        <>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="24" height="24" viewBox="0 0 288 288" enable-background="new 0 0 288 288" >

                <path fill="#003F54" opacity="1.000000" stroke="none"
                    d="
M143.048462,36.999557 
	C167.179138,36.805271 189.005371,43.066204 207.992569,57.128578 
	C233.011047,75.657867 248.165588,100.324944 252.137009,131.646408 
	C254.878998,153.271729 251.716599,173.973206 241.769226,193.066086 
	C224.198715,226.790710 196.507675,247.074951 158.328964,252.051224 
	C130.558823,255.670853 105.030350,249.361099 82.436249,233.079361 
	C57.132248,214.844818 41.892944,190.137299 37.958744,158.842712 
	C34.207706,129.005081 41.399673,102.029343 59.896591,78.451431 
	C76.599434,57.160419 98.312286,43.487160 125.291916,38.954132 
	C130.996521,37.995670 136.801620,37.635509 143.048462,36.999557 
M201.667511,68.841354 
	C181.893250,53.455288 159.150360,47.748608 134.697769,50.315662 
	C108.453300,53.070827 86.947273,65.465775 70.367554,86.158661 
	C54.038372,106.538864 47.586594,130.039871 50.328575,155.629578 
	C53.121311,181.692886 65.411629,203.158173 86.030685,219.506332 
	C110.260117,238.717056 137.918701,244.738800 167.619370,237.377228 
	C198.870193,229.631454 220.945908,210.047958 233.334274,180.138931 
	C242.501617,158.006424 242.563095,135.369247 234.710236,112.995941 
	C228.477127,95.237350 217.465942,80.548332 201.667511,68.841354 
z"/>

                <path fill="#003F54" opacity="1.000000" stroke="none"
                    d="
M138.857178,87.847725 
	C151.631531,85.499672 151.997223,85.795914 151.999207,97.905197 
	C152.001099,109.399467 152.170517,120.897331 151.923004,132.386215 
	C151.827209,136.832535 153.435867,138.138855 157.727463,138.071289 
	C170.884247,137.864136 184.054825,138.278915 197.202774,137.881195 
	C203.088821,137.703140 203.063461,140.813416 203.068527,145.006607 
	C203.073608,149.215363 203.060211,152.296890 197.189362,152.119125 
	C184.041428,151.721054 170.870789,152.138046 157.714096,151.927933 
	C153.404556,151.859100 151.848312,153.200439 151.923279,157.626816 
	C152.148865,170.949570 151.874741,184.280457 152.061188,197.604431 
	C152.118912,201.727951 150.702667,203.112045 146.592651,203.074127 
	C138.016556,202.995026 138.008255,203.184509 138.003052,194.795303 
	C137.996216,183.800781 138.047531,172.806000 137.987701,161.811813 
	C137.927979,150.835556 139.086334,152.075348 127.977661,152.013367 
	C116.317154,151.948303 104.652992,151.842133 92.996719,152.072464 
	C88.566833,152.160019 86.769447,150.906876 86.906143,146.191696 
	C87.142944,138.023590 86.885544,138.009354 95.103798,138.003082 
	C107.597565,137.993561 120.093163,137.872604 132.584061,138.060181 
	C136.702057,138.122040 138.120041,136.683350 138.059952,132.575363 
	C137.874802,119.917770 137.973495,107.255569 138.025879,94.595413 
	C138.034744,92.451035 138.360077,90.307953 138.857178,87.847725 
z"/>
            </svg>


        </>
    )
}

export default Add