import React, { useState, useEffect } from "react";
import CustomTable from "../../components/atoms/CustomTable";
import { toast, ToastContainer } from 'react-toastify';
import { CompanyGroupsApiUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { t } from "../../translations/Translation";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../static/icons/BackIcon.png";
import ErrorPopup from "../../utilities/popup/ErrorPopup";

export default function ManageCompanyGroups() {

    const [listData, setListData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);


    //table headers
    const headers = [
        {
            title: t("GROUP_NAME"),
            field: 'name',
            size: 200,
        },
    ];

    useEffect(() => {
        AXIOS.service(CompanyGroupsApiUrl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setListData(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [refresh])

    // Function to call API on adding new row
    const CreateRow = (newData) => {
        const { name } = newData
        AXIOS.service(CompanyGroupsApiUrl, 'POST', { name })
            .then((result) => {
                if (result?.success) {
                    setRefresh(!refresh);
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    // Function to call API on editing the row
    const UpdateRow = (newData) => {
        const { name } = newData;
        AXIOS.service(CompanyGroupsApiUrl + "/" + newData.id, 'PUT', { name })
            .then((result) => {
                if (result?.success) {
                    setRefresh(!refresh);
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    // Function to call API on deleting the row
    const DeleteRow = (newData) => {
        AXIOS.service(CompanyGroupsApiUrl + "/" + newData.id, 'DELETE')
            .then((result) => {
                if (result?.success) {
                    setRefresh(!refresh);
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <div className="right-container" >
                <div className="company-tab-width d-flex flex-column mt-3 border bg-white">
                    <div className={"d-flex justify-content-between p-2 border-thick align-items-center"}>
                        <h4 className="text-color mb-0 d-flex align-items-center"><img className="shortcut-icon mr-2 pointer" onClick={() => navigate("/manage-companies")} src={BackIcon} alt="Back"></img>{t('MANAGE_COMPANY_GROUPS')}</h4>
                    </div>
                    <CustomTable title={""} columns={headers} rows={listData} setRows={setListData} tableName={'company_groups'} height={'calc(100vh - 162px)'} UpdateRow={UpdateRow} CreateRow={CreateRow} DeleteRow={DeleteRow}></CustomTable>
                </div>
            </div>
        </>
    );

}