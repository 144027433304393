import React, { useEffect, useState } from "react";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { ToastContainer, toast } from 'react-toastify';
import { t } from "../../translations/Translation";
import { GetOvertimeCountsApiUrl, UpdateOvertimeCountApiUrl } from "../../routes/ApiEndPoints";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import Table from "../atoms/Table";
import FormsNew from "../molecules/FormsNew";
import CustomPopup from "../../utilities/popup/CustomPopup";

export default function OvertimeCountersOverview({ eid }) {

    let employeeId = eid
    const [dataRefresh, setDataRefresh] = useState(false);
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];

    const [listData, setListData] = useState([]);
    const [errors, setErrors] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState({})

    const overtime_counter_headers = [
        {
            title: t('OVERTIME_TYPE'),
            field: 'type',
            size: 200,
        },
        {
            title: t('HOURS'),
            field: 'hours',
            size: 200,
        },
    ]

    useEffect(() => {

        AXIOS.service(GetOvertimeCountsApiUrl + "/" + employeeId, 'GET')
            .then((result) => {
                if (result?.success) {
                    let arr = []
                    result.data.map((item, index) => {
                        item.id = index
                        arr.push(item)
                    })
                    setListData(arr)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }, [dataRefresh, eid])


    const setValue = (index, name, value) => {

        let newRowData = { ...popupData }
        newRowData[name] = value
        setPopupData(newRowData)
    }

    const onSave = () => {
        let { tableData, ...data } = popupData
        data.employee_profile_id = employeeId

        AXIOS.service(UpdateOvertimeCountApiUrl, 'POST', data)
            .then((result) => {
                if (result?.success) {
                    setDataRefresh(!dataRefresh)
                    setShowPopup(false)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const viewAction = (data, action) => {

        if (action === 'edit') {
            setShowPopup(true)
            setPopupData(data)
        }

    }

    const onHide = () => {

        setShowPopup(false)
    }

    const fieldsArray = [
        { title: t("TYPE"), name: "type", required: true, type: "text", style: "col-md-12 float-left mb-3", disabled: true },
        { title: t("DATE"), name: "date", required: true, type: "date", style: "col-md-6 float-left" },
        { title: t("HOURS"), name: "hours", required: true, type: "text", style: "col-md-6 float-left" },
    ]

    let GetUpdateData = () => {
        return (
            <FormsNew
                title={t("OVERTIME_COUNTERS")}
                data={fieldsArray}
                formattedData={popupData}
                SetValues={setValue}
            ></FormsNew>
        )
    }

    return (
        <div className="mb-2">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className="mt-3 border bg-white d-flex flex-column">
                {errors !== undefined && errors.length !== 0 && <ErrorPopup
                    title={t("VALIDATION_ERROR") + ("!")}
                    body={(errors)}
                    onHide={() => setErrors([])}
                ></ErrorPopup>}
                {showPopup && <CustomPopup title={t('UPDATE_OVERTIME_COUNTER')} size='m' footer header body={GetUpdateData()} onHide={onHide} onConfirm={onSave} saveButtonName={t('SAVE')} close></CustomPopup>}
                <div className="tablescroll flex-1 d-flex flex-column bg-grey">
                    <Table tableName='overtime_counter' columns={overtime_counter_headers} rows={listData} viewAction={viewAction} permission={UserPermissions?.includes('modify_employee_details')}></Table>
                </div>
            </div>
        </div>
    )
}