import React from 'react'

const ParameterIcon = () => {
	return (
		<>
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				width="24" height="24" viewBox="0 0 128 128" enable-background="new 0 0 128 128" >
				<path fill="#073763" opacity="1.000000" stroke="none"
					d="
M114.000000,129.000000 
	C90.312439,129.000000 66.624878,129.000000 42.565369,128.664612 
	C51.470940,122.646294 53.871067,113.813339 52.926601,103.791901 
	C52.006847,94.032684 41.645256,84.388084 31.878260,84.089264 
	C28.203142,83.976807 27.960623,82.159843 27.969067,79.334724 
	C28.027769,59.694145 27.945406,40.052895 28.095409,20.413284 
	C28.108519,18.696886 29.086660,16.648520 30.266544,15.340791 
	C34.698345,10.428788 39.399666,5.759950 44.000000,0.999999 
	C63.687561,1.000000 83.375122,1.000000 103.523956,1.408440 
	C103.020561,9.386646 102.055901,16.956413 101.030853,24.999926 
	C100.443329,24.999926 99.320244,24.999924 98.197166,24.999928 
	C80.031982,24.999971 61.866493,24.955944 43.702141,25.079294 
	C42.160530,25.089764 40.626511,26.218117 39.088963,26.827145 
	C40.677925,27.551422 42.253563,28.865940 43.858063,28.902170 
	C52.852745,29.105276 61.854385,28.999996 70.853653,29.000000 
	C81.145889,29.000004 91.438133,29.000000 101.746475,29.000000 
	C101.746475,30.545031 101.746475,31.613688 101.746475,33.000000 
	C99.874397,33.000000 98.229973,32.999996 96.585548,33.000000 
	C79.086975,33.000004 61.588211,32.964016 44.090107,33.055073 
	C42.319061,33.064289 40.552006,33.839695 38.783096,34.259537 
	C38.850224,34.923260 38.917347,35.586987 38.984474,36.250713 
	C40.812168,36.500473 42.639225,36.961716 44.467655,36.967232 
	C62.466026,37.021545 80.464752,37.032738 98.463043,36.966953 
	C101.342552,36.956429 103.057426,37.422035 101.357880,41.000008 
	C82.435165,41.000008 63.456509,40.973625 44.478188,41.041451 
	C42.612259,41.048115 40.748394,41.632862 37.894775,42.117073 
	C39.315289,43.696484 39.736725,44.576405 40.161053,44.577801 
	C60.606556,44.645138 81.052307,44.636635 101.947693,44.636635 
	C101.845299,46.500149 101.781395,47.663094 101.707932,49.000000 
	C90.002449,49.000000 78.683434,48.999809 67.364426,49.000065 
	C59.031773,49.000252 50.697426,48.921120 42.368488,49.097301 
	C41.261250,49.120720 40.181465,50.442089 39.088966,51.162369 
	C40.123058,51.774269 41.095463,52.575878 42.210613,52.938492 
	C43.115108,53.232605 44.191078,52.999912 45.190998,52.999920 
	C62.856228,53.000053 80.521492,53.018776 98.186646,52.980995 
	C101.020744,52.974934 103.464455,53.022808 101.177536,57.000183 
	C81.904160,57.000183 62.579185,56.963680 43.255089,57.076588 
	C41.863121,57.084713 40.477524,58.182827 39.088966,58.774151 
	C40.493610,59.516045 41.877975,60.849812 43.306362,60.898750 
	C49.964676,61.126862 56.635147,60.999981 63.301273,60.999992 
	C76.095818,61.000015 88.890358,61.000000 101.575485,61.000000 
	C102.949898,65.069168 100.725990,65.036369 98.138336,65.028664 
	C79.973312,64.974533 61.807732,64.955101 43.643425,65.081818 
	C42.121151,65.092438 40.606930,66.257286 39.088966,66.885887 
	C40.530857,67.590546 41.910389,68.636398 43.430298,68.914192 
	C45.365498,69.267883 47.414314,68.999977 49.414150,68.999985 
	C65.746155,69.000023 82.078156,68.998009 98.410164,69.002274 
	C100.894516,69.002922 103.638603,68.748764 102.576248,73.000000 
	C91.844231,73.000000 81.190659,72.823326 70.545677,73.050034 
	C60.042480,73.273705 49.431377,72.054871 38.943550,74.915161 
	C40.585869,76.334961 42.297153,76.949814 44.010818,76.956505 
	C62.009075,77.026871 80.021942,77.405640 97.998383,76.775482 
	C104.121643,76.560837 106.691292,78.972168 107.886108,83.967148 
	C108.914833,88.267776 111.037918,90.657860 115.999786,90.037712 
	C115.999786,101.784210 116.069412,113.261040 115.911438,124.734734 
	C115.891747,126.164986 114.666588,127.578644 114.000000,129.000000 
M50.546181,85.000000 
	C67.034775,84.999992 83.523491,85.029518 100.011757,84.955750 
	C101.720398,84.948105 103.426292,84.326347 105.878418,83.842552 
	C101.667442,80.013626 42.148499,80.088089 38.654530,83.466431 
	C42.123589,86.155823 46.038082,84.545769 50.546181,85.000000 
M89.498940,109.000000 
	C83.008896,109.472404 76.315590,107.972946 69.209267,110.179474 
	C73.219101,113.923164 104.785271,113.921822 108.813644,110.156204 
	C102.375252,107.996422 96.323593,109.480186 89.498940,109.000000 
M92.485817,95.000000 
	C84.985519,95.283485 77.378723,94.380051 68.733566,95.752518 
	C73.251839,99.943466 103.795494,99.925018 107.284271,96.635391 
	C102.851578,93.500038 97.952545,95.687775 92.485817,95.000000 
M66.778923,21.000000 
	C71.109177,20.999403 75.444756,21.114639 79.765930,20.916292 
	C80.838509,20.867062 81.864471,19.802580 82.911865,19.204767 
	C81.834435,18.470375 80.786636,17.159225 79.674416,17.102091 
	C75.023315,16.863161 70.345268,16.849817 65.693779,17.084435 
	C64.132706,17.163174 62.621212,18.224703 61.087059,18.837025 
	C62.677563,19.558010 64.268066,20.278994 66.778923,21.000000 
M39.472401,14.018170 
	C38.708115,14.817458 37.943829,15.616745 36.965061,16.640337 
	C43.878708,17.214508 45.649281,15.338823 44.140839,8.788649 
	C42.598480,10.544936 41.293133,12.031338 39.472401,14.018170 
z"/>
				<path fill="#FFFFFF" opacity="1.000000" stroke="none"
					d="
M114.472656,129.000000 
	C114.666588,127.578644 115.891747,126.164986 115.911438,124.734734 
	C116.069412,113.261040 115.999786,101.784210 115.999786,90.037712 
	C111.037918,90.657860 108.914833,88.267776 107.886108,83.967148 
	C106.691292,78.972168 104.121643,76.560837 97.998383,76.775482 
	C80.021942,77.405640 62.009075,77.026871 44.010818,76.956505 
	C42.297153,76.949814 40.585869,76.334961 38.943550,74.915161 
	C49.431377,72.054871 60.042480,73.273705 70.545677,73.050034 
	C81.190659,72.823326 91.844231,73.000000 102.576248,73.000000 
	C103.638603,68.748764 100.894516,69.002922 98.410164,69.002274 
	C82.078156,68.998009 65.746155,69.000023 49.414150,68.999985 
	C47.414314,68.999977 45.365498,69.267883 43.430298,68.914192 
	C41.910389,68.636398 40.530857,67.590546 39.088966,66.885887 
	C40.606930,66.257286 42.121151,65.092438 43.643425,65.081818 
	C61.807732,64.955101 79.973312,64.974533 98.138336,65.028664 
	C100.725990,65.036369 102.949898,65.069168 101.575485,61.000000 
	C88.890358,61.000000 76.095818,61.000015 63.301273,60.999992 
	C56.635147,60.999981 49.964676,61.126862 43.306362,60.898750 
	C41.877975,60.849812 40.493610,59.516045 39.088966,58.774151 
	C40.477524,58.182827 41.863121,57.084713 43.255089,57.076588 
	C62.579185,56.963680 81.904160,57.000183 101.177536,57.000183 
	C103.464455,53.022808 101.020744,52.974934 98.186646,52.980995 
	C80.521492,53.018776 62.856228,53.000053 45.190998,52.999920 
	C44.191078,52.999912 43.115108,53.232605 42.210613,52.938492 
	C41.095463,52.575878 40.123058,51.774269 39.088966,51.162369 
	C40.181465,50.442089 41.261250,49.120720 42.368488,49.097301 
	C50.697426,48.921120 59.031773,49.000252 67.364426,49.000065 
	C78.683434,48.999809 90.002449,49.000000 101.707932,49.000000 
	C101.781395,47.663094 101.845299,46.500149 101.947693,44.636635 
	C81.052307,44.636635 60.606556,44.645138 40.161053,44.577801 
	C39.736725,44.576405 39.315289,43.696484 37.894775,42.117073 
	C40.748394,41.632862 42.612259,41.048115 44.478188,41.041451 
	C63.456509,40.973625 82.435165,41.000008 101.357880,41.000008 
	C103.057426,37.422035 101.342552,36.956429 98.463043,36.966953 
	C80.464752,37.032738 62.466026,37.021545 44.467655,36.967232 
	C42.639225,36.961716 40.812168,36.500473 38.984474,36.250713 
	C38.917347,35.586987 38.850224,34.923260 38.783096,34.259537 
	C40.552006,33.839695 42.319061,33.064289 44.090107,33.055073 
	C61.588211,32.964016 79.086975,33.000004 96.585548,33.000000 
	C98.229973,32.999996 99.874397,33.000000 101.746475,33.000000 
	C101.746475,31.613688 101.746475,30.545031 101.746475,29.000000 
	C91.438133,29.000000 81.145889,29.000004 70.853653,29.000000 
	C61.854385,28.999996 52.852745,29.105276 43.858063,28.902170 
	C42.253563,28.865940 40.677925,27.551422 39.088963,26.827145 
	C40.626511,26.218117 42.160530,25.089764 43.702141,25.079294 
	C61.866493,24.955944 80.031982,24.999971 98.197166,24.999928 
	C99.320244,24.999924 100.443329,24.999926 101.030853,24.999926 
	C102.055901,16.956413 103.020561,9.386646 103.992615,1.408440 
	C105.066673,1.000000 106.133339,1.000000 107.474716,1.376681 
	C107.166260,3.527954 106.087967,5.299270 106.076195,7.077641 
	C105.952751,25.731974 106.389214,44.399345 105.819000,63.037048 
	C105.571808,71.116646 108.488144,77.970718 111.100601,85.097092 
	C112.023354,87.614204 115.717308,88.026955 116.557571,85.674492 
	C118.658356,79.792984 121.560654,73.762413 121.755074,67.714935 
	C122.402328,47.581516 122.058548,27.413935 121.912498,7.260768 
	C121.897339,5.169335 120.666679,3.086712 120.000000,1.000000 
	C122.991180,1.000000 125.982361,1.000000 128.952087,1.000000 
	C128.952087,43.666695 128.952087,86.333374 128.952087,129.000000 
	C124.251228,129.000000 119.598274,129.000000 114.472656,129.000000 
z"/>
				<path fill="#FFFFFF" opacity="1.000000" stroke="none"
					d="
M43.531342,0.999999 
	C39.399666,5.759950 34.698345,10.428788 30.266544,15.340791 
	C29.086660,16.648520 28.108519,18.696886 28.095409,20.413284 
	C27.945406,40.052895 28.027769,59.694145 27.969067,79.334724 
	C27.960623,82.159843 28.203142,83.976807 31.878260,84.089264 
	C41.645256,84.388084 52.006847,94.032684 52.926601,103.791901 
	C53.871067,113.813339 51.470940,122.646294 42.096710,128.664612 
	C40.285641,129.000000 38.571285,129.000000 36.533825,128.656250 
	C47.694046,123.012871 53.326309,112.494423 49.780972,101.641876 
	C47.268623,93.951408 41.842354,88.468040 33.612411,87.119690 
	C25.044975,85.716057 16.937555,87.468353 11.668123,95.005455 
	C6.900657,101.824577 6.645545,109.412766 9.671994,117.311562 
	C11.982870,123.342773 17.243847,125.797844 22.000000,129.000000 
	C15.015985,129.000000 8.031968,129.000000 1.023976,129.000000 
	C1.023976,86.446754 1.023976,43.893497 1.023976,1.000000 
	C15.019059,1.000000 29.040871,1.000000 43.531342,0.999999 
z"/>
				<path fill="#073763" opacity="1.000000" stroke="none"
					d="
M119.539368,1.000000 
	C120.666679,3.086712 121.897339,5.169335 121.912498,7.260768 
	C122.058548,27.413935 122.402328,47.581516 121.755074,67.714935 
	C121.560654,73.762413 118.658356,79.792984 116.557571,85.674492 
	C115.717308,88.026955 112.023354,87.614204 111.100601,85.097092 
	C108.488144,77.970718 105.571808,71.116646 105.819000,63.037048 
	C106.389214,44.399345 105.952751,25.731974 106.076195,7.077641 
	C106.087967,5.299270 107.166260,3.527954 107.874710,1.376681 
	C111.692909,1.000000 115.385818,1.000000 119.539368,1.000000 
M110.000000,50.498657 
	C110.000000,56.262905 110.000000,62.027157 110.000000,67.688232 
	C112.992554,67.688232 115.387497,67.688232 117.709145,67.688232 
	C117.709145,49.323147 117.709145,31.271204 117.709145,13.311549 
	C115.011688,13.311549 112.613556,13.311549 110.000000,13.311549 
	C110.000000,25.580875 110.000000,37.540100 110.000000,50.498657 
z"/>
				<path fill="#073763" opacity="1.000000" stroke="none"
					d="
M22.464651,129.000000 
	C17.243847,125.797844 11.982870,123.342773 9.671994,117.311562 
	C6.645545,109.412766 6.900657,101.824577 11.668123,95.005455 
	C16.937555,87.468353 25.044975,85.716057 33.612411,87.119690 
	C41.842354,88.468040 47.268623,93.951408 49.780972,101.641876 
	C53.326309,112.494423 47.694046,123.012871 36.105362,128.656250 
	C31.643101,129.000000 27.286201,129.000000 22.464651,129.000000 
M16.499859,97.989204 
	C12.891782,100.970123 12.941257,105.081055 12.999622,109.213921 
	C13.101054,116.396301 18.375906,122.773483 25.254536,123.979462 
	C33.277870,125.386139 41.726059,121.226845 43.953423,114.361320 
	C46.754135,105.728531 44.869251,99.154213 37.830730,94.350082 
	C31.336489,89.917442 23.486105,91.149979 16.499859,97.989204 
z"/>
				<path fill="#FFFFFF" opacity="1.000000" stroke="none"
					d="
M50.056938,85.000000 
	C46.038082,84.545769 42.123589,86.155823 38.654530,83.466431 
	C42.148499,80.088089 101.667442,80.013626 105.878418,83.842552 
	C103.426292,84.326347 101.720398,84.948105 100.011757,84.955750 
	C83.523491,85.029518 67.034775,84.999992 50.056938,85.000000 
z"/>
				<path fill="#FFFFFF" opacity="1.000000" stroke="none"
					d="
M89.998169,109.000000 
	C96.323593,109.480186 102.375252,107.996422 108.813644,110.156204 
	C104.785271,113.921822 73.219101,113.923164 69.209267,110.179474 
	C76.315590,107.972946 83.008896,109.472404 89.998169,109.000000 
z"/>
				<path fill="#FFFFFF" opacity="1.000000" stroke="none"
					d="
M92.982407,95.000000 
	C97.952545,95.687775 102.851578,93.500038 107.284271,96.635391 
	C103.795494,99.925018 73.251839,99.943466 68.733566,95.752518 
	C77.378723,94.380051 84.985519,95.283485 92.982407,95.000000 
z"/>
				<path fill="#FFFFFF" opacity="1.000000" stroke="none"
					d="
M66.318748,20.999989 
	C64.268066,20.278994 62.677563,19.558010 61.087059,18.837025 
	C62.621212,18.224703 64.132706,17.163174 65.693779,17.084435 
	C70.345268,16.849817 75.023315,16.863161 79.674416,17.102091 
	C80.786636,17.159225 81.834435,18.470375 82.911865,19.204767 
	C81.864471,19.802580 80.838509,20.867062 79.765930,20.916292 
	C75.444756,21.114639 71.109177,20.999403 66.318748,20.999989 
z"/>
				<path fill="#FFFFFF" opacity="1.000000" stroke="none"
					d="
M39.730095,13.767955 
	C41.293133,12.031338 42.598480,10.544936 44.140839,8.788649 
	C45.649281,15.338823 43.878708,17.214508 36.965061,16.640337 
	C37.943829,15.616745 38.708115,14.817458 39.730095,13.767955 
z"/>
				<path fill="#FFFFFF" opacity="1.000000" stroke="none"
					d="
M110.000000,49.998989 
	C110.000000,37.540100 110.000000,25.580875 110.000000,13.311549 
	C112.613556,13.311549 115.011688,13.311549 117.709145,13.311549 
	C117.709145,31.271204 117.709145,49.323147 117.709145,67.688232 
	C115.387497,67.688232 112.992554,67.688232 110.000000,67.688232 
	C110.000000,62.027157 110.000000,56.262905 110.000000,49.998989 
z"/>
				<path fill="#FFFFFF" opacity="1.000000" stroke="none"
					d="
M16.725368,97.675865 
	C23.486105,91.149979 31.336489,89.917442 37.830730,94.350082 
	C44.869251,99.154213 46.754135,105.728531 43.953423,114.361320 
	C41.726059,121.226845 33.277870,125.386139 25.254536,123.979462 
	C18.375906,122.773483 13.101054,116.396301 12.999622,109.213921 
	C12.941257,105.081055 12.891782,100.970123 16.725368,97.675865 
M28.523762,96.011497 
	C27.819405,100.548752 27.115047,105.086006 26.405230,109.658440 
	C41.009903,110.739845 41.009903,110.739845 40.353184,105.820190 
	C37.345058,105.820190 34.267193,105.820190 31.864426,105.820190 
	C30.969376,102.185905 30.208979,99.098381 28.523762,96.011497 
z"/>
				<path fill="#073763" opacity="1.000000" stroke="none"
					d="
M28.986172,96.011169 
	C30.208979,99.098381 30.969376,102.185905 31.864426,105.820190 
	C34.267193,105.820190 37.345058,105.820190 40.353184,105.820190 
	C41.009903,110.739845 41.009903,110.739845 26.405230,109.658440 
	C27.115047,105.086006 27.819405,100.548752 28.986172,96.011169 
z"/>
			</svg>
		</>
	)
}

export default ParameterIcon