import React, { useState, useEffect, useRef } from "react"
import "../../static/common.css";
import { t } from "../../translations/Translation";
import DetailsIcon from "../../static/icons/menu.png";



export default function MenuContentWithoutTitle({ content }) {

    const [show, setShow] = useState(false);
    let menuRef = useRef();
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];

    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target)) {

                setShow(false);
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    })

    return (
        <>
            <img className="ml-2 planning-icon" src={DetailsIcon} title={t("MORE")} onClick={() => { setShow(!show) }}></img>
            <div ref={menuRef} className="shortcut-menu-position">
                {show && <ul className="list-group shadow">
                    {content.map((val, index) => {
                        if (UserPermissions?.includes(val.permission) || val.permission === undefined) {
                            return (
                                <>
                                    {val.ActionFunction && <a key={val.title} className="list-group-item text-decoration-none border-bottom-0 text-dark" onClick={() => val.ActionFunction()}>
                                        {val.icon ? <span className="pr-3">{val.icon}</span> : ''}{val.title}
                                    </a>}
                                    {val.url && <a key={val.title} className="list-group-item text-decoration-none border-bottom-0 text-dark" href={val.url} >
                                        {val.icon ? <span className="pr-3">{val.icon}</span> : ''}{val.title}
                                    </a>}
                                </>
                            )
                        }
                    })}
                </ul>}
            </div>
        </>
    )
}