import React, { useEffect, useState } from "react";
import LeftIcon from "../../../static/icons/left-arrow.png";
import RightIcon from "../../../static/icons/right-arrow.png";
import { t } from "../../../translations/Translation";
import { GetEmployeeOverviewApiUrl } from "../../../routes/ApiEndPoints";
import { useNavigate, useParams } from "react-router-dom";
import ReportingOverview from "./ReportingOverview";
import { APICALL as AXIOS } from "../../../services/AxiosServices";
import BackIcon from "../../../static/icons/BackIcon.png";
import DownArrow from "../../../static/icons/DownArrow.svg";
// import NewIcon from "../../../static/icons/new-sticker.png";


export default function MonthlyEmployeeOverview() {

    const employeeList = JSON.parse(sessionStorage.getItem('reporting_employees'))

    const [disableNext, setDisableNext] = useState(false);
    const [disablePrev, setDisablePrev] = useState(false);
    const [spreadsheetData, setSpreadsheetData] = useState([]);
    const [employee, setEmployee] = useState('');
    const [sheetType, setSheetType] = useState('');
    const [toggleChange, setToggleChange] = useState(false);
    const [dataRefresh, setDataRefresh] =useState(false)

    const params = useParams();
    const navigate = useNavigate();

    const filterDataObject = JSON.parse(localStorage.getItem('reporting_filters'));
    
    useEffect(() => {
        employeeList.map((val, index) => {
            if (val.id === parseInt(params.id)) {
                if (employeeList[index + 1] === undefined) {
                    setDisableNext(true)
                }
                if (employeeList[index - 1] === undefined) {
                    setDisablePrev(true)
                }
            }
        })
    }, [employeeList])

    const EmployeeDetailView = (emp_id) => {

        let req_data = { ...filterDataObject }
        req_data['employee_profile_id'] = emp_id
        AXIOS.service(GetEmployeeOverviewApiUrl, 'POST', req_data)
            .then((result) => {
                if (result?.success) {
                    setEmployee(result.data.employee_details);
                    setSpreadsheetData(result.data.planning_details);
                    setSheetType(result.data.sheet_type);
                } else {
                    // setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        EmployeeDetailView(params.id)
    }, [params, dataRefresh])

    const basicDetailsArray = [
        { label: t("FULL_NAME"), value: employee.fullname },
        // { label: t("MOBILE_NUMBER"), value: employee.phone_number },
        { label: t("DATE_OF_BIRTH"), value: employee.date_of_birth },
        // { label: t("EMAIL"), value: employee.email },
        { label: t("LANGUAGE"), value: employee.language },
        { label: t("SSN"), value: employee.social_security_number },
        { label: t("SOCIAL_SECRETARY_NUMBER"), value: employee.social_secretary_number },
        { label: t("FUNCTIONS"), value: employee?.functions },
        { label: t("EMPLOYEE_TYPES"), value: employee?.employee_types },
        { label: t("SALARY"), value: employee?.salary },
        { label: t("ADDRESS_TITLE"), value: employee.street_house_no },
        { label: t("POSTAL_CODE"), value: employee.postal_code },
        { label: t("CITY"), value: employee.city },
        { label: t("COUNTRY"), value: employee.country },
    ]


    const DisableNextPrev = (type, index) => {
        if (type === 'next') {
            if (employeeList[index + 2] === undefined) {
                setDisableNext(true)
            }
            if (employeeList[index] !== undefined && disablePrev) {
                setDisablePrev(false)
            }
        } else {
            if (employeeList[index - 2] === undefined) {
                setDisablePrev(true)
            }
            if (employeeList[index] !== undefined && disableNext) {
                setDisableNext(false)
            }
        }
    }

    const nextEmployee = () => {
        employeeList.map((val, index) => {
            if (val.id === parseInt(params.id)) {
                DisableNextPrev('next', index)
                if (employeeList[index + 1]) {
                    navigate('/monthly-overview/' + employeeList[index + 1].id)
                }
            }
        })
    }

    const prevEmployee = () => {
        employeeList.map((val, index) => {
            if (val.id === parseInt(params.id)) {
                DisableNextPrev('prev', index)
                if (employeeList[index - 1]) {
                    navigate('/monthly-overview/' + employeeList[index - 1].id)
                }
            }
        })
    }

    return (
        <div className="right-container">
            <div className="company-tab-width">
                <h3 className="text-left mt-2 py-1 pl-2 text-color bg-white font-weight-bold"><img className="shortcut-icon mr-2 pointer" onClick={() => navigate('/monthly-overview')} src={BackIcon} alt="Back" />{t("MONTHLY_OVERVIEW")}</h3>
                <div className="col-md-12 p-0">
                    <div className="d-flex px-2 align-items-center m-0">
                        <div className={"col p-0 pointer text-left"}>{!disablePrev && <img className="shortcut-icon my-auto" onClick={() => prevEmployee()} src={LeftIcon}></img>}</div>
                        <div className={"col-11 px-3 bg-white pt-3 text-center" }>
                            <div className="d-flex justify-content-between">
                            <h4 className="text-left pb-1 text-color font-weight-bold">{t('EMPLOYEE_DETAILS')}</h4>
                            <img className="shortcut-icon pointer" src={DownArrow} onClick={() => setToggleChange(!toggleChange)}></img>
                            </div>
                            {/* <div className="col-md-12 m-0 p-0 row"> */}
                            {toggleChange && <ul className="col-md-12 m-0 pr-0 mb-1 pl-3 row">
                                {basicDetailsArray.map((item, index) => {
                                    return (<li className="row m-0 col-md-4 p-0" key={item.label}><label className="mb-0 font-weight-600 pr-1 float-left">{item.label}:</label><p className="mb-0 float-left">{item.value}</p></li>) //{item.label === 'City' && <img className="planning-icon" src={NewIcon}></img>}
                                })}
                            </ul>}
                        </div>
                        <div className={"col p-0 text-right pointer"}>{!disableNext && <img className="shortcut-icon" onClick={() => nextEmployee()} src={RightIcon}></img>}</div>
                    </div>
                    <div className="col-md-12 bg-white mt-2 p-0">
                        <ReportingOverview spreadsheetData={spreadsheetData} sheetType={sheetType} employee={employee} setDataRefresh={setDataRefresh} dataRefresh={dataRefresh}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
