import React, { useState, useEffect } from 'react';
import CustomPopup from '../../utilities/popup/CustomPopup';
import { t } from "../../translations/Translation"
import CustomCheckBox from '../atoms/formFields/CustomCheckBox';
import { APICALL as AXIOS } from '../../services/AxiosServices';
import { GetContractOptionsListForMAnualGeneration, GenerateContractManuallyApiUrl } from '../../routes/ApiEndPoints';
import { toast } from 'react-toastify';
import { getFormattedRadioOptions } from '../../utilities/CommonFunctions';

export default function GenerateContractManuallyPopup({ id, setShowGenerateContract }) {

    const [contractOptions, setContractOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        AXIOS.service(`${GetContractOptionsListForMAnualGeneration}/${id}`, 'GET')
            .then((response) => {
                if (response?.success) {
                    let arr = getFormattedRadioOptions(response.data)
                    arr.map((item) => {
                        item.checked = false;
                        item.customStyle = " mb-2"
                        return item;
                    })
                    setContractOptions(arr)
                } else {
                    toast.error(response.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                }
            })
    }, [])

    const handlCheckbox = (val) => {
        let newOptions = [...contractOptions]

        newOptions.map((item) => {
            if (item.key === val) {
                item.checked = !item.checked
            }
            return item;
        })

        setSelectedOptions(newOptions.filter(item => item.checked === true).map(item => item.key));
        setContractOptions(newOptions)
    }

    const onHide = () => {
        setShowGenerateContract(false)
    }

    const onConfirm = () => {
        let data = {
            "employee_contract_id": id,
            "contract_type_ids": selectedOptions
        }
        AXIOS.service(GenerateContractManuallyApiUrl, 'POST', data)
            .then((response) => {
                if (response.success) {
                    toast.success(response.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                    setShowGenerateContract(false)
                } else {
                    toast.error(response.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                }
            }).catch((error) => { console.log(error) })
    }

    return (
        <CustomPopup size="lg" header footer title={t('GENERATE_CONTRACT')} onHide={() => onHide()} onConfirm={() => onConfirm()} saveButtonName={t('SAVE')}
            body={<div className='p-2'>
                {contractOptions.map((option) => {
                    return (
                        <div className='d-flex justify-content-between mb-2 pb-1'>
                            <div className='col-8'>{option.name}</div>
                            <div className='col-2'><CustomCheckBox checkboxList={[{ name: "", key: option.key }]} changeCheckbox={(e) => handlCheckbox(e)} ></CustomCheckBox></div>
                        </div>
                    )
                })}
            </div>}
        ></CustomPopup>
    );
}