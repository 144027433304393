import React, { useState, useEffect } from "react";
import FormsNew from "../molecules/FormsNew";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { OvertimeConfigApiUrl } from "../../routes/ApiEndPoints"
import { t } from "../../translations/Translation";

export default function AddOvertime() {

    const [formData, setFormData] = useState({
        "name": "",
        "percentage": "",
        "description": "",
        "status": true,
    })

    const [active, setActive] = useState(true);
    const [inactive, setInactive] = useState(false);

    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const params = useParams();

    // Checkbox status data
    const changeCheckbox = (type) => {
        if (type === 'active') {
            setActive(true);
            setInactive(false);
        } else {
            setActive(false);
            setInactive(true);
        }
    }
    const checkboxList = [
        {
            name: t("ACTIVE"),
            key: 'active',
            checked: active,
        },
        {
            name: t("INACTIVE"),
            key: 'inactive',
            checked: inactive,
        }
    ]


    useEffect(() => {
        if (params.id !== undefined) {
            let req_data = { 'id': params.id }
            AXIOS.service(OvertimeConfigApiUrl + 'show', 'POST', req_data)
                .then((result) => {
                    if (result?.success) {
                        let data = result.data
                        setFormData(data)
                    } else {
                        setErrors(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [])


    const setValues = (index, name, value, field) => {
        const newData = { ...formData };
        if (field !== 'dropdown') {
            newData[name] = value
        }
        setFormData(newData);
    }


    const onSave = () => {

        let url = OvertimeConfigApiUrl + 'store'
        let method = 'POST'

        // Update url and method
        if (params.id !== undefined) {
            url = OvertimeConfigApiUrl + 'update'
            formData['id'] = params.id
        }
        // APICall for create and update overtime configuration
        AXIOS.service(url, method, formData)
            .then((result) => {
                if (result?.success) {
                    navigate('/manage-social-secretary-and-reporting-configurations/overtime');
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }



    const Overtimefields = [
        { title: t("NAME_TEXT"), name: "name", required: true, type: "text", style: "col-md-6 mt-4 float-left" },
        { title: t("PERCENTAGE"), name: "percentage", type: "text", required: true, style: "col-md-6 float-left mt-4" },
        { title: t("DESCRIPTION"), name: 'description', required: false, type: 'text-area', style: "col-md-12 mt-4 mb-5 float-left" },
        { title: t("STATUS_TEXT"), required: true, type: 'checkbox', checkboxList: checkboxList, changeCheckbox: changeCheckbox, style: "col-md-12 mt-4 mb-2 float-left" },
    ];

    return (
        <div className="right-container add_taxes">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <FormsNew
                view="public_holiday"
                formTitle={t("ADD_OVERTIME")}
                redirectURL={'/manage-social-secretary-and-reporting-configurations/overtime'}
                formattedData={formData}
                data={Overtimefields}
                SetValues={setValues}
                OnSave={onSave}
            ></FormsNew>
        </div>
    )
}