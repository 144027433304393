import React from 'react'

const Workstation = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 31.88 27.895">
        <path id="Path_33050" data-name="Path 33050" d="M32.2,16.026V28.854c-.014.016-.035.027-.039.045a3,3,0,0,1-3.251,2.508H22.456c.206,1.025.4,2,.6,2.988h.355c.945,0,1.889-.008,2.832,0a.993.993,0,0,1,.188,1.964,2.119,2.119,0,0,1-.433.025H13.269v.886a1,1,0,0,1-1.1,1.105H1.4A1,1,0,0,1,.32,37.293V11.584a1,1,0,0,1,1.1-1.1c1.068,0,2.138,0,3.206,0h7.562a1,1,0,0,1,1.078,1.031c.006.27,0,.539,0,.809v1.148H29.127a2.988,2.988,0,0,1,2.92,2.054c.055.165.1.331.151.5ZM30.207,27.41V16.65c0-.809-.37-1.184-1.176-1.184H13.289V27.41Zm-18.95-9.965V15.491H2.329v1.954Zm0,4.983V20.475H2.331v1.954h8.924Zm-4.429,2c-.5,0-1-.006-1.493,0a.995.995,0,1,0-.016,1.989q1.479.012,2.957,0a.995.995,0,1,0-.016-1.989c-.476-.006-.954,0-1.433,0Zm6.465,6.988v2.963h2.152c.2-.984.392-1.964.594-2.963H13.295ZM6.787,30.41a1.493,1.493,0,1,0,1.5,1.5A1.5,1.5,0,0,0,6.787,30.41Z" transform="translate(-0.32 -10.486)" fill="#292929" />
      </svg>
    </>
  )
}

export default Workstation