import React, { useEffect, useState } from "react";
import FormsNew from "./FormsNew";
import CustomPopup from "../../utilities/popup/CustomPopup";
import { t } from "../../translations/Translation"
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetActiveAndInactiveEmployeesApiUrl, EmployeeTypeApiUrl, ExportEmployeeDetailsApiUrl } from "../../routes/ApiEndPoints";
import { getFormattedDropdownOptions } from "../../utilities/CommonFunctions";
import { toast, ToastContainer } from "react-toastify";

export default function ExportEmployeesPopup({ onHide }) {
    const [filtersData, setFiltersData] = useState({
        'type': "",
        'employees': [],
        'employee_types': [],
    })
    const [employeesList, setEmployeesList] = useState([])
    const [employeeTypesList, setEmployeeTypesList] = useState([])
    const [activeType, setActiveType] = useState('')
    const [employeeTypes, setEmployeeTypes] = useState([])
    const [employees, setEmployees] = useState([])

    const [success, setSuccess] = useState(false)

    const typeOptions = [
        { label: t("ACTIVE"), value: 1 },
        { label: t("INACTIVE"), value: 0 },
        { label: t("BOTH"), value: 2 }
    ]

    useEffect(() => {
        AXIOS.service(GetActiveAndInactiveEmployeesApiUrl, 'GET')
            .then((result) => {
                if (result.success) {
                    setEmployeesList(result.data)
                }
            })

        AXIOS.service(EmployeeTypeApiUrl, 'GET')
            .then((result) => {
                if (result.success) {
                    setEmployeeTypesList(getFormattedDropdownOptions(result.data))
                }
            })


    }, [])

    const setVAlue = (index, name, value) => {
        let newData = { ...filtersData }
        if (name === "type") {
            newData.type = value.value
            setActiveType(value)
        } else if (name === "employees") {
            let arr = value.map(item => item.value)
            newData.employees = arr
            setEmployees(value)
        } else if (name === "employee_types") {
            let arr = value.map(item => item.value)
            newData.employee_types = arr
            setEmployeeTypes(value)
        }
        setFiltersData(newData)
    }

    const onExport = () => {
        setSuccess(true)
        AXIOS.service(ExportEmployeeDetailsApiUrl, 'POST', filtersData)
            .then((result) => {
                if (result?.success) {
                    setSuccess(false)
                    const link = document.createElement('a');
                    link.href = result.url;
                    link.setAttribute('download', 'ExportedData.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    onHide()
                    setSuccess(false)
                } else {
                    setSuccess(false)
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(false)
            })
    }


    const formFields = [
        { title: t('TYPE'), name: "type", options: typeOptions, selectedOptions: activeType, required: true, type: "dropdown", style: "col-4 p-2" },
        { title: t('EMPLOYEES'), name: "employees", options: employeesList, selectedOptions: employees, type: "dropdown", style: "col-4 p-2", isMulti: true },
        { title: t('EMPLOYEE_TYPE'), name: "employee_types", options: employeeTypesList, selectedOptions: employeeTypes, type: "dropdown", style: "col-4 p-2", isMulti: true },
    ]

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <CustomPopup header footer close title={t("EXPORT_EMPLOYEES")} size={'xl'} body={
                <FormsNew
                    view='filters'
                    formattedData={filtersData}
                    data={formFields}
                    SetValues={setVAlue}
                >
                </FormsNew>
            } onHide={onHide} onConfirm={onExport} saveButtonName={t('EXPORT')} disableSaveButton={success} loadingIcon></CustomPopup>
        </>
    )
}
