import React, { useState, useEffect } from 'react';
import { t } from '../../translations/Translation';
const CustomJsonEditor = ({ data, outPutFunction }) => {

    const [jsonData, setJsonData] = useState(data);
    const [editingPath, setEditingPath] = useState(null);
    const [editingValue, setEditingValue] = useState('');

    useEffect(() => {
        setJsonData(data);
    }, [data]);

    const handleValueChange = (path, value) => {
        const updatedJson = { ...jsonData };
        let obj = updatedJson;
        for (let i = 0; i < path.length - 1; i++) {
            obj = obj[path[i]];
        }
        obj[path[path.length - 1]] = value;
        setJsonData(updatedJson);

        if (outPutFunction) {
            outPutFunction(updatedJson);
        }
    };

    const handleEditClick = (path, value) => {
        setEditingPath(path);
        setEditingValue(value);
    };

    const handleInputChange = (e) => {
        setEditingValue(e.target.value);
    };

    const handleSave = () => {
        handleValueChange(editingPath, editingValue);
        setEditingPath(null);
        setEditingValue('');

    };

    const renderEditor = (json, path = [], level = 0) => {
        const backgroundColors = ['#ffffff', '#f0f8ff', '#faebd7', '#ffefd5', '#ffe4e1'];

        const getBackgroundColor = (level) => backgroundColors[level % backgroundColors.length];
        const leafColor = '#ffff99'; // Example leaf color (yellow)

        if (Array.isArray(json)) {
            return json.map((item, index) => (
                <div key={index} style={{ marginLeft: '20px', backgroundColor: getBackgroundColor(level) }}>
                    {renderEditor(item, [...path, index], level + 1)}
                </div>
            ));
        } else if (typeof json === 'object' && json !== null) {
            return Object.keys(json).map((key) => (
                <div key={key} style={{ marginBottom: '10px', marginLeft: '20px', backgroundColor: getBackgroundColor(level) }}>
                    <label style={{ marginRight: '10px' }}>{key}:</label>
                    {renderEditor(json[key], [...path, key], level + 1)}
                </div>
            ));
        } else {
            const currentPathString = JSON.stringify(path);
            const isEditing = JSON.stringify(editingPath) === currentPathString;
            return isEditing ? (
                <input
                    type="text"
                    value={editingValue}
                    onChange={handleInputChange}
                    onBlur={handleSave}
                    autoFocus
                />
            ) : (
                <span onClick={() => handleEditClick(path, json)} style={{ cursor: 'pointer', backgroundColor: leafColor }}>
                    {json}
                </span>
            );
        }
    };

    return (

        <div className='overflow-auto col-12'>
            <h2 id='text-indii-blue' className='text-center m-3 border-bottom'>{t('DIMONA_DECLARATION_EDITOR')}</h2>
            <div className='d-flex border p-2'>
                <div className='col-12'> <pre>{renderEditor(jsonData)}</pre></div>
            </div>
        </div>

    );
};

export default CustomJsonEditor;
