import React from "react";
import FormsNew from "./FormsNew";
import { useState, useEffect } from "react";
import { t } from "../../translations/Translation"
import DeleteIcon from "../../static/icons/Delete.svg";
import AddIcon from "../../static/icons/AddPlusIcon.png";
import BackIcon from "../../static/icons/BackIcon.png";
import { useNavigate } from "react-router-dom";
import CustomButton from "../atoms/CustomButton";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import { ToastContainer, toast } from "react-toastify";

export default function AddEventPlanning() {
    
    // setvalue for dropdown  options is pending
    // edit flow is pending
    //

    const navigate = useNavigate()
    const [workstationsList, setWorkstationsList] = useState([])
    const [selectedWorkstations, setSelectedWorkstations] = useState([])
    const [functionsList, setFunctionsList] = useState([])
    const [selectedFunctions, setSelectedFunctions] = useState([])
    const [equipmentList, setEquipmentList] = useState([])
    const [selectedEquipments, setSelectedEquipmentList] = useState([])

    const [eventPlanning, setEventPlanning] = useState([{
        "workstation": "",
        "function": "",
        "time": "",
        "no_of_employees": "",
        "dress_code": "",
        "equipments": []
    }])
    const [formData, setFormData] = useState({
        event_name: "",
        street: "",
        street_house_no: "",
        box_number: "",
        postal_code: "",
        city: "",
        country: "",
        extra_info: "",
    })


    const setValues = (index, name, value, field) => {
        setFormData((prev) => ({
            ...prev, [name]: value
        }))
    }

    const setPlanningValues = (index, name, value, field, arrIndex) => {

        let newplanData = [...eventPlanning]
        newplanData[arrIndex][name] = value
        setEventPlanning(newplanData)

    }

    const OnSave = () => {
        let data = { ...formData }
        data.event_planning = eventPlanning
        console.log(data);
    }

    const AddRemoveEventRow = (val, index) => {
        if (val === 'add') {
            setEventPlanning([...eventPlanning, {
                "workstation": "",
                "function": "",
                "time": "",
                "no_of_employees": "",
                "dress_code": "",
                "equipments": []
            }])
        } else {
            let arr = [...eventPlanning]
            arr.splice(index, 1)
            setEventPlanning(arr)

        }

    }

    const detailsFields = [
        { title: t("EVENT_NAME"), name: 'event_name', required: true, type: 'text', style: 'col-md-12 mt-4 mb-2 float-left' },
        // { title: t("STREET"), name: 'street', required: true, type: 'text', style: 'col-md-6 mb-2 float-left' },
        { title: t("ADDRESS_STREET_HOUSE"), name: 'street_house_no', required: true, type: 'text', style: 'col-md-6 mb-2 float-left' },
        { title: t("BOX_NUMBER"), name: 'box_number', required: true, type: 'text', style: 'col-md-6 mb-2 float-left' },
        { title: t("POSTAL_CODE"), name: 'postal_code', required: true, type: 'text', style: 'col-md-6 mb-2 float-left' },
        { title: t("CITY"), name: 'city', required: true, type: 'text', style: 'col-md-6 mb-2 float-left' },
        { title: t("COUNTRY"), name: 'country', required: true, type: 'text', style: 'col-md-6 float-left' },
        { title: t("EXTRA_INFO"), name: 'extra_info', required: true, type: 'text-area', style: 'col-md-6 float-left' },
    ]

    const planningFields = [
        { title: t("WORKSTATION"), name: 'workstation', required: true, type: 'dropdown', options: workstationsList, selectedOptions: selectedWorkstations, style: 'col-md-3 mb-2 float-left' },
        { title: t("FUNCTIONS"), name: 'function', required: true, type: 'dropdown', options: functionsList, selectedOptions: selectedFunctions, style: 'col-md-3 mb-2 float-left' },
        { title: t("TIME"), name: 'time', required: true, type: 'time', style: 'col-md-3 mb-2 float-left' },
        { title: t("NUMBER_OF_EMPLOYEES"), name: 'no_of_employees', required: true, type: 'text', style: 'col-md-3 mb-2 float-left' },
        { title: t("DRESS_CODE"), name: 'dress_code', required: true, type: 'text', style: 'col-md-3 mb-2 float-left' },
        { title: t("EQUIPMENTS"), name: 'equipments', required: true, type: 'dropdown', options: equipmentList, selectedOptions: selectedEquipments, style: 'col-md-9 mb-2 float-left' },
    ]
    return (
        <div className="right-container">
            <div className="company-tab-width bg-white mt-3 px-4">
                <h2 id="text-indii-blue" className=" pt-4 mb-0 ml-2 d-flex align-items-center"><img className="shortcut-icon mr-2 pointer" onClick={() => navigate('/manage-plannings#event')} src={BackIcon}></img>{t("ADD_EVENT_PLANNING")}</h2>
                <div className="border mt-2">
                    <FormsNew
                        // view="filters"
                        redirectURL={'/manage-configurations/dimona_namespace'}
                        formattedData={formData}
                        data={detailsFields}
                        SetValues={setValues}
                    ></FormsNew>
                </div>
                <h5 className="p-2 my-2" id="text-indii-blue">{ t("EVENT_PLANNING")}</h5>
                <div className="border p-2">
                    <div className="company-tab-width bg-white">
                        {eventPlanning.map((row, index) => {
                            return (
                                <div key={row} className="col-md-12 d-flex mb-2 justify-content-center py-4">
                                    <div className="col-md-11  p-0 border">
                                        <FormsNew
                                            view="filters"
                                            functionIndex={index}
                                            data={planningFields}
                                            SetValues={setPlanningValues}
                                            formattedData={eventPlanning[index]}
                                        />
                                    </div>
                                    <div className="col-md-1 px-3 text-center py-4">
                                        {eventPlanning.length - 1 === index &&eventPlanning.length !==1  &&
                                          <img className="shortcut-icon pointer mr-3" src={DeleteIcon}
                                          onClick={() => AddRemoveEventRow('remove', index)}></img>
                                        }
                                        <img className="shortcut-icon pointer" src={eventPlanning.length - 1 === index ? AddIcon : DeleteIcon}
                                            onClick={() => AddRemoveEventRow(eventPlanning.length - 1 === index ? 'add' : 'remove', index)}></img>
                                    </div>
                                </div>)
                        })
                        }
                    </div>

                </div>
                <div className="my-3 px-4">
                <div className="text-right pb-5">
                    <CustomButton buttonName={t("SAVE")} ActionFunction={() => OnSave()} CustomStyle=""></CustomButton>
                    <CustomButton buttonName={t("BACK_LINK")} ActionFunction={() => navigate('/manage-plannings#event')} CustomStyle=""></CustomButton>
                </div>
            </div>
            </div>


        </div>
    )
}