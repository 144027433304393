import React from 'react'

const Reasons = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="25.035" height="28.002" viewBox="0 0 25.035 28.002">
        <path id="Reason_Bl_" data-name="Reason (Bl)" d="M13908.036-17042H13883v-3.352c0-4.111,1.777-7.256,5-8.854a10.215,10.215,0,0,0,7.514,3.268,10.219,10.219,0,0,0,7.515-3.268c3.227,1.6,5,4.748,5,8.854v3.352Zm-12.437-12.381a7.9,7.9,0,0,1-7.959-7.814,7.893,7.893,0,0,1,7.959-7.807,7.891,7.891,0,0,1,7.956,7.807A7.9,7.9,0,0,1,13895.6-17054.381Zm-.218-4.992a.906.906,0,0,0-.969.9.9.9,0,0,0,.969.891.909.909,0,0,0,.985-.891A.9.9,0,0,0,13895.382-17059.373Zm.1-5.719a1.121,1.121,0,0,1,1.264,1.123c0,.619-.389.908-.927,1.309a2.205,2.205,0,0,0-1.121,1.912l0,.441h1.255v-.338c0-.689.262-.891.962-1.424l.006,0a2.356,2.356,0,0,0,1.2-1.975c0-1.486-1.288-2.15-2.565-2.15a2.892,2.892,0,0,0-2.039.736,2.267,2.267,0,0,0-.6,1.7h1.268A1.221,1.221,0,0,1,13895.484-17065.092Z" transform="translate(-13883.001 17070.002)" fill="#073763" />
      </svg>


    </>
  )
}

export default Reasons