
import React from 'react'

const QRCode = () => {
  return (
    <>
      <svg id="QR_code" data-name="QR code" xmlns="http://www.w3.org/2000/svg" width="87.661" height="87.661" viewBox="0 0 87.661 87.661">
        <path id="Path_65" data-name="Path 65" d="M295.863,0h8.348V4.174h-4.174V8.348h-8.349V20.873H283.34V16.7h4.174V4.174h8.349Z" transform="translate(-249.945)" fill="#073763" />
        <path id="Path_66" data-name="Path 66" d="M70,0V29.221H99.221V0ZM95.046,25.046H74.175V4.175H95.046Z" transform="translate(-70)" fill="#073763" />
        <path id="Path_67" data-name="Path 67" d="M443.34,0V29.221h29.219V0Zm25.044,25.046H447.515V4.175h20.871V25.046Z" transform="translate(-384.899)" fill="#073763" />
        <path id="Path_68" data-name="Path 68" d="M123.33,53.328h12.524V65.852H123.33Z" transform="translate(-114.982 -44.98)" fill="#073763" />
        <path id="Path_69" data-name="Path 69" d="M496.66,53.328h12.524V65.852H496.66Z" transform="translate(-429.872 -44.98)" fill="#073763" />
        <path id="Path_70" data-name="Path 70" d="M363.34,80h4.174v4.174H363.34Z" transform="translate(-317.422 -67.48)" fill="#073763" />
        <path id="Path_71" data-name="Path 71" d="M345.013,106.67h4.174v16.7h-4.174v-8.35h-4.174v4.175h-4.175v-8.348h8.349Z" transform="translate(-294.921 -89.972)" fill="#073763" />
        <path id="Path_72" data-name="Path 72" d="M238.35,160h4.174v4.175H246.7v4.174h-4.174V176.7H246.7v-4.174h4.175V168.35h4.174v12.523H246.7v4.174h-4.174v-4.174H238.35v-8.349H230V168.35h8.35Z" transform="translate(-204.954 -134.954)" fill="#073763" />
        <path id="Path_73" data-name="Path 73" d="M123.33,213.34h8.348v4.175H123.33Z" transform="translate(-114.982 -179.945)" fill="#073763" />
        <path id="Path_74" data-name="Path 74" d="M416.66,213.34h8.35v4.174h-4.174v4.174H416.66Z" transform="translate(-362.395 -179.945)" fill="#073763" />
        <path id="Path_75" data-name="Path 75" d="M496.66,213.34h4.175v4.175H496.66Z" transform="translate(-429.872 -179.945)" fill="#073763" />
        <path id="Path_76" data-name="Path 76" d="M70,240h8.348v4.174h4.176v4.175H78.348v4.174H74.174v-8.349H70Z" transform="translate(-70 -202.431)" fill="#073763" />
        <path id="Path_77" data-name="Path 77" d="M176.67,240h8.348v4.174h-4.174v4.175h4.174v4.174H176.67Z" transform="translate(-159.972 -202.431)" fill="#073763" />
        <path id="Path_78" data-name="Path 78" d="M478.35,240h4.176v4.174H486.7V256.7h-4.174v-4.174h-8.351V256.7H470v-8.348h8.348Z" transform="translate(-407.387 -202.431)" fill="#073763" />
        <path id="Path_79" data-name="Path 79" d="M230,266.66h4.174v4.175H230Z" transform="translate(-204.954 -224.918)" fill="#073763" />
        <path id="Path_80" data-name="Path 80" d="M603.33,266.66h4.175v4.175H603.33Z" transform="translate(-519.844 -224.918)" fill="#073763" />
        <path id="Path_81" data-name="Path 81" d="M234.173,293.34h4.175v8.348H230v-4.174h4.175Z" transform="translate(-204.952 -247.422)" fill="#073763" />
        <path id="Path_82" data-name="Path 82" d="M390,293.34h4.175v4.174H390Z" transform="translate(-339.908 -247.422)" fill="#073763" />
        <path id="Path_83" data-name="Path 83" d="M70,320h4.174v4.175H70Z" transform="translate(-70 -269.908)" fill="#073763" />
        <path id="Path_84" data-name="Path 84" d="M123.33,320H127.5v4.175H123.33Z" transform="translate(-114.982 -269.908)" fill="#073763" />
        <path id="Path_85" data-name="Path 85" d="M291.68,320h8.349v4.174h-4.174v8.35H291.68v8.348h-4.174v-8.348h-4.174v-8.35h4.174v4.175h4.174Z" transform="translate(-249.938 -269.908)" fill="#073763" />
        <path id="Path_86" data-name="Path 86" d="M416.66,320h4.175v4.175H416.66Z" transform="translate(-362.395 -269.908)" fill="#073763" />
        <path id="Path_87" data-name="Path 87" d="M603.33,320h4.175v4.175H603.33Z" transform="translate(-519.844 -269.908)" fill="#073763" />
        <path id="Path_88" data-name="Path 88" d="M390,346.66h4.175v8.348H390Z" transform="translate(-339.908 -292.395)" fill="#073763" />
        <path id="Path_89" data-name="Path 89" d="M523.33,346.66h4.175v8.348H523.33Z" transform="translate(-452.367 -292.395)" fill="#073763" />
        <path id="Path_90" data-name="Path 90" d="M576.67,346.66h4.174v4.174H576.67Z" transform="translate(-497.358 -292.395)" fill="#073763" />
        <path id="Path_91" data-name="Path 91" d="M335.041,373.33h4.173v4.175h4.175v8.348h12.522v4.174h-16.7v8.348H335.04v-8.348h-4.174V394.2h-4.176v4.173h4.176v4.175H309.994v-4.175h12.523v-8.348h4.174V377.505h8.35Z" transform="translate(-272.426 -314.89)" fill="#073763" />
        <path id="Path_92" data-name="Path 92" d="M70,373.33v29.221H99.221V373.33Zm25.046,25.046H74.175V377.506H95.046Z" transform="translate(-70 -314.89)" fill="#073763" />
        <path id="Path_93" data-name="Path 93" d="M363.34,400h4.174v4.174H363.34Z" transform="translate(-317.422 -337.385)" fill="#073763" />
        <path id="Path_94" data-name="Path 94" d="M550.01,400h8.347v4.174H550.01Z" transform="translate(-474.871 -337.385)" fill="#073763" />
        <path id="Path_95" data-name="Path 95" d="M123.33,426.66h12.524v12.524H123.33Z" transform="translate(-114.982 -359.872)" fill="#073763" />
        <path id="Path_96" data-name="Path 96" d="M603.33,426.66h4.175v4.175H603.33Z" transform="translate(-519.844 -359.872)" fill="#073763" />
        <path id="Path_97" data-name="Path 97" d="M283.34,453.33h4.174V457.5h4.174v4.176H283.34Z" transform="translate(-249.945 -382.367)" fill="#073763" />
        <path id="Path_98" data-name="Path 98" d="M336.66,453.33h8.348V457.5H336.66Z" transform="translate(-294.918 -382.367)" fill="#073763" />
        <path id="Path_99" data-name="Path 99" d="M550.01,506.67h4.174v4.174H550.01Z" transform="translate(-474.871 -427.358)" fill="#073763" />
        <path id="Path_100" data-name="Path 100" d="M603.33,506.67h4.175v4.174H603.33Z" transform="translate(-519.844 -427.358)" fill="#073763" />
        <path id="Path_101" data-name="Path 101" d="M496.66,533.33h4.175v4.175H496.66Z" transform="translate(-429.872 -449.844)" fill="#073763" />
      </svg>
    </>
  )
}

export default QRCode