import NumberEdit from './NumberEdit';
import NumberView from './NumberView';
import SelectEdit from './SelectEditor';


// fulltimeColumnConfigurations
export const fulltimeColumnConfigurations = {
  "date": {
    defaultValue: (employee) => employee.date,
    readOnly: true,
    className: "text-left",
    value: "",
  },
  "contract_hours": {
    defaultValue: (employee) => employee.contract_hours,
    className: "header-row text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "total_worked_hours": {
    defaultValue: (employee) => employee.total_worked_hours,
    className: "text-center bg-yellow",
    // readOnly: true,
    value: "",
  },
  "worked_hours": {
    defaultValue: (employee) => employee.worked_hours,
    className: "text-center",
    readOnly: true,
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "clocked_timings": {
    defaultValue: (employee) => employee.clocked_timings,
    className: "text-center",
    readOnly: true,
    //   DataEditor: NumberEdit,
    //   DataViewer: NumberView,
    value: "",
    data: "",
  },
  "break_hours": {
    defaultValue: (employee) => employee.break_hours,
    // readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "night_hours": {
    defaultValue: (employee) => employee.night_hours,
    // readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "split_hours": {
    defaultValue: (employee) => employee.split_hours,
    readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "overtime_1": {
    defaultValue: (employee) => employee.overtime_1,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
  "overtime_type": {
    defaultValue: (employee) => employee.overtime_type,
    // readOnly: false,
    DataViewer: SelectEdit,
    className: "text-center",
    value: "",
    data: "",
  },
  "overtime_2": {
    defaultValue: (employee) => employee.overtime_2,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },

  "leave_hours": {
    defaultValue: (employee) => employee.leave_hours,
    readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "leave_code": {
    defaultValue: (employee) => employee.leave_code,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
  "bruto_netto": {
    defaultValue: (employee) => employee.bruto_netto,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
//   "cost": {
//     defaultValue: (employee) => employee.cost,
//     readOnly: true,
//     className: "text-center",
//     value: "",
//     data: "",
//   },
  // Add more column configurations as needed
};

export const halftimeColumnConfigurations = {
  "date": {
    defaultValue: (employee) => employee.date,
    readOnly: true,
    className: "text-left",
    value: "",
  },
  "contract_hours": {
    defaultValue: (employee) => employee.contract_hours,
    className: "header-row text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "total_worked_hours": {
    defaultValue: (employee) => employee.total_worked_hours,
    className: "text-center bg-yellow",
    // readOnly: true,
    value: "",
  },
  "worked_hours": {
    defaultValue: (employee) => employee.worked_hours,
    className: "text-center",
    readOnly: true,
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "clocked_timings": {
    defaultValue: (employee) => employee.clocked_timings,
    className: "text-center",
    readOnly: true,
    //   DataEditor: NumberEdit,
    //   DataViewer: NumberView,
    value: "",
    data: "",
  },
  "break_hours": {
    defaultValue: (employee) => employee.break_hours,
    // readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "night_hours": {
    defaultValue: (employee) => employee.night_hours,
    // readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "split_hours": {
    defaultValue: (employee) => employee.split_hours,
    readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "overtime_1": {
    defaultValue: (employee) => employee.overtime_1,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
  "overtime_type": {
    defaultValue: (employee) => employee.overtime_type,
    // readOnly: false,
    DataViewer: SelectEdit,
    className: "text-center",
    value: "",
    data: "",
  },
  "overtime_2": {
    defaultValue: (employee) => employee.overtime_2,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },

  "leave_hours": {
    defaultValue: (employee) => employee.leave_hours,
    readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "leave_code": {
    defaultValue: (employee) => employee.leave_code,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
  "overtime": {
    defaultValue: (employee) => employee.overtime,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
//   "cost": {
//     defaultValue: (employee) => employee.cost,
//     readOnly: true,
//     className: "text-center",
//     value: "",
//     data: "",
//   },
  // Add more column configurations as needed
};

export const groteFlexColumnConfigurations = {
  "date": {
    defaultValue: (employee) => employee.date,
    readOnly: true,
    className: "text-left",
    value: "",
  },
  "contract_hours": {
    defaultValue: (employee) => employee.contract_hours,
    className: "header-row text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "total_worked_hours": {
    defaultValue: (employee) => employee.total_worked_hours,
    className: "text-center",
    // readOnly: true,
    value: "",
  },
  "worked_hours": {
    defaultValue: (employee) => employee.worked_hours,
    className: "text-center",
    readOnly: true,
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "clocked_timings": {
    defaultValue: (employee) => employee.clocked_timings,
    className: "text-center",
    readOnly: true,
    //   DataEditor: NumberEdit,
    //   DataViewer: NumberView,
    value: "",
    data: "",
  },
  "break_hours": {
    defaultValue: (employee) => employee.break_hours,
    // readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "night_hours": {
    defaultValue: (employee) => employee.night_hours,
    // readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "split_hours": {
    defaultValue: (employee) => employee.split_hours,
    readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "overtime_1": {
    defaultValue: (employee) => employee.overtime_1,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
  "overtime_type": {
    defaultValue: (employee) => employee.overtime_type,
    // readOnly: false,
    DataViewer: SelectEdit,
    // onChange: check,
    className: "text-center",
    value: "",
    data: "",
  },
  "overtime_2": {
    defaultValue: (employee) => employee.overtime_2,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },

  "leave_hours": {
    defaultValue: (employee) => employee.leave_hours,
    readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "leave_code": {
    defaultValue: (employee) => employee.leave_code,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
  "plusuren": {
    defaultValue: (employee) => employee.plusuren,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
  "bruto_netto": {
    defaultValue: (employee) => employee.bruto_netto,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
//   "cost": {
//     defaultValue: (employee) => employee.cost,
//     readOnly: true,
//     className: "text-center",
//     value: "",
//     data: "",
//   },
  // Add more column configurations as needed
};

export const groteFlexPartTimeColumnConfigurations = {
  "date": {
    defaultValue: (employee) => employee.date,
    readOnly: true,
    className: "text-left",
    value: "",
  },
  "contract_hours": {
    defaultValue: (employee) => employee.contract_hours,
    className: "header-row text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "total_worked_hours": {
    defaultValue: (employee) => employee.total_worked_hours,
    className: "text-center",
    // readOnly: true,
    value: "",
  },
  "worked_hours": {
    defaultValue: (employee) => employee.worked_hours,
    className: "text-center",
    readOnly: true,
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "clocked_timings": {
    defaultValue: (employee) => employee.clocked_timings,
    className: "text-center",
    readOnly: true,
    //   DataEditor: NumberEdit,
    //   DataViewer: NumberView,
    value: "",
    data: "",
  },
  "break_hours": {
    defaultValue: (employee) => employee.break_hours,
    // readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "night_hours": {
    defaultValue: (employee) => employee.night_hours,
    // readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "split_hours": {
    defaultValue: (employee) => employee.split_hours,
    readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "overtime_1": {
    defaultValue: (employee) => employee.overtime_1,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
  "overtime_type": {
    defaultValue: (employee) => employee.overtime_type,
    // readOnly: false,
    DataViewer: SelectEdit,
    // onChange: check,
    className: "text-center",
    value: "",
    data: "",
  },
  "overtime_2": {
    defaultValue: (employee) => employee.overtime_2,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },

  "leave_hours": {
    defaultValue: (employee) => employee.leave_hours,
    readOnly: true,
    className: "text-center",
    // DataEditor: NumberEdit,
    // DataViewer: NumberView,
    value: "",
    data: "",
  },
  "leave_code": {
    defaultValue: (employee) => employee.leave_code,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
  "plusuren": {
    defaultValue: (employee) => employee.plusuren,
    readOnly: true,
    className: "text-center",
    value: "",
    data: "",
  },
//   "cost": {
//     defaultValue: (employee) => employee.cost,
//     readOnly: true,
//     className: "text-center",
//     value: "",
//     data: "",
//   },
  // Add more column configurations as needed
};


// Column conf for total and week total rows
export const totalColumnConfigurations = {
  "week": {
    defaultValue: (employee) => employee.week,
    readOnly: true,
    className: "bg-yellow text-left",
    value: "",
  },
  "contract_hours": {
    defaultValue: (employee) => employee.contract_hours,
    readOnly: true,
    className: "header-row text-center bg-yellow",
    value: "",
  },
  "total_worked_hours": {
    defaultValue: (employee) => employee.total_worked_hours,
    className: "text-center bg-yellow",
    readOnly: true,
    value: "",
  },
  "worked_hours": {
    defaultValue: (employee) => employee.worked_hours,
    className: "text-center bg-yellow",
    readOnly: true,
    value: "",
  },
  "clocked_timings": {
    defaultValue: (employee) => employee.clocked_timings,
    className: "text-center bg-yellow",
    readOnly: true,
    value: "",
  },
  "break_hours": {
    defaultValue: (employee) => employee.break_hours,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },
  "night_hours": {
    defaultValue: (employee) => employee.night_hours,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },
  "split_hours": {
    defaultValue: (employee) => employee.split_hours,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },
  "overtime_1": {
    defaultValue: (employee) => employee.overtime_1,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },
  "overtime_type": {
    defaultValue: (employee) => employee.overtime_type,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },
  "overtime_2": {
    defaultValue: (employee) => employee.overtime_2,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },

  "leave_hours": {
    defaultValue: (employee) => employee.leave_hours,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },
  "leave_code": {
    defaultValue: (employee) => employee.leave_code,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },
  "plusuren": {
    defaultValue: (employee) => employee.plusuren,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },
  "bruto_netto": {
    defaultValue: (employee) => employee.bruto_netto,
    readOnly: true,
    className: "text-center bg-yellow",
    value: "",
  },
//   "cost": {
//     defaultValue: (employee) => employee.cost,
//     readOnly: true,
//     className: "text-center bg-yellow",
//     value: "",
//   },
  // Add more column configurations as needed
};