import React from 'react'

const Sectors = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="72.77" height="72.778" viewBox="0 0 72.77 72.778">
        <g id="Group_27816" data-name="Group 27816" transform="translate(-10172.838 9297.066)">
          <path id="Path_55751" data-name="Path 55751" d="M10217.8-9264.8v-32.271a39.989,39.989,0,0,0-18.326,6.915,39.318,39.318,0,0,0-11.87,14.407Z" transform="translate(-11.573 0.001)" fill="#073763" />
          <path id="Path_55752" data-name="Path 55752" d="M10352.935-9261.381v-35.153s23.973,1.382,31.811,24.55-9.451,37.8-9.451,37.8Z" transform="translate(-141.177 -0.417)" fill="#073763" />
          <path id="Path_55753" data-name="Path 55753" d="M10202.653-9165.245l-28.47-10.489a51.313,51.313,0,0,0-1.268,13.369,32.859,32.859,0,0,0,3.113,11.3Z" transform="translate(0 -95.111)" fill="#073763" />
          <path id="Path_55754" data-name="Path 55754" d="M10229-9113.867l-30.2,16.021s7.491,13.255,23.4,16.481,28.238-5.994,28.238-5.994Z" transform="translate(-20.351 -143.608)" fill="#073763" />
        </g>
      </svg>
    </>
  )
}

export default Sectors