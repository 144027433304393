import React from 'react'

const EmailTemplate = () => {
  return (
    <>
      <svg id="Email_template" data-name="Email template" xmlns="http://www.w3.org/2000/svg" width="57.627" height="57.627" viewBox="0 0 57.627 57.627">
        <path id="Path_55744" data-name="Path 55744" d="M57.267,55.658,37.57,36.017,57.21,16.376a3.247,3.247,0,0,1,.45,1.632V54.026A3.607,3.607,0,0,1,57.267,55.658ZM50.513,18.009h-.056v.056L36.05,32.472v-.056H21.643v.056L7.237,18.065v-.056H7.18L3.635,14.463V3.6A3.434,3.434,0,0,1,4.7,1.041,3.508,3.508,0,0,1,7.237,0h43.22a3.48,3.48,0,0,1,2.561,1.041A3.48,3.48,0,0,1,54.059,3.6V14.463ZM20.124,36.017.427,55.658a3.607,3.607,0,0,1-.394-1.632V18.009a3.247,3.247,0,0,1,.45-1.632Zm12.381,0,21.61,21.61H3.579l21.61-21.61Z" transform="translate(-0.033 0)" fill="#073763" />
      </svg>

    </>
  )
}

export default EmailTemplate