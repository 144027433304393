import React from 'react'

const AddLocation = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22.03 25.503">
        <g id="Group_6532" data-name="Group 6532" transform="translate(-153.701 -235.444)">
          <path id="Subtraction_80" data-name="Subtraction 80" d="M7.946,21.377a1.35,1.35,0,0,1-1.136-.616L1.288,12.28A7.944,7.944,0,0,1,13.562,2.327h0A7.937,7.937,0,0,1,14.6,12.28L9.079,20.761A1.347,1.347,0,0,1,7.946,21.377Zm0-16.353a2.924,2.924,0,1,0,2.923,2.925A2.929,2.929,0,0,0,7.946,5.024Z" transform="translate(153.701 235.444)" fill="#073763" />
          <path id="Subtraction_94" data-name="Subtraction 94" d="M5.017,9.99A5,5,0,0,1,1.469,8.527a4.98,4.98,0,0,1,0-7.064,5.032,5.032,0,0,1,7.095,0A5,5,0,0,1,5.017,9.99ZM3.627,4.726a.27.27,0,1,0,0,.54h1.1V6.379a.271.271,0,0,0,.542,0V5.266H6.407a.27.27,0,1,0,0-.54H5.27V3.611a.271.271,0,0,0-.542,0V4.726Z" transform="translate(165.697 250.957)" fill="#073763" />
        </g>
      </svg>
    </>
  )
}

export default AddLocation