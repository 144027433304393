import React, { useState } from "react";
// import AddEmployeeIcon from "../static/icons/AddEmployee.svg"
import ExportIcon from "../static/icons/export.svg"
import EmployeeListWithDetails from "../components/organisms/EmployeeListWithDetails";
import BackIcon from "../static/icons/BackIcon.png"
import { useNavigate, useParams } from "react-router-dom";
import { t } from "../translations/Translation";
import InviteEmployeePopup from "../components/molecules/InviteEmployeePopup"
import AddEmployee from "../static/icons/AddEmployee";
import ImportIcon from "../static/icons/Import.svg"
import InviteEmployee from "../static/icons/InviteEmployee"
import Switch from "../components/atoms/Switch";
import InvitedEmployeeOverview from "../components/molecules/InvitedEmployeesOverview";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ExportEmployeesPopup from "../components/molecules/ExportEmployeesPopup";

export default function Employees() {

    const navigate = useNavigate();
    const params = useParams();
    const [showDetails, setShowDetails] = useState(params.id ? true : false);
    const [openPopup, setOpenPopup] = useState(false)
    const [isArchived, setIsArchived] = useState(false)

    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];

    const handleInvite = () => {
        setOpenPopup(true)

    }
    const onHide = () => {
        setOpenPopup(false)
    }

    return (
        <div className="right-container">
            {openPopup&&<ExportEmployeesPopup onHide={onHide}></ExportEmployeesPopup>}
            {/* {openPopup ? <InviteEmployeePopup onHide={onHide} setOpenPopup={setOpenPopup} openPopup={openPopup}></InviteEmployeePopup> : */}
            {/* {openPopup ? <InvitedEmployeeOverview onHide={onHide} setOpenPopup={setOpenPopup} openPopup={openPopup}></InvitedEmployeeOverview> : */}
            <div className="company-tab-width mt-3 border bg-white">
                <div className="col-md-12 row mt-3 mx-0 px-0 ">
                    <div className="col-md-6 float-left">
                        <h4 className="d-flex align-items-center">{showDetails && <img className="shortcut-icon mr-2 pointer" onClick={() => { setShowDetails(false); navigate('/manage-employees') }} src={BackIcon} alt="Back"></img>}{t("EMPLOYEES_TITLE")}</h4>
                    </div>
                    <div className="col-md-6 float-right d-flex">
                        {/* <div className="col-2 d-flex justify-content-end"><Switch label={t("ARCHIVED")} id="switch3" lableClick={true} styleClass="text-nowrap mb-0 px-3" onChange={() => setIsArchived(!isArchived)} checked={isArchived}></Switch></div> */}
                        <div className="col-12">
                            <ul className="d-flex float-right">
                                {!showDetails && <li className="list-group d-inline"><Switch label={t("ARCHIVED")} id="switch3" lableClick={true} styleClass="text-nowrap mb-0 px-3" onChange={() => setIsArchived(!isArchived)} checked={isArchived}></Switch></li>}
                                {!showDetails && UserPermissions?.includes('add_employee') &&
                                    <li className="list-group d-inline add_btn" onClick={() => navigate('/add-employees')}>
                                        <AddEmployee />
                                        <span className="">{t("CREATE_EMPLOYEE")}</span>
                                    </li>}
                                <li className="list-group d-inline ml-5">
                                    <img className="header-icon" src={ImportIcon} alt={t("IMPORT")} data-tooltip-id={t("IMPORT_EMPLOYEES")} onClick={() => navigate("/import-employees")} />
                                    <ReactTooltip
                                        id={t("IMPORT_EMPLOYEES")}
                                        place="bottom"
                                        globalEventOff="click"
                                        content={t("IMPORT_EMPLOYEES")}
                                        style={{ zIndex: 9999 }}
                                    />
                                </li>
                                {UserPermissions?.includes('invite_employee') && <li className="list-group d-inline ml-3 p-0" onClick={() => navigate('/manage-invites')} data-tooltip-id={t("INVITE_EMPLOYEE")}>
                                    <InviteEmployee color="#292929" />
                                    <ReactTooltip
                                        id={t("INVITE_EMPLOYEE")}
                                        place="bottom"
                                        globalEventOff="click"
                                        content={t("INVITE_EMPLOYEE")}
                                        style={{ zIndex: 9999 }}
                                    />
                                </li>}
                                {/* <img className="header-icon" src={EmailForwardersIcon} alt={t("INVITE_EMPLOYEE")} title={t("INVITE_EMPLOYEE")} /></li> */}
                                {/* <li className="list-group d-inline ml-3"><img className="header-icon" src={FilterIcon} alt={t("FILTER")} title={t("FILTER")} /></li> */}
                                <li className="list-group d-inline ml-3">
                                    <img className="header-icon" src={ExportIcon} alt={t("EXPORT")} data-tooltip-id={t("EXPORT")} onClick={() => setOpenPopup(true)} />
                                    <ReactTooltip
                                        id={t("EXPORT")}
                                        place="bottom"
                                        globalEventOff="click"
                                        content={t("EXPORT")}
                                        style={{ zIndex: 9999 }}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {!openPopup && <EmployeeListWithDetails setShowDetails={params.id ? () => setShowDetails : setShowDetails} showDetails={showDetails} isArchived={isArchived}></EmployeeListWithDetails>}
            </div>
        </div>

    )
}
