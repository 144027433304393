import React, { useEffect, useRef, useState } from "react";
import Autosuggest from 'react-autosuggest';
import { useNavigate } from "react-router-dom";
import { t } from "../../translations/Translation";


export default function AutoSuggestion({ setSearchBar, searchBar }) {

    const [suggestions, setSuggestions] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        let suggestions_arr = [
            //Dashboard
            { name: t('DASHBOARD'), url: '/' },
            { name: t('PLANNING'), url: '/manage-plannings' },
            { name: t('TIME_TABLE'), url: '/uurrooster' },
            { name: t('AVAILABILITY'), url: '/manage-employees#employee_availability' },
            { name: t('NEW_EMPLOYEE'), url: '/add-employees' },
            { name: t('QR_CONFIGURATION'), url: '/' },
            { name: t('HOLIDAY_LEAVE'), url: '/manage-plannings#holiday' },
            { name: t('DIMONA_OVERVIEW'), url: '/manage-plannings#dimona' },
            { name: t('DEVICE_ACTIVATE'), url: '/' },
            { name: t('INVITE_EMPLOYEE_OVERVIEW'), url: '/manage-invites' },

            //Sidebar
            { name: t('EMPLOYEES'), url: '/manage-employees' },
            { name: t('COMPANIES'), url: '/manage-companies' },
            { name: t('CURRENT_PLAN'), url: '/current-plan' },
            { name: t('CONFIGURATIONS'), url: '/configurations' },
            { name: t('SETTINGS'), url: '/settings' },
            { name: t('REPORTING'), url: '/reporting' },
            { name: t('MONTHLY_OVERVIEW'), url: '/monthly-overview' },

            //Planning
            { name: t('PLANNING_LOGS'), url: '/manage-plannings#logs' },
            { name: t('EVENT_PLANNING'), url: '/manage-plannings#event' },
            { name: t('OPEN_SHIFT'), url: '/manage-plannings#open_shift' },
            { name: t('OTH_PLANNING'), url: '/manage-plannings#oth' },

            //Plan tab
            { name: t('SEND_DIMONA'), url: '/manage-plannings' },
            { name: t('IMPORT_PLANNING'), url: '/manage-plannings' },
            { name: t('ADD_LEAVE'), url: '/manage-plannings' },
            { name: t('CLONE_PLANNING'), url: '/manage-plannings' },

            //Employees
            { name: t('IMPORT_EMPLOYEES'), url: '/import-employees' },

            //Companies
            { name: t('CREATE') + " " + t('COMPANY'), url: '/manage-companies/company/0' },
            { name: t('LOCATIONS'), url: '/manage-companies#location' },
            { name: t('WORKSTATION'), url: '/manage-companies#workstation' },
            { name: t('RESP_PERSON'), url: '/manage-companies#responsible_person' },
            { name: t('COST_CENTER'), url: '/manage-companies#cost_center' },
            { name: t('CONTRACTS'), url: '/manage-companies#company_contract' },

            //Configurations
            { name: t('CONTRACT_TYPE'), url: '/manage-configurations/contract_type' },
            { name: t('ADD_CONTRACT'), url: '/add-contract-type' },

            { name: t('EMPLOYEE_TYPES'), url: '/manage-configurations/employee_type' },
            { name: t('ADD_EMPLOYEE_TYPE'), url: '/add-employee-type' },

            { name: t('FUNCTIONS'), url: '/manage-configurations/functions' },
            { name: t('ADD_FUNCTION'), url: '/add-function' },

            { name: t('GROUP_FUNCTIONS'), url: '/manage-configurations/group_functions' },
            { name: t('ADD_GROUP_FUNCTION'), url: '/add-group-function' },

            { name: t('SECTORS'), url: '/manage-configurations/sectors' },
            { name: t('ADD_SECTOR'), url: '/add-sector' },

            { name: t('MIN_SALARY'), url: '/manage-configurations/min_salary' },
            { name: t('ADD_FLEX_SALARY'), url: '/add-flex-salary' },
            { name: t('INCREASE_MINIMUM_SALARIES'), url: '/manage-configurations/min_salary' },

            { name: t('REASONS'), url: '/manage-configurations/reasons' },
            { name: t('ADD_REASONS'), url: '/add-reasons' },

            { name: t('SOCIAL_SECRETARY'), url: '/manage-configurations/social_secretary' },
            { name: t('ADD_SOCIAL_SECRETARY'), url: '/add-social-secretary' },

            { name: t('INTERIM_AGENCIES'), url: '/manage-configurations/interim-agencies' },
            { name: t('ADD_INTERIM_AGENCIES'), url: '/add-interim-agency' },

            { name: t('DEFAULT_PARAMETER'), url: '/manage-configurations/default_param' },
            { name: t('PARAMETER'), url: '/manage-configurations/parameters' },

            { name: t('DIMONA_NAMESPACE'), url: '/manage-configurations/dimona_namespace' },
            { name: t('ADD_DIMONA_NAMESPACE'), url: '/add-dimona-namespace' },

            { name: 'SOCIAL_SECRETARY_ENGINES', url: '/manage-configurations/social_secretary_engines' },

            { name: t('ROLES'), url: '/manage-configurations/roles' },
            { name: t('ADD_ROLE'), url: '/add-role' },

            { name: t('HOLIDAY_CODES'), url: '/manage-holiday-configurations/holiday_code' },
            { name: t('ADD_HOLIDAY_CODE'), url: '/add-holiday-code' },

            { name: t('HOLIDAY_CODE_CONFIGURATION'), url: '/manage-holiday-configurations/holiday_code_configuration' },

            { name: t('MANAGE_PUBLIC_HOLIDAY'), url: '/manage-holiday-configurations/public_holiday_configuration' },
            { name: t('ADD_PUBLIC_HOLIDAY'), url: '/add-public-holiday' },

            { name: t('EMAIL_TEMPLATES'), url: '/manage-communication-configurations/email' },
            { name: t('TRANSLATIONS'), url: '/manage-communication-configurations/translation' },

            { name: t('CONTRACT_TEMPLATES'), url: 'manage-communication-configurations/contracts_template' },
            { name: t('ADD_CONTRACT_TEMPLATE'), url: '/add-contracts-template/template' },

            { name: t('FAQ_OVERVIEW'), url: '/faq-overview' },

            { name: t('TAXES'), url: '/manage-social-secretary-and-reporting-configurations/taxes' },
            { name: t('ADD_TAXES'), url: '/add-taxes' },

            { name: t('SALARY_COEFFICIENT'), url: '/manage-social-secretary-and-reporting-configurations/salary_coefficient' },
            { name: t('ADD_SALARY_COEFFICIENT'), url: '/add-salary-coefficient' },

            { name: t('WORKCODES'), url: '/manage-social-secretary-and-reporting-configurations/workcodes' },
            { name: t('ADD_WORKCODE'), url: '/add-workcode' },

            //Settings
            { name: t('GENDER'), url: '/settings' },
            { name: t('MARITAL_STATUS'), url: '/settings' },
            { name: t('MEAL_VOUCHERS'), url: '/settings' },
            { name: t('COMMUTE_TYPES'), url: '/settings' },
            { name: t('DIMONA_ERROR_CODES'), url: '/settings' },

            //Monthly overview
            { name: t('PROCESS_PLANNINGS'), url: '/monthly-overview' },
            { name: t('EXPORT_SOCIAL_SECRETARY'), url: '/monthly-overview' },

            { name: t('MY_ACCOUNT'), url: '/my-account' },
            { name: t('CHANGE_PASSWORD'), url: '/my-account#changePassword' },
            { name: t('ENABLE_NOTIFICATION'), url: '/my-account#enableNotification' },
        ]
        setSuggestions(suggestions_arr);
    }, [refresh])

    const onSuggestionSelected = (event, { suggestion }) => {
        setSearchBar(!searchBar);
        setValue('');
        navigate(suggestion.url);
    }

    const handleSearch = (val) => {
        const searchTerm = val
        const results = suggestions.filter(item =>
            // console.log(item)
            item.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setSuggestions(results);
    };


    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={({ value }) => {
                // Fetch suggestions based on the input value
                // and update the suggestions state
                handleSearch(value);
                // setSuggestions(data);
            }}
            onSuggestionsClearRequested={() => {
                // Clear suggestions when needed
                // setSuggestions([]);
                setRefresh(!refresh)
            }}
            getSuggestionValue={(suggestion) => suggestion.name}
            renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
            inputProps={{
                placeholder: 'Type something...',
                value,
                onChange: (_, { newValue }) => setValue(newValue),
                ref: inputRef,
            }}
            onSuggestionSelected={onSuggestionSelected}
            highlightFirstSuggestion={true}
        />
    )
}
