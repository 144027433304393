import { t } from '../../../../translations/Translation';
import RowObjectBuilder from './RowObjectBuilder';
import { fulltimeColumnConfigurations, groteFlexColumnConfigurations, groteFlexPartTimeColumnConfigurations, halftimeColumnConfigurations, totalColumnConfigurations } from "./SheetColumnConfigurations";


//  { value: `=(D${index + 1}+G${index + 1})`, readOnly: true }, // Replace D1 and G1 with dynamic index
export const ConstructReportingData = (spreadsheetData, setIndex, sheetType, setSpreadSheetData) => {
  const columnLabels = sheetType === 'grote_flex_full_time' ? [
    t("DATE"),
    t("CONTRACT_HOURS"),
    t("TOTAL_WORKED_HOURS"),
    t("WORKED_HOURS"),
    t("TIMINGS"),
    t("BREAK_TOTAL"),
    t("NIGHT_HOURS"),
    t("SPLIT"),
    t("OVERTIME_100"),
    t("OVERTIME_TYPE"),
    t("OVERTIME_2"),
    t("LEAVE_HOURS"),
    t("LEAVE_CODE"),
    t("PLUS_HOURS"),
    t("BRUTO_NETTO"),
    // t("COST_TITLE"),
  ] : sheetType === 'bruto_netto_part_time' ? [
    t("DATE"),
    t("CONTRACT_HOURS"),
    t("TOTAL_WORKED_HOURS"),
    t("WORKED_HOURS"),
    t("TIMINGS"),
    t("BREAK_TOTAL"),
    t("NIGHT_HOURS"),
    t("SPLIT"),
    t("OVERTIME_100"),
    t("OVERTIME_TYPE"),
    t("OVERTIME_2"),
    t("LEAVE_HOURS"),
    t("LEAVE_CODE"),
    t("OVERTIME"),
    // t("COST_TITLE"),
  ] : sheetType === 'grote_flex_part_time' ? [
    t("DATE"),
    t("CONTRACT_HOURS"),
    t("TOTAL_WORKED_HOURS"),
    t("WORKED_HOURS"),
    t("TIMINGS"),
    t("BREAK_TOTAL"),
    t("NIGHT_HOURS"),
    t("SPLIT"),
    t("OVERTIME_100"),
    t("OVERTIME_TYPE"),
    t("OVERTIME_2"),
    t("LEAVE_HOURS"),
    t("LEAVE_CODE"),
    t("PLUS_HOURS"),
  ] : [
    t("DATE"),
    t("CONTRACT_HOURS"),
    t("TOTAL_WORKED_HOURS"),
    t("WORKED_HOURS"),
    t("TIMINGS"),
    t("BREAK_TOTAL"),
    t("NIGHT_HOURS"),
    t("SPLIT"),
    t("OVERTIME_100"),
    t("OVERTIME_TYPE"),
    t("OVERTIME_2"),
    t("LEAVE_HOURS"),
    t("LEAVE_CODE"),
    t("BRUTO_NETTO"),
    // t("COST_TITLE"),
  ];


  let columnConf;
  let totalColConf = totalColumnConfigurations
  if (sheetType === 'bruto_netto_full_time') {
    columnConf = fulltimeColumnConfigurations
    delete totalColConf.plus_hours
  } else if (sheetType === 'grote_flex_full_time') {
    columnConf = groteFlexColumnConfigurations
  } else if (sheetType === 'bruto_netto_part_time') {
    columnConf = halftimeColumnConfigurations
    delete totalColConf.plus_hours
  } else if (sheetType = 'grote_flex_part_time') {
    columnConf = groteFlexPartTimeColumnConfigurations
  } else {
    columnConf = {}
  }

  // Create an instance of RowObjectBuilder
  const rowObjectBuilder = new RowObjectBuilder(columnConf, totalColConf);
  // Build row objects based on employee data and column configurations
  const rowObjects = rowObjectBuilder.buildRowObject(spreadsheetData, setIndex, sheetType, setSpreadSheetData);

  return {
    'columnLabels': columnLabels,
    // 'rowLables': rowLables,
    'data': rowObjects

  };
}