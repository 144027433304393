import React from 'react'

const AddCompany = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 28.003">
        <g id="Group_6254" data-name="Group 6254" transform="translate(-155.963 -239.83)">
          <path id="Subtraction_79" data-name="Subtraction 79" d="M19367.252,4009.832H19359v-25.2a2.778,2.778,0,0,1,2.748-2.8h16.5a2.782,2.782,0,0,1,2.754,2.8v25.2h-8.254v-5.6h-5.5v5.6Zm4.123-12.6h0v4.2h4.125v-4.2h-4.125Zm-6.877,0h0v4.2h4.125v-4.2h-4.125Zm6.877-7h0v4.2h4.125v-4.2h-4.125Zm-6.877,0h0v4.2h4.125v-4.2h-4.125Z" transform="translate(-19203.039 -3741.999)" fill="#292929" />
        </g>
      </svg>
    </>
  )
}

export default AddCompany