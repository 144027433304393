import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { t } from "../../translations/Translation";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../static/icons/BackIcon.png"
import Table from "../atoms/Table";
import DeleteIcon from "../../static/icons/Delete.svg"
import MoveIcon from "../../static/icons/Move.png"
import MoveEmployeePopup from "./MoveEmployeePopup";

export default function EventEmployeeList() {

    const [tabIndex, setTabIndex] = useState(0);
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];
    const [events, setEvents] = useState([]);
    const [movePopup, setMovePopup] = useState(false);
    const navigate = useNavigate();

    const TabsData = [
        { tabHeading: t("ALL"), tabName: 'all', permission: 'modify_event_plannings' },
        { tabHeading: t("FILLED"), tabName: 'filled', permission: 'modify_event_plannings' },
        { tabHeading: t("WAITING"), tabName: 'waiting', permission: 'modify_event_plannings' },
    ]

    const headers = [
        {
            title: t("EMPLOYEE_NAME"),
            field: 'name',
            size: 200,
        },
        {
            title: t("STATUS_TEXT"),
            field: 'status',
            size: 200,
        },
        {
            title: t('ACTIONS'),
            render: rowData => (
                <div className="text-left">
                    <img className="planning-icon " src={MoveIcon} alt="move" onClick={() => viewAction(rowData, 'move')}></img>
                    <img className="header-icon ml-2" src={DeleteIcon} alt="delete" onClick={() => viewAction(rowData, 'delete')}></img>
                </div>
            )
        }
    ]

    const listData = [
        { id: 1, name: 'Employee 01', status: 'Filled' },
        { id: 2, name: 'Employee 02', status: 'Filled' },
        { id: 3, name: 'Employee 03', status: 'Filled' },
        { id: 4, name: 'Employee 04', status: 'Waiting' },
        { id: 5, name: 'Employee 05', status: 'Waiting' },
    ]

    const FilledListData = [
        { id: 1, name: 'Employee 01', status: 'Filled' },
        { id: 2, name: 'Employee 02', status: 'Filled' },
        { id: 3, name: 'Employee 03', status: 'Filled' },
    ]

    const WaitingListData = [
        { id: 4, name: 'Employee 04', status: 'Waiting' },
        { id: 5, name: 'Employee 05', status: 'Waiting' },
    ]

    const viewAction = (data, action) => {
        // setEvents(data);
        if (action === 'move') {
            openPopupToMoveEmployees('all');
        } else {

        }
    }

    const openPopupToMoveEmployees = (type) => {
        setMovePopup(true);
    }

    const setEmployees = (data) => {
        data.map((val, i) => {
            events.push(val.id);
        })
        setEvents(events);
    }

    const OnEventSelect = (rows) => {
        setEmployees(rows);
    }


    return (
        <div className="right-container">
            <div className="company-tab-width mt-3">
                {movePopup && <MoveEmployeePopup
                    MoveEmployees={console.log('moved')} setMovePopup={setMovePopup}
                ></MoveEmployeePopup>}
                <h3 className="text-left mt-2 py-1 pl-2 mb-2 text-color bg-white font-weight-bold"><img className="shortcut-icon mr-2 pointer" onClick={() => navigate('/manage-plannings#event')} src={BackIcon} alt="Back" />{t("APPLICANT_OVERVIEW")}</h3>

                <Tabs className="h-100 mt-1 pt-1" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                    <TabList className="d-flex p-0 mb-0">
                        {TabsData.map((val) => {
                            if (UserPermissions?.includes(val.permission)) {
                                return (
                                    <Tab className="planing_tabs" key={val.tabName} >{val.tabHeading}</Tab>
                                )
                            }
                        })}
                    </TabList>

                    {UserPermissions?.includes('modify_event_plannings') && <TabPanel>
                        <Table tableName={'events'} columns={headers} rows={listData} setDimonaData={OnEventSelect} viewAction={viewAction} ></Table>

                    </TabPanel>}

                    {UserPermissions?.includes('modify_event_plannings') && <TabPanel>
                        <Table tableName={'events'} columns={headers} rows={FilledListData} setDimonaData={OnEventSelect} viewAction={viewAction} ></Table>
                    </TabPanel>}

                    {UserPermissions?.includes('modify_event_plannings') && <TabPanel>
                        <Table tableName={'events'} columns={headers} rows={WaitingListData} setDimonaData={OnEventSelect} viewAction={viewAction} ></Table>
                    </TabPanel>}
                </Tabs>
            </div>
        </div>
    )
}
