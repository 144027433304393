import React from "react";

function FAQConfig() {
    return (

        <svg id="SvgjsSvg1011" width="28" height="28" xmlns="http://www.w3.org/2000/svg" version="1.1" xlink="http://www.w3.org/1999/xlink" svgjs="http://svgjs.com/svgjs">
            <defs id="SvgjsDefs1012"></defs>
            <g id="SvgjsG1013"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28" width="60" height="60">
                <path fill="#073763" fill-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm-1.086-8.724c-.084.56.392 1.022.958 1.022h.47a.525.525 0 0 0 .524-.492c.057-.526.283-.986.676-1.379l.63-.62c.492-.495.836-.944 1.033-1.347.197-.409.295-.842.295-1.299 0-1.005-.303-1.782-.909-2.33C13.985 6.277 13.133 6 12.035 6c-1.088 0-1.947.29-2.58.871a2.84 2.84 0 0 0-.793 1.28c-.184.604.36 1.131.99 1.131.534 0 .933-.435 1.304-.84.052-.057.103-.114.154-.168.234-.247.542-.37.925-.37.808 0 1.212.454 1.212 1.362 0 .301-.078.589-.233.863-.155.269-.469.621-.94 1.056-.466.43-.787.869-.964 1.315-.087.221-.152.48-.196.776Zm.057 2.611c-.233.231-.35.527-.35.887 0 .355.114.648.342.88.233.23.539.346.917.346s.68-.116.909-.347c.233-.231.35-.524.35-.879 0-.36-.12-.656-.358-.887-.233-.237-.534-.355-.901-.355-.368 0-.671.118-.91.355Z" clip-rule="evenodd" class="color000 svgShape"></path>
            </svg></g>
        </svg>
    );
}

export default FAQConfig;