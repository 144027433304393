import React, { useEffect, useState } from 'react';
import Spreadsheet from 'react-spreadsheet';
import { ConstructReportingData } from './atoms/ConstructReportingData';
import { APICALL as AXIOS } from "../../../services/AxiosServices";
import { UpdateEmployeeRecordApiUrl } from '../../../routes/ApiEndPoints';
import AddLeavePopup from '../../molecules/AddLeavePopup';
import ErrorPopup from '../../../utilities/popup/ErrorPopup';
import { t } from '../../../translations/Translation';
import LoadingIcon from '../../../utilities/LoadingIcon';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


export default function ReportingOverview({ spreadsheetData, sheetType, employee, setDataRefresh, dataRefresh }) {

  const [data, setData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [index, setIndex] = useState('');
  const [errors, setErrors] = useState([]);
  const [editingSheet, setEditingSheet] = useState(false);
  const [batchData, setBatchData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [popupOpen, setPopupOpen] = useState(false);
  const [leaveData, setLeaveData] = useState({})
  let temp;
  const params = useParams();


  const filterDataObject = JSON.parse(localStorage.getItem('reporting_filters'));

  useEffect(() => {
    // Construct spreadsheet data
    const employee_data = ConstructReportingData(spreadsheetData, setIndex, sheetType, setOvertimeData)
    setEmployeeData(employee_data)
    setData(employee_data.data)
    temp = employee_data.data
  }, [spreadsheetData])


  // useEffect(() => {
  //   if (data[index]) {
  //     let req_data = {
  //       "plan_id": data[index][1].plan_id,
  //       "contract_hours": data[index][1].value,
  //       "worked_hours": data[index][2].value,
  //       "break_hours": data[index][5].value
  //     }

  //     AXIOS.service(UpdateEmployeeRecordApiUrl, 'POST', req_data)
  //       .then((result) => {
  //         if (result?.success) {
  //           setIndex('');
  //         } else {
  //           setErrors(result.message)
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //   }
  // }, [index])

  const updateValue = (updated_data, update_index) => {
    let selected_index = update_index ? update_index : index
    if (updated_data[selected_index] !== undefined && updated_data[selected_index][1].plan_id !== null && updated_data[selected_index][1].value !== '' && updated_data[selected_index][3].value !== '' && updated_data[selected_index][5].value !== '') {
      // let work_hr
      // if ((updated_data[selected_index][3].value)?.includes('G')) {
      //   work_hr = spreadsheetData[selected_index]?.worked_hours - parseInt(updated_data[selected_index][6].value)

      // } else if ((toString(spreadsheetData[selected_index].break_hours) === updated_data[selected_index][6].value) || (toString(spreadsheetData[selected_index].worked_hours) !== updated_data[selected_index][3].value)) {
      //   work_hr = updated_data[selected_index][3].value

      // } else {
      //   work_hr = spreadsheetData[selected_index].worked_hours
      // }
      let req_data = {
        "plan_id": updated_data[selected_index][1].plan_id,
        "contract_hours": updated_data[selected_index][1].value,
        // "worked_hours": work_hr,
        "break_hours": updated_data[selected_index][5].value,
        "total_worked_hours": updated_data[selected_index][2].value,
        "night_hours": updated_data[selected_index][6].value ? updated_data[selected_index][6].value : 0,
        "overtime_type": updated_data[selected_index][9]?.value,

        "start_date": filterDataObject?.start_date,
        "end_date": filterDataObject?.end_date,
        "employee_types": filterDataObject?.employee_types,
        "locations": filterDataObject?.locations,
      }

      AXIOS.service(UpdateEmployeeRecordApiUrl, 'POST', req_data)
        .then((result) => {
          if (result?.success) {
            setIndex('');
            const employee_data = ConstructReportingData(result.data?.planning_details, setIndex, result.data?.sheet_type, setOvertimeData)
            setEmployeeData(employee_data)
            setData(employee_data.data)
            temp = employee_data.data
            setLoading(false);
          } else {
            setErrors(result.message)
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }


  // ADD LEAVE POPUP CODE
  const openAddLeavePopup = (selected_item) => {
    if ((selected_item.range?.start.column === 11 || selected_item.range?.start.column === 12) && data[selected_item.range?.start.row] !== undefined) {
      setPopupOpen(true)

      if (data[selected_item.range?.start.row][1]?.absense_id) {
        setLeaveData({
          "absence_id": data[selected_item.range?.start.row][1]?.absense_id
        })
      } else {
        setLeaveData({
          "employee_profile_id": params.id,
          "full_name": employee.fullname,
          "dates": [data[selected_item.range?.start.row][0]?.value]
        })
      }
    }
  }

  const setOvertimeData = (cell) => {
    let newData = [...temp]

    newData[cell?.index][9] = cell
    // if (cell.value === 'bruto_netto') {
    //   let changed_data = newData[cell?.index][14]
    //   changed_data['value'] = cell?.index === 0 ? spreadsheetData[cell?.index]['overtime_100'] : (spreadsheetData[cell?.index]['overtime_100'] + spreadsheetData[cell?.index - 1]['bruto_netto'])
    //   newData[cell?.index][14] = changed_data
    // }
    temp = newData;
    // setEditingSheet(true);
    // setData(newData);
    setIndex(cell.index)
    updateValue(newData, cell?.index);
  }

  const setSpreadSheetData = (changes) => {
    // Update data state only if necessary
    // updateValue(changes);

    if (editingSheet) {
      setData(changes);
      // updateValue(changes);
      setEditingSheet(false);
    } else {
      setBatchData(changes);
    }
  };

  const keyDown = (event) => {
    if (event.keyCode == 13 || event.key == "Enter") {
      setLoading(true)
      setEditingSheet(true);
    }
  }

  useEffect(() => {
    if (editingSheet) {
      // Set editingSheet to false after data has been updated
      // setEditingSheet(false);
      // batchData.length !== 0 && updateValue(batchData);
      batchData.length !== 0 ? updateValue(batchData) : updateValue(data);
      let status = (batchData.length != 0) ? setData(batchData) : null;
      setBatchData([]);

    }
  }, [editingSheet]);


  return (
    <>
      {errors !== undefined && errors.length !== 0 && <ErrorPopup
        title={t("VALIDATION_ERROR") + ("!")}
        body={(errors)}
        onHide={() => setErrors([])}
      ></ErrorPopup>}
      {loading && <div className="d-flex justify-content-center align-items-center" id="monthly-overview-loading-icon">
        <div className="spinner-border custom-loading-icon"></div>
      </div>}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {popupOpen && <AddLeavePopup buttonName={t("CANCEL")} setAddLeave={setPopupOpen} addLeave={popupOpen} setDataRefresh={setDataRefresh} dataRefresh={dataRefresh} updateLeaveData={leaveData}></AddLeavePopup>}
      <Spreadsheet
        className="col-md-12 p-0 reporting"
        data={data}
        columnLabels={employeeData.columnLabels}
        rowLabels={employeeData.rowLables}
        hideRowIndicators
        onChange={setSpreadSheetData}
        onSelect={(selected) => {
          setIndex(selected?.range?.start?.row);
          // updateValue(selected);
          openAddLeavePopup(selected)
        }
        }
        onKeyDown={keyDown}
      />
    </>




  );
};
