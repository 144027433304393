import react from 'react';

const RolesIcon = () => {
    return (
        <svg id="RolesIcon" width="52" height="52" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <g fill="none" stroke="#073763" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"> {/* Increased strokeWidth to 4 */}
                <path d="M9.15 35.582V25.76h33.7v9.822M26 21.304v14.18" fill="#ffffff" />
                <path d="M50 49.686H35.7c0-3.95 3.2-7.15 7.15-7.15s7.15 3.2 7.15 7.15zM42.85 42.536c-1.734 0-3.142-2.079-3.142-3.812a3.144 3.144 0 013.142-3.142 3.144 3.144 0 013.141 3.142c0 1.733-1.408 3.812-3.141 3.812z" fill="#ffffff" />
                <path d="M31.826 46.974H20.174a5.825 5.825 0 1 1 11.652 0z" fill="#ffffff" />
                <path d="M26 41.149c-1.412 0-2.56-1.693-2.56-3.106 0-1.412 1.148-2.56 2.56-2.56s2.56 1.148 2.56 2.56c0 1.413-1.148 3.106-2.56 3.106z" fill="#ffffff" />
                <path d="M16.3 49.686H2c0-3.95 3.2-7.15 7.15-7.15s7.15 3.2 7.15 7.15zM9.15 42.536c-1.733 0-3.141-2.079-3.141-3.812 0-1.733 1.408-3.142 3.141-3.142s3.142 1.409 3.142 3.142c0 1.733-1.408 3.812-3.142 3.812z" fill="#ffffff" />
                <circle cx="26" cy="11.809" r="9.495" />
                <path d="M19.494 18.706c1.5-1.951 3.853-3.216 6.51-3.216s5.028 1.265 6.499 3.235" />
                <circle cx="25.998" cy="9.173" r="2.636" />
            </g>
        </svg>
    )
}

export default RolesIcon;