import React, { useState, useEffect } from "react";
import { ValidatePasswordResetTokenApiUrl, UpdatePasswordApiUrl } from "../../routes/ApiEndPoints";
import { toast, ToastContainer } from 'react-toastify';
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../atoms/CustomButton";
import PasswordInput from "../../components/atoms/formFields/PasswordInput";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import Logo from "../../static/icons/Logo.png"
import FormsNew from "../molecules/FormsNew";
import { getFormattedDropdownOptions } from "../../utilities/CommonFunctions";
import { t } from "../../translations/Translation";

export default function ResetForgotPassword() {

    const navigate = useNavigate();
    const params = useParams();

    // const [showForm, setShowForm] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [validationError, setValidationError] = useState("");
    const [messageColour, setColour] = useState("");
    const [validToken, setValidToken] = useState({
        "token": params.validtoken
    })

    // Function to validate strong password
    function validatePasswordStrength(password) {
        // At least 8 characters, including uppercase, lowercase, digit, and special character
        const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#?$%^&*]).{8,}$/;
        return strongRegex.test(password);
    }

    useEffect(() => {
        if (newPassword === '') {
            setValidationError(t("PASSWORD_GUIDELINES") + (": @$! % * ? &"));
            setColour("default");
        } else if (validatePasswordStrength(newPassword)) {
            setValidationError(t("STRONG_PASSWORD"));
            setColour("pass");
        } else {
            setValidationError(t("WEAK_PASSWORD"));
            setColour("fail");
        }

        if (confirmPassword !== newPassword && confirmPassword !== "") {

            setValidationError(t("PASSWORD_NOT_MATCHING"));
            setColour("fail");
        }
    }, [newPassword, confirmPassword]);


    const validateToken = () => {

        AXIOS.service(ValidatePasswordResetTokenApiUrl, 'POST', validToken)
            .then((result) => {
                if (result?.success) {

                    setShowForm(true)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    useEffect(() => {

        validateToken();

    }, [showForm])

    const sendRequest = () => {
        let data = {
            'password': newPassword,
            'password_confirmation': confirmPassword,
            'token': params.validtoken
        }

        AXIOS.service(UpdatePasswordApiUrl, 'POST', data)
            .then((result) => {
                if (result.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    navigate('/');
                    window.location.reload();
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <nav className="navbar navbar-expand-sm bg-white navbar-light px-4 mx-auto shadow-sm border-bottom py-3 justify-content-between">
                <div className="d-flex col-xl-3 col-lg-4">
                    <div className=" align-items-center">
                        <a className="navbar-brand p-0" href="/"><img alt={t("LOGO")} className="logo" src={Logo}></img></a>
                    </div>
                </div>
            </nav>
            {showForm && <><div className="col-md-8 mx-auto mt-5"><h2 className="text-center" id="text-indii-blue">{t("RESET_PASSWORD")}</h2></div>
                <div className="col-md-8 mx-auto mt-5 h-50 pt-5">
                    {validationError && <div className="mb-3 text-center" >
                        <span id={messageColour === "default" ? "text-indii-blue" : ""} className={messageColour === "fail" ? "text-red font-weight-bolder" : (messageColour === "pass" ? "text-green font-weight-bolder" : "")}>{validationError}</span>
                    </div>}
                    <br></br>
                    <PasswordInput title={t("NEW_PASSWORD")} name={"password"} setValue={setNewPassword} placeholder={""} CustomStyle={"col-md-8 mx-auto"} required={true} type="text"></PasswordInput>
                    <br></br>
                    <PasswordInput title={t("CONFIRM_PASSWORD")} name={"password_confirmation"} setValue={setConfirmPassword} placeholder={""} CustomStyle={"col-md-8 mx-auto"} required={true} type="password" ></PasswordInput>
                    <br></br>
                    <div className="col-md-8 mx-auto">
                        <CustomButton buttonName={t("SAVE")} ActionFunction={() => sendRequest()} CustomStyle={"col-md-12 mx-auto"}></CustomButton>
                    </div>
                </div></>}
            {!showForm && <div className="company-tab-width full-page-height company_creation mt-2 mb-3 mx-auto border bg-white"> <h2 className="col-md-10 p-0 mt-4 ml-5 text-center text-danger " >{"Unauthorized"}</h2></div>}
        </>
    );
}