import React from 'react'

const Functions = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="92.438" height="81.791" viewBox="0 0 92.438 81.791">
        <g id="Group_27822" data-name="Group 27822" transform="translate(-12647.661 8800.75)">
          <path id="Employee" d="M3977.277-7659.249h-37.4v-5c0-6.129,2.656-10.818,7.478-13.2a15.262,15.262,0,0,0,11.222,4.879,15.269,15.269,0,0,0,11.221-4.879c4.822,2.389,7.477,7.074,7.477,13.2v5Zm-18.58-18.464a11.782,11.782,0,0,1-11.884-11.646A11.775,11.775,0,0,1,3958.7-7701a11.775,11.775,0,0,1,11.883,11.641A11.781,11.781,0,0,1,3958.7-7677.713Z" transform="translate(8735.743 -1099.751)" fill="#073763" />
          <path id="Settings_Grey_" data-name="Settings (Grey)" d="M29.835,19.918a9.344,9.344,0,0,0,.077-1.5c0-.528-.077-.977-.077-1.5l3.23-2.481a.69.69,0,0,0,.154-.977L30.143,8.263a.75.75,0,0,0-.923-.3l-3.846,1.5a11.214,11.214,0,0,0-2.614-1.5l-.539-3.985a.825.825,0,0,0-.769-.6H15.3a.825.825,0,0,0-.769.6l-.615,3.985a13.026,13.026,0,0,0-2.615,1.5l-3.846-1.5a.72.72,0,0,0-.923.3L3.454,13.451a.906.906,0,0,0,.154.977L6.915,16.91c0,.526-.077.977-.077,1.5s.077.977.077,1.5L3.686,22.4a.69.69,0,0,0-.154.977l3.077,5.189a.75.75,0,0,0,.923.3l3.846-1.5a11.214,11.214,0,0,0,2.614,1.5l.615,3.985a.745.745,0,0,0,.769.6h6.152a.825.825,0,0,0,.769-.6l.616-3.985a13.019,13.019,0,0,0,2.614-1.5l3.846,1.5a.72.72,0,0,0,.923-.3l3.077-5.189a.9.9,0,0,0-.154-.977l-3.384-2.481ZM18.376,23.678a5.265,5.265,0,1,1,5.384-5.264A5.292,5.292,0,0,1,18.376,23.678Z" transform="translate(12706.646 -8753.881)" fill="#073763" />
          <path id="Subtraction_189" data-name="Subtraction 189" d="M23.691,30.083H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H23.691a2,2,0,0,1,2,2V28.084A2,2,0,0,1,23.691,30.083ZM4.368,20.808v1.5h12.2v-1.5Zm0-5.014v1.5H21.324v-1.5Zm0-5.014v1.5H21.324v-1.5Zm0-5.014v1.5H21.324v-1.5Z" transform="translate(12647.661 -8749.042)" fill="#073763" />
          <g id="arrow-90deg-right" transform="translate(12647.159 -8779.875)">
            <g id="Group_27818" data-name="Group 27818" transform="translate(0 0)">
              <path id="Path_55763" data-name="Path 55763" d="M16.476,5.773a.595.595,0,0,0,0-.842L11.719.174a.6.6,0,0,0-.842.842l3.743,3.741H2.973A2.973,2.973,0,0,0,0,7.731V24.623a.595.595,0,0,0,1.189,0V7.731A1.784,1.784,0,0,1,2.973,5.947H14.619L10.877,9.688a.6.6,0,0,0,.842.842Z" transform="translate(7 -2)" fill="#073763" fill-rule="evenodd" />
            </g>
          </g>
          <g id="arrow-down" transform="translate(12702.219 -8750.242) rotate(90)">
            <path id="Path_55764" data-name="Path 55764" d="M14.786-3.5a.607.607,0,0,1,.607.608l-.009,22.114L20.014,14.6a.607.607,0,1,1,.858.86L15.2,21.117a.607.607,0,0,1-.858,0L8.678,15.459a.607.607,0,1,1,.858-.86l4.633,4.624.009-22.117A.607.607,0,0,1,14.786-3.5Z" transform="translate(0 0)" fill="#073763" fill-rule="evenodd" />
          </g>
          <g id="arrow-90deg-right-2" data-name="arrow-90deg-right" transform="matrix(-0.017, 1, -1, -0.017, 12732.492, -8782.16)">
            <g id="Group_27818-2" data-name="Group 27818" transform="translate(3 -0.002)">
              <path id="Path_55763-2" data-name="Path 55763" d="M23.924,5.491a.575.575,0,0,0,0-.814l-4.6-4.6a.575.575,0,1,0-.814.814L22.13,4.509,5.874,4.6A2.874,2.874,0,0,0,3,7.47v9.2a.575.575,0,0,0,1.149,0V7.47A1.724,1.724,0,0,1,5.874,5.746L22.13,5.659,18.512,9.275a.575.575,0,0,0,.814.814Z" transform="translate(-3 0.002)" fill="#073763" fill-rule="evenodd" />
            </g>
          </g>
        </g>
      </svg>

    </>
  )
}

export default Functions