import React from 'react'

const DimonaWhite = ({ color }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="84.176" height="87.661" viewBox="0 0 84.176 87.661">
        <path id="Dimona" d="M16757,11686.979a40.71,40.71,0,0,1,53.662-38.575,17.636,17.636,0,0,0,16.807,22.915,17.48,17.48,0,0,0,7.121-1.5,40.691,40.691,0,1,1-77.59,17.16Zm37.563-25.042v28.172a3.13,3.13,0,0,0,3.129,3.125h18.773a3.127,3.127,0,1,0,0-6.255h-15.645v-25.042a3.129,3.129,0,0,0-6.258,0Zm23.217,1.454a13.7,13.7,0,1,1,9.689,4.014A13.623,13.623,0,0,1,16817.777,11663.391Zm2.078-11.245a.959.959,0,0,0-.436.6.987.987,0,0,0,.119.739l3.443,5.531.537.859,12.74-8.7a.97.97,0,0,0,.26-1.352.987.987,0,0,0-.811-.427.944.944,0,0,0-.543.168l-11.072,7.558-2.9-4.67a.963.963,0,0,0-.828-.456A.933.933,0,0,0,16819.855,11652.146Z" transform="translate(-16756.998 -11640)" fill={color} />
      </svg>

    </>
  )
}

export default DimonaWhite