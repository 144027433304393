import React, { useEffect, useState } from "react";
import CustomButton from "../atoms/CustomButton";
import UpdateEmployeeContractDetailsForm from "./UpdateEmployeeContractDetailsForm";
import { t } from "../../translations/Translation";
import EmployeeDocumentsOverview from "./EmployeeDocumentsOverview";
import { EmployeeBenefitsApiUrl, EmployeeApiUrl, EmployeeCommutetApiUrl, AddCommuteDetailsApiUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import LoadingIcon from "../../utilities/LoadingIcon";
import CustomTable from "../atoms/CustomTable";
import Dropdown from "../atoms/Dropdown";
import { ToastContainer, toast } from "react-toastify";

export default function EmployeeUpdate({ tab, edit, setEditStatus, dataLeft, dataRight, eid }) {

    const [commuteData, setCommuteData] = useState([]);
    const [locationsList, setLocationsList] = useState({});
    const [commuteTypeList, setCommuteTypeList] = useState({});
    const [refresh, setRefresh] = useState(false);

    const commute_table_headers = [
        {
            title: t("LOCATION"),
            field: 'location_id',
            size: 200,
            lookup: locationsList
        },
        {
            title: t('DISTANCE_IN_KMS'),
            field: 'distance',
            size: 200,

        },
        {
            title: t('COMMUTE'),
            field: 'commute_type_id',
            size: 200,
            lookup: commuteTypeList,
        },

    ]

    useEffect(() => {

        if (tab === 'tab2') {

            AXIOS.service(EmployeeCommutetApiUrl + '/create', 'GET')
                .then((result) => {
                    if (result?.success) {
                        setLocationsList(convertArrayToObject(result.data.locations, 'id', 'location_name'))
                        setCommuteTypeList(convertArrayToObject(result.data.commute_types, 'id', 'name'))
                    } else {
                        // setErrors(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

            AXIOS.service(EmployeeCommutetApiUrl + '/' + eid, 'GET')
                .then((result) => {
                    if (result?.success) {
                        setCommuteData(result.data)
                    } else {
                        // setErrors(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }, [refresh])


    const setValue = (value, name, index) => {
        if (name === 'location') {
            let newData = [...commuteData]
            newData[index].location = value;
            setCommuteData(newData)
        }

    }


    const convertArrayToObject = (array, key, value) => {
        const result = {};
        array.forEach(item => {
            result[item[key]] = item[value];
        });
        return result;
    };

    const UpdateRow = (newData, index) => {
        AXIOS.service(EmployeeCommutetApiUrl + '/' + eid, 'PUT', newData)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
        setRefresh(!refresh)
    }

    const deleteRow = (data) => {

        AXIOS.service(EmployeeCommutetApiUrl + '/' + data.id, 'DELETE')
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
        setRefresh(!refresh)
    }

    const CreateRow = (newData, index) => {
        AXIOS.service(AddCommuteDetailsApiUrl + '/' + eid, 'POST', newData)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
        setRefresh(!refresh)
    }


    return (
        <div className="row m-0">
            <div className="col-md-6 font-details ">
                {tab !== 'tab4' && dataLeft.map((val, index) => {
                    return (
                        <div key={val.label} className={"font-weight-bold col-md-12 row m-0 mb-1 mt-3"}>
                            <label className="col-md-4 mb-1 pr-0 text-secondary">{val.label}:</label>
                            {edit && <input type="text" className="col-md-8 mb-3 form-control font-weight-bold pt-0" name={val.label} value={val.value} />}
                            {!edit && <p className="mb-0 col-md-8 text-truncate">{val.value}</p>}
                        </div>
                    )
                })}
                {/* {tab === "tab2" && <UpdateEmployeeContractDetailsForm data={dataLeft} edit={edit} />} */}
            </div>
            <div className="col-md-6 font-details">
                {tab !== 'tab4' && dataRight.map((val, index) => {
                    return (
                        <div key={val.label} className={"font-weight-bold col-md-12 row m-0 mb-1 mt-3"}>
                            <label className="col-md-4 mb-1 pr-0 text-secondary">{val.label}:</label>
                            {edit && <input type="text" className="col-md-8 mb-3 form-control font-weight-bold pt-0" name={val.label} value={val.value} />}
                            {!edit && <p className="mb-0 col-md-8 text-truncate">{val.value}</p>}
                        </div>
                    )
                })}
            </div>
            {tab === 'tab4' && <EmployeeDocumentsOverview eid={eid}></EmployeeDocumentsOverview>}
            {edit && <div className="float-right col-md-12 text-right mb-3">
                <CustomButton buttonName={t("SAVE")} ActionFunction={() => setEditStatus(false)}></CustomButton>
                <CustomButton buttonName={t("CANCEL")} ActionFunction={() => setEditStatus(false)}></CustomButton>
            </div>}
            {tab === 'tab2' && <CustomTable tableName='commute_details' columns={commute_table_headers} rows={commuteData} title={t('COMMUTE_DETAILS')} setRows={setCommuteData} UpdateRow={UpdateRow} DeleteRow={deleteRow} CreateRow={CreateRow} onChage={setValue}></CustomTable>}
        </div>

    )
}
