import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import AddEmployeeContractTypes from "./AddEmployeeContractTypes";
import AddEmployeeFunctionSalaries from "./AddEmployeeFunctionSalaries";
import { EmployeeContractApiUrl, CheckEmployeeFuturePlansAvailabilityApiUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { t } from "../../translations/Translation";
import { toast } from 'react-toastify';
import ErrorPopup from "../../utilities/popup/ErrorPopup"

export default function AddContractPopup(props) {

    const [displaySubType, setDisplaySubType] = useState(false);
    const [selectedEmpTypeCategory, setSelectedEmpTypeCategory] = useState();
    const [tabIndex, setTabIndex] = useState(0);
    const [functionSalaries, setFunctionSalaries] = useState([{ 'function_id': '', 'salary': '', 'experience': '' }]);
    const [functions, setFunctions] = useState([]);
    const [checkedStates, setCheckedStates] = useState(Array(functionSalaries?.length).fill(false));
    const [errors, setErrors] = useState([])
    const [weeklyContractHours, setWeeklyContractHours] = useState()
    const [isInterimType, setIsInterimType] = useState(false)
    const [selectedInterimAgency, setSelectedInterimAgency] = useState("")
    const [employeeContractOptions, setEmployeeContractOptions] = useState([]);
    const [swap, setSwap] = useState(false)
    const [deletePlans, setDeletePlans] = useState(false)
    const [hasFuturePlans, setHasFuturePlans] = useState(false)

    useEffect(() => {
        AXIOS.service(EmployeeContractApiUrl + '/create', 'GET')
            .then((result) => {
                if (result?.success) {
                    setEmployeeContractOptions(result.data)
                } else {
                    // setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    // Tabs data array for super admin
    const TabsData = [
        { tabHeading: t("EMPLOYEE_CONTRACT_TYPE"), tabName: 'employee_contract_type' },
        { tabHeading: t("FUNCTION_SALARIES"), tabName: 'function_salaries' },
    ]

    const [employeeContracts, setEmployeeContracts] = useState(
        {
            "employee_type_id": '',
            "sub_type": "",
            "schedule_type": "",
            "employment_type": "",
            "start_date": "",
            "end_date": "",
            "weekly_contract_hours": "",
            "work_days_per_week": '',
            'send_dimona': false
        }
    )

    const onConfirm = () => {

        let url = EmployeeContractApiUrl
        let data = {
            "employee_profile_id": props.eid,
            "employee_contract_details": employeeContracts,
            "employee_function_details": functionSalaries,
            "interim_contract": isInterimType,
            "future_plannings":""
        }
        if(hasFuturePlans) {
            data.future_plannings = swap?"swap":deletePlans?"cancel":""
        } 
        AXIOS.service(url, "POST", data)
            .then((result) => {
                if (result?.success) {
                    props.setDataRefresh(!props.dataRefresh)
                    props.setOpenPopup(false)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    return (
        <Modal
            show={true}
            onHide={props.onHide}
            size="xl"
            className=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('CREATE_CONTRACT')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="popup-tabs border">
                    {errors !== undefined && errors.length !== 0 && <ErrorPopup
                        title={t("VALIDATION_ERROR") + ("!")}
                        body={(errors)}
                        onHide={() => setErrors([])}
                    ></ErrorPopup>}
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => { setTabIndex(index); }}>
                        <TabList>
                            {TabsData.map((val) => {
                                return (
                                    <Tab key={val.tabName} >{val.tabHeading}</Tab>
                                )
                            })}
                        </TabList>
                        <TabPanel>
                            <div className="">
                                <AddEmployeeContractTypes
                                    setEmployeeContracts={setEmployeeContracts} employeeContracts={employeeContracts}
                                    displaySubType={displaySubType} setDisplaySubType={setDisplaySubType}
                                    selectedEmpTypeCategory={selectedEmpTypeCategory} setSelectedEmpTypeCategory={setSelectedEmpTypeCategory}
                                    tabIndex={tabIndex} options={employeeContractOptions}
                                    weeklyContractHours={weeklyContractHours} setWeeklyContractHours={setWeeklyContractHours}
                                    isInterimType={isInterimType} setIsInterimType={setIsInterimType}
                                    selectedInterimAgency={selectedInterimAgency} setSelectedInterimAgency={setSelectedInterimAgency}
                                    existingEmployee={true} setSwap={setSwap} swap={swap} setDeletePlans={setDeletePlans} deletePlans={deletePlans}
                                    hasFuturePlans={hasFuturePlans} setHasFuturePlans={setHasFuturePlans}
                                    eid={props.eid}
                                ></AddEmployeeContractTypes>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="">
                                <AddEmployeeFunctionSalaries
                                    selectedEmpTypeCategory={selectedEmpTypeCategory}
                                    tabIndex={2}
                                    functionSalaries={functionSalaries} setFunctionSalaries={setFunctionSalaries}
                                    functions={functions} setFunctions={setFunctions}
                                    options={employeeContractOptions}
                                    employeeContracts={employeeContracts}
                                    checkedStates={checkedStates}
                                    setCheckedStates={setCheckedStates}
                                    eid={props.eid}
                                ></AddEmployeeFunctionSalaries>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {tabIndex === 1 && <Button className='button-style float-left' onClick={() => onConfirm()}>
                    {t("SAVE")}
                </Button>}
                {tabIndex === 0 && <Button className='button-style float-left' onClick={() => setTabIndex(1)}>
                    {t("NEXT_LINK")}
                </Button>}
                <Button className='button-style' onClick={props.onHide}>
                    {props.buttonName ? (props.buttonName) : t("CANCEL")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
