export default function SelectEdit({ cell }) {
  const handleChange = e => {
    cell.onChange({ ...cell, value: e.target.value });
  };
  return (
    <select className="form-select"
      disabled={cell.plan_id ? false : true}
      autoFocus
      style={{ width: "100%" }}
      onChange={handleChange}
      defaultValue={getValue(cell) || ""}
    >
      {(cell.sheetType === 'grote_flex_full_time' || cell.sheetType === 'grote_flex_part_time') && <option value="plusuren" selected={cell.value === "plusuren"} >Plusuren</option>}
      {(cell.sheetType !== 'bruto_netto_part_time' && cell.sheetType !== 'grote_flex_part_time') && <option value="bruto_netto" selected={true}>Brutto/Netto</option>}
      {cell.sheetType === 'bruto_netto_part_time' && <option value="overtime" selected={true}>Overtime 100</option>}
    </select>
  );
};

// Define a getValue function
const getValue = (cell) => {
  // Check if the cell object exists and has a value property
  if (cell && typeof cell === 'object' && 'value' in cell) {
    return cell.value; // Return the value of the cell
  }
  return null; // Return null if the cell object is invalid or doesn't have a value property
};
