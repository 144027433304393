import React, { useState, useEffect } from "react";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import Table from "../atoms/Table";
import CustomButton from "../atoms/CustomButton";
import AddOpenShift from "../molecules/AddOpenShift";
import { OpenShiftApiUrl } from "../../routes/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import Switch from "../atoms/Switch";
import { toast } from 'react-toastify';
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import { t } from "../../translations/Translation";
import ModalPopup from "../../utilities/popup/Popup";



export default function OpenShiftOverview({ setHeaderCompanyDropdown }) {

    const navigate = useNavigate();
    const [popupOpen, setOpenPopup] = useState(false);
    const [draftedShiftList, setDraftedShiftsList] = useState([]);
    const [activeShiftList, setActiveShiftList] = useState([]);
    const [deletedShiftList, setDeletedShiftList] = useState([])
    const [showDrafts, setShowDrafts] = useState(false)
    const [refresh, setRefresh] = useState(false);
    const [shiftId, setShiftId] = useState("");
    const [headers, setHeaders] = useState([]);
    const [errors, setErrors] = useState([]);
    const [deleteUrl, setDeleteUrl] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [createData, setCreateData] = useState([])
    const [templateId, setTemplateId] = useState('');

    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];


    const draftHeaders = [
        {
            title: t("TITLE_TEXT"),
            field: "name",
            status: 200
        },
        {
            title: t("VACANCY"),
            field: "count",
            status: "200",
        },
        {
            title: t("FUNCTION_NAME"),
            field: "function_name",
            status: "200",
        },
    ]

    const activeHeaders = [
        {
            title: t("SHIFT_NAME"),
            field: "name",
            status: 200
        },
        {
            title: t("START_DATE"),
            field: "date",
            status: "200",
        },
        {
            title: t("FUNCTION_NAME"),
            field: "function_name",
            status: "200",
        },
        {
            title: t("LOCATION"),
            field: "location_name",
            status: "200",
        },
        {
            title: t("APPLIED"),
            field: "applied",
            status: "200",
        },
        {
            title: t("FILLED_POSITIONS"),
            field: "filled_position",
            status: "200",
        },
    ]

    useEffect(() => {
        if (popupOpen) {
            AXIOS.service(OpenShiftApiUrl + '/create', 'GET')
                .then((result) => {
                    if (result?.success) {
                        if (result.data.length !== 0) {
                            setCreateData(result.data)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [popupOpen])

    useEffect(() => {
        //to get all vacancies and seggrigating
        AXIOS.service(OpenShiftApiUrl, "GET")
            .then((result) => {
                if (result?.success) {
                    let drafts = []
                    let active = []
                    result.data.open_shifts.map((item, i) => {
                        item.filled_position = item.approved_count + "/" + item.count
                        item.applied = item.applied_count
                        active.push(item)
                    })
                    result.data.templates.map((item, i) => {
                        drafts.push(item)
                    })
                    setDraftedShiftsList(drafts)
                    setActiveShiftList(active)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [refresh])


    const deleteShift = () => {

        AXIOS.service(deleteUrl, 'DELETE',)
            .then((result) => {
                if (result?.success) {
                    setRefresh(!refresh)
                    toast.success(result?.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setWarningMessage('')
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    // Function for onclick actions in the overview tables
    const viewAction = (data, action) => {
        if (action === 'edit') {
            setShiftId(data.id)
            setOpenPopup(true)
        } else if (action === 'view') {
            setShiftId(data.id)
            navigate('/manage-plannings/open-shift-view/' + data.id)
        } else if (action === 'use_template') {
            setTemplateId(data.id)
            setOpenPopup(true)
        } else {
            setWarningMessage(t("DELETE_CONFIRMATION_COMPANY") + ('?'))
            setDeleteUrl(OpenShiftApiUrl + "/" + data.id)
        }

    }

    const OnHides = () => {
        setRefresh(!refresh)
        setShiftId("");
        setOpenPopup(false)
    }

    const handleCreateShift = () => {
        setOpenPopup(true);
        setShiftId("");
        setTemplateId("")
    }

    return (
        <div className="mt-1 h-100">
            {popupOpen && <AddOpenShift
                onHide={() => OnHides()}
                shiftId={shiftId}
                createData={createData}
                showDrafts={showDrafts}
                templateId={templateId}
                popupOpen={popupOpen}
            ></AddOpenShift>}
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ('!')}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {warningMessage && <ModalPopup
                title={t("WARNING_TITLE")}
                body={(warningMessage)}
                onConfirm={deleteShift}
                onHide={() => setWarningMessage('')}
                close
            ></ModalPopup>}
            {!popupOpen && <div className="col-md-12 p-0 bg-white">
                <div className="d-flex align-items-center">
                    <div className="col-md-10">
                        <Switch label={t("TEMPLATES")} id="switch4" styleClass="px-3" lableClick={true} onChange={() => setShowDrafts(!showDrafts)} checked={showDrafts} />
                    </div>
                    <div className="col-md-2 text-right my-2">
                        {UserPermissions?.includes('modify_open_shifts') && <CustomButton buttonName={t("CREATE_SHIFT")} ActionFunction={() => handleCreateShift()} CustomStyle="add_btn"></CustomButton>}
                    </div>
                </div>
                {!showDrafts && <Table columns={activeHeaders} rows={activeShiftList} tableName={"open_shifts_overview"} viewAction={viewAction} permission={UserPermissions?.includes('modify_open_shifts')} viewPermission={true}></Table>}
                {showDrafts && <Table columns={draftHeaders} rows={draftedShiftList} tableName={"open_shifts_templates"} viewAction={viewAction} permission={UserPermissions?.includes('modify_open_shifts')} viewPermission={true}></Table>}
            </div>}
        </div>
    )
}