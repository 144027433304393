import React, { useEffect, useState } from "react";
import FormsNew from "../molecules/FormsNew";
import { t } from "../../translations/Translation";
import Table from "../atoms/Table";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetEmployeeListApiurl } from "../../routes/ApiEndPoints";

export default function PlanningLogs() {


    const [formData, setFormData] = useState({
        "from_date": "",
        "to_date": "currentDate",
        "employees": []
    })

    const [employeesList, setEmployeesList] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [logs, setLogs] = useState([]);

    const tableHeaders = [
        {
            title: t("UPDATED_ON"),
            field: "updated_on",
            status: "200",
        },
        {
            title: t("UPDATED_PLAN_DETAILS"),
            field: "details",
            status: "200",
        },
        {
            title: t("EMPLOYEE_NAME"),
            field: "name",
            status: "200",
        },
    ]

    useEffect(() => {
        AXIOS.service(GetEmployeeListApiurl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setEmployeesList(result?.data);
                }
            })

    }, [])


    // useEffect(()=>{
    //     AXIOS.service("", 'POST', formData)
    //     .then((result)=>{
    //         if (result?.success) {
    //            setLogs(result?.data)
    //         }
    //     })
    // },[formData])

    const setValues = (index, name, value, field) => {
        let newData = { ...formData }
        if (field !== 'dropdown') {
            newData[name] = value
        } else {
            setSelectedEmployees(value)
            let arr = []
            value.map((item) => {
                arr.push(item.value)
                return null
            })
            newData[name] = arr;
        }
        setFormData(newData);
    }

    const filterData = [
        { title: t("EMPLOYEE_NAME"), name: 'employees', required: false, options: employeesList, selectedOptions: selectedEmployees, isMulti: true, type: 'dropdown', style: "col-md-3 float-left" },
        { title: t("FROM_DATE"), name: 'from_date', required: false, type: 'date', style: "col-md-3 float-left" },
        { title: t("TO_DATE"), name: 'to_date', required: false, type: 'date', style: "col-md-3 float-left" },
    ]

    return (
        <div className="planning-body">
            <div className="bg-white mt-1">
                <FormsNew
                    view="filters"
                    formTitle={''}
                    formattedData={formData}
                    data={filterData}
                    SetValues={setValues}
                ></FormsNew>
            </div>
            <div className="bg-white mt-1">
                <Table columns={tableHeaders} rows={logs} tableName={"planning_logs"}></Table>
            </div>
        </div>
    );
}