import React from 'react'

const GroupFunctions = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="95.71" height="75.607" viewBox="0 0 95.71 75.607">
        <g id="Group_27823" data-name="Group 27823" transform="translate(-12647.661 8794.565)">
          <path id="Settings_Grey_" data-name="Settings (Grey)" d="M29.835,19.918a9.344,9.344,0,0,0,.077-1.5c0-.528-.077-.977-.077-1.5l3.23-2.481a.69.69,0,0,0,.154-.977L30.143,8.263a.75.75,0,0,0-.923-.3l-3.846,1.5a11.214,11.214,0,0,0-2.614-1.5l-.539-3.985a.825.825,0,0,0-.769-.6H15.3a.825.825,0,0,0-.769.6l-.615,3.985a13.026,13.026,0,0,0-2.615,1.5l-3.846-1.5a.72.72,0,0,0-.923.3L3.454,13.451a.906.906,0,0,0,.154.977L6.915,16.91c0,.526-.077.977-.077,1.5s.077.977.077,1.5L3.686,22.4a.69.69,0,0,0-.154.977l3.077,5.189a.75.75,0,0,0,.923.3l3.846-1.5a11.214,11.214,0,0,0,2.614,1.5l.615,3.985a.745.745,0,0,0,.769.6h6.152a.825.825,0,0,0,.769-.6l.616-3.985a13.019,13.019,0,0,0,2.614-1.5l3.846,1.5a.72.72,0,0,0,.923-.3l3.077-5.189a.9.9,0,0,0-.154-.977l-3.384-2.481ZM18.376,23.678a5.265,5.265,0,1,1,5.384-5.264A5.292,5.292,0,0,1,18.376,23.678Z" transform="translate(12709.918 -8753.881)" fill="#073763" />
          <path id="Subtraction_189" data-name="Subtraction 189" d="M23.691,30.083H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H23.691a2,2,0,0,1,2,2V28.084A2,2,0,0,1,23.691,30.083ZM4.368,20.808v1.5h12.2v-1.5Zm0-5.014v1.5H21.324v-1.5Zm0-5.014v1.5H21.324v-1.5Zm0-5.014v1.5H21.324v-1.5Z" transform="translate(12647.661 -8749.042)" fill="#073763" />
          <g id="arrow-90deg-right" transform="matrix(-0.017, 1, -1, -0.017, 12735.405, -8780.16)">
            <g id="Group_27818" data-name="Group 27818" transform="translate(3 -0.002)">
              <path id="Path_55763" data-name="Path 55763" d="M23.924,5.491a.575.575,0,0,0,0-.814l-4.6-4.6a.575.575,0,1,0-.814.814L22.13,4.509,5.874,4.6A2.874,2.874,0,0,0,3,7.47l-.1,3.2a.575.575,0,0,0,1.149,0l.1-3.2A1.724,1.724,0,0,1,5.874,5.746L22.13,5.659,18.512,9.275a.575.575,0,0,0,.814.814Z" transform="translate(-3 0.002)" fill="#073763" fill-rule="evenodd" />
            </g>
          </g>
          <g id="arrow-90deg-right-2" data-name="arrow-90deg-right" transform="translate(12647.159 -8779.875)">
            <g id="Group_27818-2" data-name="Group 27818" transform="translate(0 0)">
              <path id="Path_55763-2" data-name="Path 55763" d="M11.476,5.773a.595.595,0,0,0,0-.842L6.719.174a.6.6,0,0,0-.842.842L9.619,4.758H2.973A2.973,2.973,0,0,0,0,7.731V24.623a.595.595,0,0,0,1.189,0V7.731A1.784,1.784,0,0,1,2.973,5.947H9.619L5.877,9.688a.6.6,0,0,0,.842.842Z" transform="translate(7 -2)" fill="#073763" fill-rule="evenodd" />
            </g>
          </g>
          <g id="arrow-down" transform="translate(12702.219 -8750.242) rotate(90)">
            <path id="Path_55764" data-name="Path 55764" d="M14.786-3.5a.607.607,0,0,1,.607.608l-.009,22.114L20.014,14.6a.607.607,0,1,1,.858.86L15.2,21.117a.607.607,0,0,1-.858,0L8.678,15.459a.607.607,0,1,1,.858-.86l4.633,4.624.009-22.117A.607.607,0,0,1,14.786-3.5Z" transform="translate(0 0)" fill="#073763" fill-rule="evenodd" />
          </g>
          <g id="Group_27821" data-name="Group 27821" transform="translate(12446.533 -9064.88)">
            <path id="Employee" d="M17.683,19.742H0V17.379a6.533,6.533,0,0,1,3.536-6.243,7.255,7.255,0,0,0,10.612,0,6.534,6.534,0,0,1,3.535,6.243v2.363ZM8.9,11.011A5.571,5.571,0,0,1,3.279,5.5,5.568,5.568,0,0,1,8.9,0a5.567,5.567,0,0,1,5.619,5.5A5.571,5.571,0,0,1,8.9,11.011Z" transform="translate(239.001 278.373)" fill="#073763" />
            <path id="Employee-2" data-name="Employee" d="M17.683,19.742H0V17.379a6.533,6.533,0,0,1,3.536-6.243,7.255,7.255,0,0,0,10.612,0,6.534,6.534,0,0,1,3.535,6.243v2.363ZM8.9,11.011A5.571,5.571,0,0,1,3.279,5.5,5.568,5.568,0,0,1,8.9,0a5.567,5.567,0,0,1,5.619,5.5A5.571,5.571,0,0,1,8.9,11.011Z" transform="translate(256.684 270.314)" fill="#073763" />
            <path id="Employee-3" data-name="Employee" d="M17.683,19.742H0V17.379a6.533,6.533,0,0,1,3.536-6.243,7.255,7.255,0,0,0,10.612,0,6.534,6.534,0,0,1,3.535,6.243v2.363ZM8.9,11.011A5.571,5.571,0,0,1,3.279,5.5,5.568,5.568,0,0,1,8.9,0a5.567,5.567,0,0,1,5.619,5.5A5.571,5.571,0,0,1,8.9,11.011Z" transform="translate(222.443 270.315)" fill="#073763" />
          </g>
        </g>
      </svg>
    </>
  )
}

export default GroupFunctions
