import { useEffect, useState } from 'react';
import { pdfjs, Document, Page, Outline } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ZoomInIcon from "../../static/icons/ZoomIn.png";
import ZoomOutIcon from "../../static/icons/ZoomOut.png"
import { t } from "../../translations/Translation"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


export default function PdfViewer({ file }) {


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(.3);
    const [height, setHeight] = useState(2480)

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
    function zoomIn() {
        setScale(prev => prev + 0.05)
        setHeight(prev => prev * 1.05)
    }

    function zoomOut() {
        setScale(prev => prev - 0.05)
        setHeight(prev => prev / 1.05)
    }
    function reset() {
        setScale(.3)
        setHeight(2480)
    }

    return (
        <>
            <div className='pdf_container'>
                <Document
                    className={"pdf_document"}
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                // renderMode="svg"
                >
                    <Page pageNumber={pageNumber}
                        className={'pdf_page shadow'}
                        height={2480}
                        scale={scale}
                        canvasBackground={""}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                    >
                    </Page>
                </Document>
            </div>
            <div className='page_controls shadow'>
                <button
                    className='arrowButtons shadow ml-2'
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                >
                    ‹
                </button>
                <span className='mx-2'>{(numPages ? pageNumber : '--')}/{numPages || '--'}</span>
                <button
                    className='arrowButtons shadow'
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                >
                    ›
                </button>
                <button
                    className='arrowButtons shadow'
                    type="button"
                    onClick={zoomIn}
                >
                    <img className='page-control-icons' src={ZoomInIcon}></img>
                </button>
                <button
                    className='arrowButtons shadow ml-3'
                    type="button"
                    onClick={zoomOut}
                >
                    <img className='page-control-icons' src={ZoomOutIcon}></img>
                </button>
                <button
                    className='arrowButtons shadow ml-3 mr-2'
                    type="button"
                    onClick={reset}
                >
                    {t("RESET")}
                </button>

            </div>
        </>
    );

}
