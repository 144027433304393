import React, { useEffect } from 'react';
import ErrorPopup from '../../utilities/popup/ErrorPopup';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { t } from '../../translations/Translation'
import FormsNew from './FormsNew';
import { RolesApiUrl } from '../../routes/ApiEndPoints';
import { APICALL as AXIOS } from '../../services/AxiosServices';
import { toast } from 'react-toastify';

export default function AddRole() {

    const navigate = useNavigate();
    const params = useParams();
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(true);
    const [formData, setFormData] = useState({
        "name": "",
    })

    useEffect(() => {
        if (params.id !== undefined && params.id !== '' && params.id !== null) {
            AXIOS.service(RolesApiUrl + "/" + params.id, 'GET')
                .then((result) => {
                    if (result?.success) {
                        setFormData({ name: result.data?.name })
                    } else {
                        setErrors(result.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }, [])

    const setValues = (index, name, value, field) => {
        setFormData(prev => ({ ...prev, name: value }))
    }

    const OnSave = () => {

        let url = RolesApiUrl
        let method = 'POST'

        if (params.id !== undefined) {
            url = RolesApiUrl + '/' + params.id
            method = 'PUT'
        }

        AXIOS.service(url, method, formData)
            .then((result) => {
                if (result?.success) {
                    navigate('/manage-configurations/roles');
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSuccess(true)
                } else {
                    setErrors(result.message);
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }


    const formFields = [
        { title: t("NAME_TEXT"), name: 'name', required: true, type: 'text', style: 'col-md-12 mt-4 float-left' },
    ]

    return (
        <div className="right-container add_public_holidays">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <FormsNew
                view="role"
                formTitle={t("ADD_ROLE")}
                redirectURL={'/manage-configurations/roles'}
                formattedData={formData}
                data={formFields}
                SetValues={setValues}
                OnSave={OnSave}
                setSuccess={setSuccess}
                Success={success}
            ></FormsNew>

        </div>
    )

}