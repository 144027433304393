import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../atoms/formFields/TextInput";
import TextArea from "../atoms/formFields/TextArea";
import CustomCheckBox from "../atoms/formFields/CustomCheckBox";
import CustomButton from "../atoms/CustomButton";
import Dropdown from "../atoms/Dropdown";
import BackIcon from "../../static/icons/BackIcon.png"
import TimeInput from "../atoms/TimeInput";
import { t } from "../../translations/Translation";

export default function Forms({ formTitle, redirectURL, changeCheckbox, checkboxList, field1, field2, field3, field4, field5, field6, field7, field8, field9, error1, error2, error3, error4, error7, SetValues, onSave, view, setSuccess, Success }) {

    const navigate = useNavigate();
    const params = useParams();

    // Forms for functions, employee type and sectors
    return (
        <div className={view !== 'sectors' ? "form-container mt-3 border bg-white d-flex flex-column" : "pt-5 pb-5"}>
            {view !== 'sectors' && <h2 id="text-indii-blue" className="p-4 mb-0 ml-2 d-flex align-items-center"><img className="shortcut-icon mr-2 pointer" onClick={() => navigate(redirectURL)} src={BackIcon}></img>{formTitle}</h2>}
            <div className="d-flex px-4 flex-column flex-1">
                <form className="col-md-12 px-0 flex-1">
                    {/* Text input field */}
                    <TextInput
                        title={field1.title}
                        name={field1.name}
                        placeholder={field1.placeholder}
                        CustomStyle={"col-md-6 float-left" + ((view === 'group_function' || view === 'employee_types' || view === 'contract_type') ? ' mt-4' : '')}
                        required={field1.required}
                        value={field1.value}
                        setValue={(e) => SetValues(e, 1)}
                        error={error1}
                        styleMargin={error1 ? '' : (view === 'group_function' ? '' : '')}
                    ></TextInput>

                    {view !== 'employee_types' && view !== 'group_function' && view !== 'contract_type' &&
                        <TextInput title={field2.title}
                            name={field2.name}
                            placeholder={field2.placeholder}
                            CustomStyle={"col-md-6 float-left"}
                            required={field2.required}
                            value={field2.value}
                            setValue={(e) => SetValues(e, 2)}
                            error={error2}
                            styleMargin={error2 ? '' : ''}
                        ></TextInput>
                    }
                    {<Dropdown
                        options={field3.options}
                        selectedOptions={field3.value}
                        onSelectFunction={(e) => SetValues(e, 3)}
                        CustomStyle="col-md-6 mt-4 float-left"
                        title={field3.title}
                        required={field3.required}
                        isMulti={field3.isMulti}
                        error={error3}
                        styleMargin={error2 ? '' : ''}
                    ></Dropdown>}

                    {view !== 'functions' && view !== 'contract_type' && <Dropdown
                        options={field4.options}
                        selectedOptions={field4.value}
                        onSelectFunction={(e) => SetValues(e, 4)}
                        CustomStyle="col-md-6 mt-4 float-left"
                        title={field4.title}
                        required={field4.required}
                        isMulti={field4.isMulti}
                        error={error4}
                    ></Dropdown>}

                    {view === 'employee_types' && <Dropdown
                        options={field7.options}
                        selectedOptions={field7.value}
                        onSelectFunction={(e) => SetValues(e, 7)}
                        CustomStyle="col-md-6 mt-4 float-left"
                        title={field7.title}
                        required={field7.required}
                        isMulti={field7.isMulti}
                        error={error7}
                    ></Dropdown>}
                    {view === 'sectors' &&
                        <TimeInput
                            setTime={(e) => SetValues(e, 'night_hour_start_time')}
                            value={field8.value}
                            type={field8.type}
                            title={field8.title}
                            required={field8.required}
                            customStyle="col-md-6 float-left mt-4"
                        ></TimeInput>
                    }
                    {view === 'sectors' &&
                        <TimeInput
                            setTime={(e) => SetValues(e, 'night_hour_end_time')}
                            value={field9.value}
                            type={field9.type}
                            title={field9.title}
                            required={field9.required}
                            customStyle="col-md-6 float-left mt-4"
                        ></TimeInput>
                    }

                    {/* Text area input field */}
                    <TextArea
                        title={field5.title}
                        name={field5.name}
                        required={field5.required}
                        CustomStyle={"col-md-12 mt-4 float-left"}
                        value={field5.value}
                        setValue={(e) => SetValues(e, 5)}
                    ></TextArea>

                    {/* Check boxes for status */}
                    <CustomCheckBox
                        title={field6.title}
                        checkboxList={checkboxList}
                        changeCheckbox={changeCheckbox}
                        required={field6.required}
                        CustomStyle={"col-md-12 my-4 float-left"}
                    ></CustomCheckBox>
                </form>
                <div className="my-3">
                    {view !== 'sectors' && <div className={"text-right " + (view === 'sectors' ? 'pb-5' : '')}>
                        {((view === 'sectors' && params.id !== undefined) || view !== 'sectors') && <CustomButton buttonName={t("SAVE")} ActionFunction={() => {onSave(); setSuccess(false);}} CustomStyle={Success !== false ? "" : "disabled"}></CustomButton>}
                        <CustomButton buttonName={t("BACK_LINK")} ActionFunction={() => navigate(redirectURL)}></CustomButton>
                    </div>}
                </div>
            </div>


        </div>
    )
}
