import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { DeleteInviteApiUrl, EmployeeInviteApiUrl, GetEmployeePreFillDataApiUrl, GetInvitedEmployeesApiUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { t } from "../../translations/Translation";
import { ToastContainer, toast } from 'react-toastify';
import Table from "../atoms/Table";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../static/icons/BackIcon.png"
import InviteEmployee from "../../static/icons/InviteEmployee";
import InviteEmployeePopup from "./InviteEmployeePopup";
import ModalPopup from "../../utilities/popup/Popup";



export default function InvitedEmployeeOverview(props) {

    const [error, setError] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [deleteUrl, setDeleteUrl] = useState('');
    const [dataRefresh, setDataRefresh] = useState(false);


    const navigate = useNavigate();
    const header = [
        {
            title: t("EMPLOYEE_NAME"),
            field: "name",
            status: "200",
        },
        {
            title: t("EMAIL"),
            field: "email",
            status: "200",
        },
        {
            title: t("INVITATION_STATUS"),
            field: "invitation_status",
            status: "200",
        }
    ]
    const [listData, setListData] = useState([]);
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];


    useEffect(() => {
        AXIOS.service(GetInvitedEmployeesApiUrl, "GET")
            .then((result) => {
                if (result?.success) {
                    setListData(result.data);
                } else {
                    setError(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [dataRefresh])


    const [selectedLanguage, setSelectedLanguage] = useState({ value: "nl", label: "NL" })
    //Language options
    const LanguageOptions = [
        { value: 'en', label: 'EN' },
        { value: 'nl', label: 'NL' },
        { value: 'fr', label: 'FR' },
    ]

    const DeleteApiCall = () => {

        AXIOS.service(deleteUrl, 'DELETE')
            .then((result) => {
                if (result?.success) {
                    setDataRefresh(!dataRefresh);
                    setWarningMessage('')
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const viewAction = (data, action) => {
        if (action === 'reject') {
            setWarningMessage(t("DELETE_CONFIRMATION") + ("?"))
            setDeleteUrl(DeleteInviteApiUrl + '/' + data.id)
        } else {
            // AXIOS.service(GetEmployeePreFillDataApiUrl + '/' + data.id, "GET")
            // .then((result) => {
            //     if (result?.success) {
            //         console.log(result.data);
            //     } else {
            //         setError(result.message)
            //     }
            // })
            // .catch((error) => {
            //     console.log(error);
            // })
            navigate('/add-employees/' + data.id);
        }
    }


    return (
        <div className="right-container">
            <div className="company-tab-width mt-3 border bg-white">
                {warningMessage && <ModalPopup
                    title={t("WARNING_TITLE")}
                    body={(warningMessage)}
                    onConfirm={DeleteApiCall}
                    onHide={() => setWarningMessage('')}
                ></ModalPopup>}
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                {openPopup && <InviteEmployeePopup onHide={() => setOpenPopup(false)} setOpenPopup={setOpenPopup} openPopup={openPopup}></InviteEmployeePopup>}

                {/* <h2 className="col-md-12 p-0 mt-4 text-left border-bottom pb-3" id="text-indii-blue">{t("INVITE_EMPLOYEE")}</h2> */}
                < div className="d-flex justify-content-between">
                    <h4 className="pt-2 mb-0 px-2 bg-white"><img className="shortcut-icon mr-2 mb-1" onClick={() => navigate('/manage-employees')} src={BackIcon} alt={t("BACK_ICON")}></img>
                        {t('INVITE_EMPLOYEE_OVERVIEW')}</h4>
                    <div className="px-3 my-auto" onClick={() => setOpenPopup(true)}><InviteEmployee color="#292929" /></div>
                </div>
                {error?.length !== 0 && error.map((err, i) => {
                    return (
                        <p className="text-danger mb-0 text-center">{err}</p>
                    )
                })}
                <Table columns={header} rows={listData} tableName={'invite_employee'} viewAction={viewAction} permission={UserPermissions?.includes('invite_employee')}></Table>
            </div>
        </div >
    )
}
