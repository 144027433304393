import React, { useState } from "react";
import FormsNew from "./FormsNew";
import CustomPopup from "../../utilities/popup/CustomPopup";
import { t } from "../../translations/Translation";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { toast } from "react-toastify";
import { ChangePasswordByAdminOrManagerApiUrl} from "../../routes/ApiEndPoints";

export default function ChangePasswordByAdmin({ setChangePassword, eid }) {

    const [formData, setFormData] = useState({
        "password": "",
        "password_confirmation": ""
    })
    const [error, setError] = useState([]);

    const formFields = [
        { title: "New Password", type: "text", name: "password", style: " col-12 mb-4" },
        { title: "Confirm Password", type: "text", name: "password_confirmation", style: "col-12" }
    ]

    const setValue = (index, name, value) => {
        let newData = { ...formData }
        newData[name] = value
        setFormData(newData)
    }

    const onConfirm = () => {
        let data ={
            'employee_id':eid,
            'password':formData.password,
            'password_confirmation': formData.password_confirmation,
        }
        AXIOS.service(ChangePasswordByAdminOrManagerApiUrl, 'POST', data)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setChangePassword(false)
                } else {
                    setError(result.message);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return (
        <CustomPopup
            size={'m'}
            title={t("CHANGE_PASSWORD")}
            close
            header
            footer
            onConfirm={onConfirm}
            saveButtonName={t("SAVE")}
            onHide={() => setChangePassword(false)}
            body={<>
                {error?.length !== 0 && error.map((err, i) => {
                    return (
                        <p className="text-danger mb-0 text-center">{err}</p>
                    )
                })}
                <FormsNew
                    data={formFields}
                    formattedData={formData}
                    SetValues={setValue}
                ></FormsNew>
                </>
            }
        >
        </CustomPopup>
    )
}