import React, { useState } from "react";
import "../../static/common.css";

export default function CustomButton({ buttonName, redirectURL, ActionFunction, CustomStyle, disabled, notification }) {

  return (
    <>
      {redirectURL && (<a className={`btn button-style mx-2 ${CustomStyle} ${notification ? 'with-notification' : ''}`} href={redirectURL ? redirectURL : ''} onClick={() => ActionFunction(buttonName)}>
        {buttonName}
        {notification >0 && <span className="notification-badge">{notification}</span>}
      </a>)}
      {redirectURL === undefined && (<a className={`btn button-style mx-2 ${CustomStyle} ${notification ? 'with-notification' : ''}`} onClick={() => { if (!disabled) { ActionFunction(buttonName) } }}>
        {buttonName}
        {notification >0 && <span className="notification-badge">{notification}</span>}
      </a>)}
    </>
  );
}

