import { FormulaConfiguration } from "./Formula";

export default class RowObjectBuilder {
  constructor(columnConfigurations, totalColumnConfigurations) {
    this.columnConfigurations = columnConfigurations;
    this.totalColumnConfigurations = totalColumnConfigurations;
    this.max_hours = 11 // call sync function get sector max hours
    this.val = '';
  }



  buildRowObject(employeeData, setIndex, sheetType, setSpreadSheetData) {
    let week_row_number = 0
    let week_index_array = []
    let week_rows = 0
    return employeeData.map((employee, index) => {
      if (employee?.type === 'week') {
        week_row_number = week_row_number + employee.plans_count + 1
        week_index_array.push(week_row_number)
        week_rows = employee.plans_count
      }
      const rowObject = [];
      Object.keys(employee?.type === 'date' ? this.columnConfigurations : this.totalColumnConfigurations).forEach((columnName) => {
        if (employee?.type === 'date') {
          const columnConfig = this.columnConfigurations[columnName];
          if (columnConfig) {
            const value = this.getColumnValue(columnConfig, employee);
            let readOnly = ['contract_hours', 'total_worked_hours', 'break_hours', 'night_hours'].includes(columnName) ? (employee.plan_id === null ? true : false) : true
            let styleClass = employee?.status === false && columnName === 'date' ? "bg-red" : (columnName === 'worked_hours' ? "worked-hour-col" : "")
            // let sheetValue;
            // if (sheetType === 'full_time') {
            //   sheetValue = employee[columnName] //FormulaConfiguration(employee[columnName], index, this.max_hours, employee.split_status).fulltime[columnName]
            // } else if (sheetType === 'grote_flex') {
            //   sheetValue = employee[columnName] //FormulaConfiguration(employee[columnName], index, this.max_hours, employee.split_status).grote_flex[columnName]
            // } else {
            //   sheetValue =employee[columnName] //FormulaConfiguration(employee[columnName], index, this.max_hours, employee.split_status).partime[columnName]
            // }
            rowObject.push({ ...columnConfig, value: employee[columnName], plan_id: employee.plan_id, absense_id: employee.absense_id, sheetType: sheetType, readOnly: readOnly, onChange: setSpreadSheetData, setIndex: setIndex, index: index, className: styleClass });
          }
        } else if (employee?.type === 'total') {
          const columnConfig = this.totalColumnConfigurations[columnName];
          if (columnConfig) {
            const value = this.getColumnValue(columnConfig, employee);
            rowObject.push({ ...columnConfig, value: employee[columnName], className: "bg-blue" }); //FormulaConfiguration(employee[columnName], index, this.max_hours, week_index_array, sheetType).total_fulltime[columnName]
          }
        } else {
          const columnConfig = this.totalColumnConfigurations[columnName];
          if (columnConfig) {
            const value = this.getColumnValue(columnConfig, employee);
            rowObject.push({ ...columnConfig, value: employee[columnName] }); //FormulaConfiguration(employee[columnName], index, this.max_hours, week_rows, sheetType).week_fulltime[columnName]
          }
        }

      });
      return rowObject;
    });
  }

  getColumnValue(columnConfig, employee) {
    let value;
    if (typeof columnConfig === 'function') {
      value = columnConfig(employee);
    } else {
      value = columnConfig.defaultValue;
    }
    return value;
  }
}


