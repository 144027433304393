import React, { useEffect } from 'react';
import ErrorPopup from '../../utilities/popup/ErrorPopup';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { t } from '../../translations/Translation'
import FormsNew from './FormsNew';
import { RolesApiUrl, GetRolePermissionsApiUrl, UpdateRolePermissionsApiUrl } from '../../routes/ApiEndPoints';
import { APICALL as AXIOS } from '../../services/AxiosServices';
import { toast } from 'react-toastify';
import Table from '../atoms/Table';
import ModifiedCustomCheckbox from '../atoms/formFields/ModifiedCustomCheckbox';
import BackIcon from '../../static/icons/BackIcon.png';
import CustomButton from '../atoms/CustomButton';
import WindowHeightComponent from '../atoms/WindowHeightComponent';
import { Height } from '@material-ui/icons';

export default function RolePermissions() {

    const navigate = useNavigate();
    const params = useParams();
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(true);
    const [formData, setFormData] = useState([])
    const [listData, setListData] = useState([])
    const [enabledPermissions, setEnabledPermissions] = useState([])

    useEffect(() => {
        if (params.id !== undefined && params.id !== '' && params.id !== null) {
            AXIOS.service(GetRolePermissionsApiUrl + "/" + params.id, 'GET')
                .then((result) => {
                    if (result?.success) {
                        setFormData(result.data)
                        setListData(result.data)
                        let arr = []
                        result.data?.map((item, index) => {
                            if (item.status === true) {
                                arr.push(item.id)
                            }
                        })
                        setEnabledPermissions(arr)
                    } else {
                        setErrors(result.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }, [])

    const handleCheckbox = (e) => {
        let arr = []
        let updatedData = listData.map((val, index) => {
            if (val.id === e) {
                if (val.status === false) {

                    arr.push(val.id)
                }
                return { ...val, status: !val.status }
            }
            if (val.status === true) {
                arr.push(val.id)
            }
            return val
        })
        setListData(updatedData)
        setEnabledPermissions(arr)
    }

    const table_header = [
        {
            title: t("PERMISSIONS"),
            field: 'name',
            size: 200,
        },
        {
            title: t("DESCRIPTION"),
            field: 'description',
            size: 200,
        },
        {
            title: t("ENABLE_PERMISSION"),
            // field: 'description',
            size: 200,
            render: rowData => <ModifiedCustomCheckbox checkboxList={[{ name: '', key: rowData.id }]} changeCheckbox={handleCheckbox} checked={rowData.status}></ModifiedCustomCheckbox>
        }
    ]

    const setValues = (index, name, value, field) => {
        setFormData(prev => ({ ...prev, name: value }))
    }

    const OnSave = () => {
        setSuccess(false)
        let data = {
            'id': params.id,
            'permissions': enabledPermissions
        }

        AXIOS.service(UpdateRolePermissionsApiUrl, 'PUT', data)
            .then((result) => {
                if (result?.success) {
                    navigate('/manage-configurations/roles');
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSuccess(true)
                } else {
                    setErrors(result.message);
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }


    const formFields = [
        { title: t("NAME_TEXT"), name: 'name', required: true, type: 'text', style: 'col-md-12 mt-4 float-left' },
    ]

    return (
        <WindowHeightComponent className="w-100">
            <div className="right-container w-100 h-100 pt-3 pb-2">
                {errors !== undefined && errors.length !== 0 && <ErrorPopup
                    title={t("VALIDATION_ERROR") + ("!")}
                    body={(errors)}
                    onHide={() => setErrors([])}
                ></ErrorPopup>}

                <div className="company-tab-width d-flex flex-column bg-white  border h-100 overflow-auto">

                    <div className={"d-flex px-3 justify-content-between py-2 border-thick align-items-center"}>
                        <h4 className="text-color mb-0 d-flex align-items-center"><img className="shortcut-icon mr-2 pointer" onClick={() => navigate("/manage-configurations/roles")} src={BackIcon} alt="Back"></img>{t('MANAGE_PERMISSIONS')}</h4>
                    </div>

                    <div className="manage_permission_tablescroll flex-1 overflow-auto">
                        <Table columns={table_header} rows={listData} tableName={'permissions'} style={{ display: "flex", flexDirection: "column", height: "100%", overflow: "auto" }}></Table>
                    </div>

                    <div className='my-2'>
                        <div className='text-right'>
                            <CustomButton buttonName={t('SAVE')} ActionFunction={() => OnSave()} CustomStyle={success ? "" : 'disabled'} ></CustomButton>
                            <CustomButton buttonName={t('BACK_LINK')} ActionFunction={() => navigate("/manage-configurations/roles")}></CustomButton>
                        </div>
                    </div>


                </div>


            </div>
        </WindowHeightComponent>
    )

}