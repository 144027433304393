import React from 'react'

const AddEmployee = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="24.998" height="24.502" viewBox="0 0 24.998 24.502">
        <g id="Group_6254" data-name="Group 6254" transform="translate(-159.881 -244.826)">
          <path id="Union_227" data-name="Union 227" d="M18468.314,741.363a4.979,4.979,0,0,1,0-7.062,5.017,5.017,0,0,1,8.564,3.533,5.02,5.02,0,0,1-8.564,3.529Zm1.887-3.529a.27.27,0,0,0,.27.269h1.1v1.113a.273.273,0,0,0,.547,0V738.1h1.137a.269.269,0,0,0,0-.538h-1.137v-1.118a.273.273,0,0,0-.547,0v1.118h-1.1A.27.27,0,0,0,18470.2,737.835Zm-18.32,3.9v-2.8c0-3.441,1.484-6.07,4.188-7.4a8.571,8.571,0,0,0,9.725,2.013,9.018,9.018,0,0,0,.139,8.184v0Zm3.881-16.879a6.651,6.651,0,1,1,6.65,6.524A6.6,6.6,0,0,1,18455.762,724.855Z" transform="translate(-18292 -473.5)" fill="#073763" />
        </g>
      </svg>
    </>
  )
}

export default AddEmployee