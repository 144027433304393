import React from "react";

function Taxes() {
    return (
        <svg id="SvgjsSvg1075" width="35" height="35" xmlns="http://www.w3.org/2000/svg" version="1.1" xlink="http://www.w3.org/1999/xlink" svgjs="http://svgjs.com/svgjs">
            <defs id="SvgjsDefs1076"></defs>
            <g id="SvgjsG1077"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="55" height="55">
                <g data-name="Layer 2" fill="#073763" class="color000 svgShape">
                    <polygon fill="#073763" points="14.05 6.414 9.464 11 14.05 11 14.05 6.414" class="color231f20 svgShape"></polygon>
                    <path fill="#073763" d="M42.1,49.364l.217.217,6.9-3.137L52.108,42.4l-4.458-4.458-4.042,2.887-3.138,6.9.217.217,3.535-3.536a1,1,0,0,1,1.414,1.414Z" class="color231f20 svgShape"></path>
                    <rect width="2" height="8" x="51" y="34.05" fill="#073763" transform="rotate(-44.99 52 38.05)" class="color231f20 svgShape"></rect>
                    <path fill="#073763" d="M46.05,50.08l-3.54,1.61a.978.978,0,0,1-.41.09,1.007,1.007,0,0,1-.71-.29l-2.83-2.83a1.012,1.012,0,0,1-.2-1.12l3.54-7.78a.916.916,0,0,1,.33-.4l3.82-2.73V5h-30v7a1,1,0,0,1-1,1h-7V59h38ZM33.05,15h8v2h-8Zm0,4h8v2h-8Zm0,4h8v2h-8Zm0,4h8v2h-8Zm-13-12.61V12h2v2.4a4.814,4.814,0,0,1,3.82,3.11l-1.91.61a2.948,2.948,0,0,0-2.91-1.81c-1.65,0-3,1.07-3,2.38a2.187,2.187,0,0,0,.93,1.72,3.992,3.992,0,0,0,2.12.63c.27.03.55.06.83.11a4.632,4.632,0,0,1,4.12,4.32,4.574,4.574,0,0,1-4,4.29V32h-2V29.75a4.776,4.776,0,0,1-3.77-2.97l1.88-.67a3.008,3.008,0,0,0,2.89,1.74c1.65,0,3-1.07,3-2.38a2.642,2.642,0,0,0-2.43-2.35c-.25-.04-.5-.07-.75-.1a5.837,5.837,0,0,1-3.1-1.01,4.2,4.2,0,0,1-1.72-3.32A4.583,4.583,0,0,1,20.05,14.39ZM13.18,35h26v2h-26Zm15,10h-15V43h15Zm4.87-4h-20V39h20Z" class="color231f20 svgShape"></path>
                </g>
            </svg></g>
        </svg>
    );
}

export default Taxes;