import React from 'react'

const AddCompanyGroup = () => {
    return (
        <>
            {/* <?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> */}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 6C9 6.39397 9.0776 6.78407 9.22836 7.14805C9.37913 7.51203 9.6001 7.84274 9.87868 8.12132C10.1573 8.3999 10.488 8.62087 10.852 8.77164C11.2159 8.9224 11.606 9 12 9C12.394 9 12.7841 8.9224 13.1481 8.77164C13.512 8.62087 13.8427 8.3999 14.1213 8.12132C14.3999 7.84274 14.6209 7.51203 14.7716 7.14805C14.9224 6.78407 15 6.39396 15 6C15 5.60603 14.9224 5.21593 14.7716 4.85195C14.6209 4.48797 14.3999 4.15725 14.1213 3.87868C13.8427 3.6001 13.512 3.37912 13.148 3.22836C12.7841 3.0776 12.394 3 12 3C11.606 3 11.2159 3.0776 10.8519 3.22836C10.488 3.37913 10.1573 3.6001 9.87868 3.87868C9.6001 4.15726 9.37912 4.48797 9.22836 4.85195C9.0776 5.21593 9 5.60604 9 6L9 6Z" stroke="#222222" />
                <path d="M4.43781 13.9015C4.09663 14.0985 3.79758 14.3607 3.55775 14.6733C3.31792 14.9858 3.142 15.3426 3.04004 15.7231C2.93807 16.1037 2.91206 16.5006 2.96348 16.8912C3.0149 17.2818 3.14275 17.6584 3.33974 17.9996C3.53672 18.3408 3.79897 18.6398 4.11153 18.8796C4.42408 19.1195 4.78081 19.2954 5.16136 19.3974C5.5419 19.4993 5.9388 19.5253 6.32939 19.4739C6.71999 19.4225 7.09663 19.2946 7.43781 19.0977C7.779 18.9007 8.07804 18.6384 8.31787 18.3259C8.5577 18.0133 8.73362 17.6566 8.83559 17.276C8.93756 16.8955 8.96357 16.4986 8.91215 16.108C8.86072 15.7174 8.73287 15.3408 8.53589 14.9996C8.33891 14.6584 8.07665 14.3594 7.7641 14.1195C7.45154 13.8797 7.09481 13.7038 6.71427 13.6018C6.33373 13.4998 5.93683 13.4738 5.54623 13.5252C5.15564 13.5767 4.779 13.7045 4.43781 13.9015L4.43781 13.9015Z" stroke="#222222" />
                <path d="M19.5622 13.9015C19.9034 14.0985 20.2024 14.3607 20.4422 14.6733C20.6821 14.9859 20.858 15.3426 20.96 15.7231C21.0619 16.1037 21.0879 16.5006 21.0365 16.8912C20.9851 17.2818 20.8572 17.6584 20.6603 17.9996C20.4633 18.3408 20.201 18.6398 19.8885 18.8796C19.5759 19.1195 19.2192 19.2954 18.8386 19.3974C18.4581 19.4993 18.0612 19.5253 17.6706 19.4739C17.28 19.4225 16.9034 19.2946 16.5622 19.0977C16.221 18.9007 15.922 18.6384 15.6821 18.3259C15.4423 18.0133 15.2664 17.6566 15.1644 17.276C15.0624 16.8955 15.0364 16.4986 15.0879 16.108C15.1393 15.7174 15.2671 15.3408 15.4641 14.9996C15.6611 14.6584 15.9234 14.3594 16.2359 14.1195C16.5485 13.8797 16.9052 13.7038 17.2857 13.6018C17.6663 13.4998 18.0632 13.4738 18.4538 13.5252C18.8444 13.5767 19.221 13.7045 19.5622 13.9015L19.5622 13.9015Z" stroke="#222222" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00254 6.12533C9.0008 6.08372 8.99993 6.04203 8.99993 6.0003C8.99993 5.65294 9.06026 5.30857 9.17787 4.98247C9.03301 5.03346 8.8894 5.08843 8.74719 5.14733C7.71592 5.5745 6.77889 6.2006 5.98959 6.9899C5.20029 7.7792 4.57419 8.71623 4.14702 9.7475C3.71986 10.7788 3.5 11.8841 3.5 13.0003C3.5 13.5265 3.54885 14.0502 3.64523 14.5648C3.86884 14.2999 4.13691 14.0755 4.43774 13.9018C4.47388 13.8809 4.51042 13.8609 4.54733 13.8415C4.51586 13.5628 4.5 13.2819 4.5 13.0003C4.5 12.0154 4.69399 11.0401 5.0709 10.1302C5.44781 9.22024 6.00026 8.39345 6.6967 7.69701C7.36051 7.03319 8.14275 6.5002 9.00254 6.12533ZM14.9973 6.12528C15.8572 6.50014 16.6395 7.03315 17.3033 7.697C17.9997 8.39344 18.5522 9.22023 18.9291 10.1302C19.306 11.0401 19.5 12.0154 19.5 13.0003C19.5 13.282 19.4841 13.5628 19.4527 13.8416C19.4895 13.8609 19.526 13.881 19.5621 13.9018C19.863 14.0755 20.1311 14.3 20.3547 14.565C20.4511 14.0503 20.5 13.5265 20.5 13.0003C20.5 11.8841 20.2801 10.7788 19.853 9.74749C19.4258 8.71622 18.7997 7.77919 18.0104 6.98989C17.2211 6.2006 16.2841 5.57449 15.2528 5.14733C15.1105 5.0884 14.9669 5.03342 14.822 4.98242C14.9396 5.30854 14.9999 5.65292 14.9999 6.0003C14.9999 6.04201 14.9991 6.08368 14.9973 6.12528ZM17.533 19.4529C17.1916 19.3917 16.8631 19.2717 16.5621 19.098C16.5261 19.0772 16.4906 19.0556 16.4555 19.0334C15.9665 19.3945 15.4343 19.6957 14.8701 19.9294C13.9602 20.3063 12.9849 20.5003 12 20.5003C11.0151 20.5003 10.0398 20.3063 9.12988 19.9294C8.56568 19.6957 8.03345 19.3945 7.54443 19.0334C7.50935 19.0556 7.47378 19.0772 7.43775 19.098C7.13681 19.2717 6.80829 19.3917 6.46694 19.4528C7.14728 20.0362 7.91668 20.5093 8.74719 20.8533C9.77846 21.2804 10.8838 21.5003 12 21.5003C13.1162 21.5003 14.2215 21.2804 15.2528 20.8533C16.0833 20.5093 16.8527 20.0362 17.533 19.4529Z" fill="#222222" />
            </svg>
        </>
    )
}

export default AddCompanyGroup