import React from "react";

function OverTime() {
    return (
        <svg id="SvgjsSvg1103" width="28" height="28" xmlns="http://www.w3.org/2000/svg" version="1.1" xlink="http://www.w3.org/1999/xlink" svgjs="http://svgjs.com/svgjs">
            <defs id="SvgjsDefs1104"></defs>
            <g id="SvgjsG1105"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="45" height="45">
                <path fill="#073763" d="M19 18v1A28.92 28.92 0 0 1 7.08 17 6 6 0 0 1 19 18zM32.16 38.88L31.57 43H22L18 33v8.79l-3-.22V47H4a3 3 0 0 1-3-3V30.12a4 4 0 0 1 3-3.88l4.59-1.15a6 6 0 0 0 8.72.05l3 1.2a4 4 0 0 1 2.27 2.34L26 38z" class="colorbfbfbf svgShape"></path>
                <path fill="#ffffff" d="M43,18a13,13,0,0,1-3.81,9.19L30,18V5A13,13,0,0,1,43,18Z" class="colorfc6459 svgShape"></path>
                <circle cx="39" cy="40" r="1" fill="#073763" class="color424242 svgShape"></circle>
                <path fill="#073763" d="M33,22a1,1,0,0,1-.71-.29l-3-3A1,1,0,0,1,29,18V12a1,1,0,0,1,2,0v5.59l2.71,2.7A1,1,0,0,1,33,22Z" class="colorbfbfbf svgShape"></path>
                <path fill="#073763" d="M30,20a2,2,0,1,1,2-2A2,2,0,0,1,30,20Zm0-2Z" class="colorbfbfbf svgShape"></path>
                <path fill="#073763" d="M47,32H41.3A18,18,0,1,0,13.43,11,7,7,0,0,0,6,18c0,2.89-.22,4.49.94,6.48l-3.16.79A5,5,0,0,0,0,30.12V44a4,4,0,0,0,4,4H45a1,1,0,0,0,1-.86l2-14A1,1,0,0,0,47,32ZM13,13a5,5,0,0,1,5,5,27.23,27.23,0,0,1-9.72-1.59A5,5,0,0,1,13,13ZM8,18.44A29.41,29.41,0,0,0,18,20v1A5,5,0,0,1,8,21ZM19.53,45.53a1.58,1.58,0,0,1-1.12.47H16V42.65l2.53.18A1.58,1.58,0,0,1,19.53,45.53ZM19,40.89v-2.7l1.27,3.17A3.66,3.66,0,0,0,19,40.89ZM30.13,46H21.61A3.36,3.36,0,0,0,22,44h8.46Zm.57-4h-8l-3.75-9.37A1,1,0,0,0,17,33v7.71l-9-.64V33a1,1,0,0,0-2,0v8a1,1,0,0,0,.93,1l7.07.5V46H4a2,2,0,0,1-2-2V30.12a3,3,0,0,1,2.27-2.91l4.05-1a7,7,0,0,0,9.24.1l2.41,1A3,3,0,0,1,21.67,29c.32.95-1.25-3.44,3.39,9.32a1,1,0,0,0,.8.65l5.17.74ZM23.64,10.22l-1.4-1.39A12,12,0,0,1,29,6V8a1,1,0,0,0,2,0V6.05a11.9,11.9,0,0,1,6.74,2.8l-1.38,1.37a1,1,0,0,0,.71,1.71c.55,0,.68-.26,2.08-1.67A11.9,11.9,0,0,1,42,17H40a1,1,0,0,0,0,2h2a11.9,11.9,0,0,1-2.8,6.74l-1.37-1.38a1,1,0,0,0-1.42,1.42l1.38,1.37A11.9,11.9,0,0,1,31,30V28a1,1,0,0,0-2,0v2a11.8,11.8,0,0,1-5.61-2c-1-2.13-2.57-2.54-4.46-3.29C20.27,22.55,20,20.84,20,18a6.88,6.88,0,0,0-1.3-4,12.11,12.11,0,0,1,2.13-3.72c1.49,1.5,1.56,1.69,2.1,1.69A1,1,0,0,0,23.64,10.22Zm7.67,27.53-4.57-.66-.55-1.51a17.53,17.53,0,0,0,5.38.35Zm.56-3.89a16.07,16.07,0,0,1-6.51-.55l-.89-2.46a14,14,0,1,0-7.3-18.46,6.74,6.74,0,0,0-1.75-1A16,16,0,1,1,37.73,32H33a1,1,0,0,0-1,.86ZM44.13,46h-12c1.2-8.38.83-5.78,1.72-12h12Z" class="color424242 svgShape"></path>
            </svg></g>
        </svg>
    );
}

export default OverTime;