import React, { useState } from "react";
import ModalPopup from "../../utilities/popup/Popup";
import { t } from "../../translations/Translation";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import Card from "../atoms/Card";
import CustomCheckBox from "../atoms/formFields/CustomCheckBox";
import RadioInput from "../atoms/formFields/RadioInput";
import { getFormattedRadioOptions } from "../../utilities/CommonFunctions";

export default function MoveEmployeePopup({ MoveEmployees, setMovePopup }) {

    const [errors, setErrors] = useState([]);
    const [selectedFunction, setSelectedFunction] = useState('')

    const Employees = [
        { id: 1, workstation_name: 'Function name 1', timings: '09:00 AM - 18:00 PM', status: 'not-available' },
        { id: 2, workstation_name: 'Function name 2', timings: '09:00 AM - 18:00 PM', status: 'available' },
        { id: 3, workstation_name: 'Function name 3', timings: '09:00 AM - 18:00 PM', status: 'not-available' },
        { id: 4, workstation_name: 'Function name 4', timings: '09:00 AM - 18:00 PM', status: 'not-available' },
        { id: 5, workstation_name: 'Function name 5', timings: '09:00 AM - 18:00 PM', status: 'available' },
        { id: 6, workstation_name: 'Function name 6', timings: '09:00 AM - 18:00 PM', status: 'available' },
    ]


    return (
        <ModalPopup
            size="xl"
            title={t('MOVE_EMP_FUN')}
            body={
                <div>
                    {errors !== undefined && errors.length !== 0 && <ErrorPopup
                        title={t("VALIDATION_ERROR") + ("!")}
                        body={(errors)}
                        onHide={() => setErrors([])}
                    ></ErrorPopup>}
                    <div className="d-flex flex-wrap">
                        {Employees.map((val, i) => {
                            return (
                                <div className={val.status !== 'not-available' ? "card width-22 p-0 m-3 bg-grey" : "card width-22 p-0 m-3 table-head-bg"}>
                                    <div class="card-body p-2 col-md-12">
                                        <div className="d-flex m-0 col-md-12 p-0">
                                            <div className="col-md-10">
                                                <small class="mb-0">{val.workstation_name}</small>
                                                <br></br>
                                                <small class="mb-0">{val.timings}</small>
                                            </div>
                                            <div className="col-md-2 custom-control custom-checkbox">
                                                <input
                                                    className=""
                                                    type="radio"
                                                    name={val.workstation_name}
                                                    value={val.id}
                                                    checked={val.id === parseInt(selectedFunction)}
                                                    onChange={(e) => setSelectedFunction(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary">Go somewhere</a> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            onConfirm={() => MoveEmployees()}
            startplanButton={t('SEND')}
            onHide={() => { setMovePopup(false) }}
            close={true}
        ></ModalPopup>
    )
}
