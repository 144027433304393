import React, { useState, useEffect } from "react";
import Table from "../atoms/Table";
import { APICALL as axios } from "../../services/AxiosServices";
import { t } from "../../translations/Translation";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetEmployeeDocumentsApiUrl } from "../../routes/ApiEndPoints";
import UpdateIdCardPopup from "./UpdateIdCardPopup";
import CustomButton from "../atoms/CustomButton";
import ErrorPopup from "../../utilities/popup/ErrorPopup";

export default function EmployeeDocumentsOverview({ eid }) {

    const [documentsData, setDocumentsData] = useState([])
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];
    const [show, setShow] = useState(false)
    const [dataRefresh, setDataRefresh] = useState(false)
    const [errors, setErrors] = useState('')


    const tableHeaders = [
        {
            title: t("DOCUMENT_NAME"),
            field: "file_name",
            status: "200",
        },
        {
            title: t("TYPE"),
            field: "type",
            status: "200",
        },
    ]

    useEffect(() => {
        AXIOS.service(GetEmployeeDocumentsApiUrl + "/" + eid, 'GET')
            .then((result) => {
                if (result?.success) {
                    let arr = []
                    result.data.map((item, index) => {
                        item.id = item.file_id
                        arr.push(item)
                    })
                    setDocumentsData(arr)
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [eid, dataRefresh])


    const viewAction = (data, action) => {

        if (action === 'view') {
            window.open(data.file_url, '_blank')
        }

    }

    return (
        <>
        {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("WARNING_TITLE") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {show && <UpdateIdCardPopup show={show} setShow={setShow} onHide={() => setShow(false)} eid={eid} dataRefresh={dataRefresh} setDataRefresh={setDataRefresh}></UpdateIdCardPopup>}
            <div className="d-flex  justify-content-end  mt-2  mb-2 w-100">
                {/* <CustomButton buttonName={t("UPDATE_ID_CARD")} ActionFunction={()=>setShow(true)}></CustomButton> */}
            </ div>
            <Table columns={tableHeaders} rows={documentsData} tableName={"documents_overview"} viewAction={viewAction} permission={UserPermissions?.includes('modify_employee_details')} viewPermission={UserPermissions?.includes('modify_employee_details')}></Table>
        </>
    )
}