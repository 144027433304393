import react from 'react';
import { useState, useEffect } from 'react';
import { APICALL as AXIOS } from '../services/AxiosServices';
import { GetplansFortheDayApiUrl, StartPlanByEmployeeApiUrl, StopPlanByEmployeeApiUrl, StartBreakByPlanIdApiUrl, StopBreakEmployeeApiUrl, StopPastPlansApiurl, GetContractPDFApiUrl, SignContractApiUrl, LogoutApiUrl, SignLaterApiUrl } from '../routes/ApiEndPoints';
import { t } from '../translations/Translation';
import Table from '../components/atoms/Table';
import CustomButton from '../components/atoms/CustomButton';
import { GetFormattedDate } from '../utilities/CommonFunctions';
import DotIcon from '../static/icons/DotIcon';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../utilities/popup/ErrorPopup';
import { ToastContainer, toast } from 'react-toastify';
import CustomPopup from '../utilities/popup/CustomPopup'
import TimeInput from '../components/atoms/TimeInput';
import SignaturePad from '../components/atoms/SignaturePad';

export default function CurrentPlan({ setAuth }) {

    const [isActive, setActive] = useState(false);
    const [isPlansAvailable, setIsPlansAvailable] = useState(false)
    const [isLoading, setIsLoading] = useState(true);

    const currentDate = new Date()

    const [plansData, setPlansData] = useState([])
    const [currentPlan, setCurrentPlan] = useState('');
    const [pastPlan, setPastPlan] = useState('');
    const [showPopup, setShowPopup] = useState(false)
    const [stopTime, setStopTime] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [signContractStatus, setSignContractStatus] = useState(false)
    const [showContractPopup, setShowContractPopup] = useState(false)
    // const [pdfData, setPDFData] = useState('')
    const [contractDetails, setContractDetails] = useState();

    const [messageToDisplay, setMessagesToDisplay] = useState('');
    const [errors, setErros] = useState('');
    const [refreshSign, setRefreshSign] = useState(true);// this state displays sign pad
    const [pdfFileError, setPdfFileError] = useState([]);

    const navigate = useNavigate()

    useEffect(() => {

        let data = {
            'dashboard_access_token': localStorage.getItem("dashboard_access_token"),
        }
        AXIOS.service(GetplansFortheDayApiUrl, 'POST', data)
            .then((result) => {
                if (result?.success) {
                    setIsLoading(false)
                    setActive(true)
                    setIsPlansAvailable(result.data?.length >= 1 ? true : false)
                    findCurrentOrPastPlan(result.data)
                    setPlansData(result.data)

                } else {
                    setIsLoading(false)
                    setMessagesToDisplay(result.message[0]);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [refresh])


    const tableHeader = [
        {
            title: t('CURRENT_PLAN'),
            field: 'id',
            size: 200,
            render: rowData => { return (rowData.current_plan ? <DotIcon color='green' /> : (rowData.past_plan ? <DotIcon color='red' /> : "")) }
        },
        {
            title: t('WORK_STATION'),
            field: 'workstation',
            size: 200,
        },
        {
            title: t('FUNCTION_NAME'),
            field: 'function',
            size: 200,
        },
        {
            title: t('PLAN_DATE'),
            field: 'date',
            size: 200,
        },
        {
            title: t('START_TIME'),
            field: 'start_time',
            size: 200,
        },
        {
            title: t('END_TIME'),
            field: 'end_time',
            size: 200,
        },
    ]

    const findCurrentOrPastPlan = (dataArr) => {

        dataArr?.map((item, index) => {
            if (item.current_plan) {
                setCurrentPlan(item)
                setSignContractStatus(item?.sign_contract_status);
            }
            if (item.past_plan) {
                setPastPlan(item)
                setMessagesToDisplay(item.description)
                // setErros([item.description])
            }
            return null;
        }
        )
    }

    const apiCall = (url, action) => {
        let data = {
            'plan_id': currentPlan.id
        }
        AXIOS.service(url, 'POST', data)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setRefresh(!refresh)
                    if (action !== 'sign_later') { Logout() } else {
                        setShowContractPopup(false)
                    }
                } else {
                    setErros(result.message);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const contractApicall = () => {
        setShowContractPopup(true)
        GetContractPopupBody()
        AXIOS.service(`${GetContractPDFApiUrl}/${currentPlan.id}`, "GET")
            .then((result) => {
                if (result?.success) {
                    // setPDFData(reult.data?.contract_url)
                    GetContractPopupBody(result.data?.contract_url)
                    setPdfFileError([]);
                } else {
                    setPdfFileError(result.message)
                    GetContractPopupBody('', result.message[0])
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const sendSignatureData = (data) => {
        let req_data = {
            'plan_id': currentPlan.id,
            'signature': data
        }
        AXIOS.service(SignContractApiUrl, 'POST', req_data)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setRefresh(!refresh)
                    setShowContractPopup(false)
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const stopPastPlan = () => {

        let data = {
            'plan_id': plansData[0].id,
            'stop_time': stopTime
        }
        AXIOS.service(StopPastPlansApiurl, 'POST', data)
            .then((result) => {
                if (result?.success) {
                    setShowPopup(false);
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setRefresh(!refresh)
                    setPastPlan(false)
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const handleButtons = (action) => {
        switch (action) {
            case 'start_plan': apiCall(StartPlanByEmployeeApiUrl); break;
            case 'stop_plan': apiCall(StopPlanByEmployeeApiUrl); break;
            case 'start_break': apiCall(StartBreakByPlanIdApiUrl); break;
            case 'stop_break': apiCall(StopBreakEmployeeApiUrl); break;
            case 'stop_past_plan': stopPastPlan(); break;
            case 'sign': contractApicall(); break;
            default: break;
        }
    }

    const handlePastTimePlan = () => {
        setShowPopup(true)
    }

    const onHide = () => {
        setShowPopup(false)
    }

    const onHideContractPopup = () => {
        setShowContractPopup(false)
    }

    const setValue = (val) => {
        setStopTime(val)
    }

    const GetBody = () => {
        return (<TimeInput customStyle={'col-12'} value={stopTime} setTime={setValue}></TimeInput>)
    }

    const signLater = () => {
        apiCall(SignLaterApiUrl, 'sign_later')
    }

    // Function to call Logout Api call 
    const Logout = () => {
        AXIOS.service(LogoutApiUrl, 'GET')
            .then((result) => {
                if (result.success) {
                    let lastCompanyId = localStorage.getItem('company_id')
                    let previousLang = localStorage.getItem('active_language')
                    let dashboard_access_token = localStorage.getItem('dashboard_access_token')
                    localStorage.clear();
                    localStorage.setItem('auth', false)
                    localStorage.setItem('company_id', lastCompanyId)
                    localStorage.setItem('active_language', previousLang)
                    localStorage.setItem('dashboard_access_token', dashboard_access_token)
                    setAuth(false);
                    navigate('/login');
                }
            })
    }

    // to re-render after cancel action
    useEffect(() => {
        GetContractPopupBody()
        setRefreshSign(true)
        setShowContractPopup(false)
    }, [refreshSign])

    const GetContractPopupBody = (url, error) => {
        setContractDetails(
            <div>
                {error && <h4 className='text-center text-danger p-2'>{error}</h4>}
                {!url && !error&&<div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border custom-loading-icon"></div></div>}
                <object data={url} type="application/pdf" width="100%" height="500px"></object>
               <CustomButton buttonName={t("SIGN_LATER")} CustomStyle="float-right mt-2" ActionFunction={() => signLater()}></CustomButton>
                {url&&<CustomButton buttonName={t("SIGN")} ActionFunction={() => setContractDetails(<SignaturePad view="contractSign" sendSignatureData={sendSignatureData} sign={refreshSign} setSign={setRefreshSign} refresh={refreshSign}></SignaturePad>)} CustomStyle="float-right mt-2"></CustomButton>}
            </div>
        )

    }

    const ButtonList = [
        { "name": t('SIGN'), action: "sign", display: signContractStatus },
        { "name": t('START_PLAN'), action: "start_plan", display: currentPlan.start_plan_status && !signContractStatus },
        { "name": t('STOP_PLAN'), action: "stop_plan", display: !currentPlan.start_plan_status && currentPlan.start_break_status && !signContractStatus },
        { "name": t('START_BREAK'), action: "start_break", display: !currentPlan.start_plan_status && currentPlan.start_break_status && !signContractStatus },
        { "name": t('STOP_BREAK'), action: "stop_break", display: !currentPlan.start_plan_status && !currentPlan.start_break_status && !signContractStatus },
    ]

    return (
        <div className='right-container'>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {errors && <ErrorPopup
                title={t('VALIDATION_ERROR')}
                body={errors}
                onHide={() => setErros('')}
            ></ErrorPopup>}
            {showContractPopup && <CustomPopup title={t('SIGN')} close size='lg' header body={contractDetails} onHide={onHideContractPopup}></CustomPopup>}
            {showPopup && <CustomPopup title={t('ENTER_STOP_TIME')} footer size='sm' header body={GetBody()} onHide={onHide} saveButtonName={t('STOP_PLAN')} onConfirm={() => { handleButtons('stop_past_plan') }}></CustomPopup>}
            <div className='border company-tab-width mt-3 bg-white'>
                {isLoading ? <div className="d-flex justify-content-center align-items-center h-25"><div className="spinner-border custom-loading-icon"></div></div>
                    :
                    <>
                        {!isActive && <div className='d-flex.column p-2'><h3 className='mt-5 text-center' id="text-indii-blue">{messageToDisplay}</h3></div>}
                        {isActive &&
                            <>
                                <div className='bg-gray mb-3 d-flex justify-content-center align-items-center height-10 font-weight-bold'><h3 className='text-color font-weight-bold'>{`Plannings for the day ${GetFormattedDate(currentDate, currentDate.getFullYear())}`}</h3></div>
                                {!isPlansAvailable && <div className='d-flex justify-content-center p-2'><h3 className='text-danger'>{t("CURRENTLY_NO_PLANS_AVAILABLE")}</h3></div>}
                                {pastPlan && <div className='d-flex.column p-2 border mx-4 mb-2 shadow'><h5 className='text-center text-danger'>{/*t("DEVICE_NOT_ACTIVATED")*/messageToDisplay}</h5></div>}
                                <div className='mx-4'> <Table columns={tableHeader} rows={plansData} tableName='current_plans'></Table>
                                </div>
                                <div className='d-flex justify-content-center mt-5'>
                                    {currentPlan && ButtonList.map((item, index) => {
                                        if (item.display) {
                                            return (
                                                <CustomButton key={index} buttonName={item.name} ActionFunction={() => handleButtons(item.action)}></CustomButton>
                                            )
                                        }
                                    })}
                                    {pastPlan && <CustomButton buttonName={'stop Plan'} ActionFunction={() => handlePastTimePlan()}></CustomButton>}
                                    {!signContractStatus && <CustomButton buttonName={t('LOGOUT')} ActionFunction={() => Logout()}></CustomButton>}
                                </div>
                            </>
                        }
                    </>}
            </div>
        </div>
    )
}