import React from 'react'

const Translate = () => {
  return (
    <>
      <svg id="translate" xmlns="http://www.w3.org/2000/svg" width="67.77" height="67.77" viewBox="0 0 67.77 67.77">
        <g id="Group_27803" data-name="Group 27803" transform="translate(0 0)">
          <path id="Path_55745" data-name="Path 55745" d="M15.544,24.731,13.7,30.178H9L16.887,9h5.438l7.853,21.178H25.235l-1.842-5.447Zm6.921-3.117-2.876-8.564h-.208l-2.876,8.564h5.964Z" transform="translate(3.707 3.707)" fill="#073763" />
          <path id="Path_55746" data-name="Path 55746" d="M0,8.471A8.471,8.471,0,0,1,8.471,0H38.12a8.471,8.471,0,0,1,8.471,8.471V21.178H59.3a8.471,8.471,0,0,1,8.471,8.471V59.3A8.471,8.471,0,0,1,59.3,67.77H29.649A8.471,8.471,0,0,1,21.178,59.3V46.592H8.471A8.471,8.471,0,0,1,0,38.12ZM8.471,4.236A4.236,4.236,0,0,0,4.236,8.471V38.12a4.236,4.236,0,0,0,4.236,4.236H38.12a4.236,4.236,0,0,0,4.236-4.236V8.471A4.236,4.236,0,0,0,38.12,4.236ZM38.7,46.57a30.981,30.981,0,0,0,2.668,3.583,32.849,32.849,0,0,1-11.724,5.472A21.3,21.3,0,0,1,32,59.3,37.088,37.088,0,0,0,44.224,52.97a30.349,30.349,0,0,0,12.41,6.235,19.055,19.055,0,0,1,2.664-3.77A29.982,29.982,0,0,1,47.354,50,34.263,34.263,0,0,0,54.22,38.319H59.3V33.885H46.592v4.435h3.24a27.63,27.63,0,0,1-5.388,9.022,25.691,25.691,0,0,1-1.758-2.084A8.42,8.42,0,0,1,38.7,46.57Z" transform="translate(0 0)" fill="#073763" />
        </g>
      </svg>

    </>
  )
}

export default Translate