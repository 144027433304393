import React from "react";
import TextInput from "../atoms/formFields/TextInput";
import Dropdown from "../atoms/Dropdown";
import TimeInput from "../atoms/TimeInput";
import CancelIcon from "../../static/icons/Close.png";

export default function OthPlanForm({ planData, i, index, othPlanData, SetValues, daysArr, locationsData, workstationsData, workstationsList }) {

    return (
        <form className="col-md-12 px-0 d-flex border-blue">
                {planData.map((field, j) => {
                    if (field.type === "text") {
                        return (
                            <TextInput
                                key={field.name}
                                title={field.title}
                                name={field.name}
                                CustomStyle={field.style}
                                required={field.required}
                                value={othPlanData !== undefined && othPlanData[index] !== undefined && othPlanData[index][i] !== undefined ? othPlanData[index][i][field.name] : ''}
                                setValue={(e) => SetValues(i, field.name, e, field.type, index)}
                            ></TextInput>
                        )
                    } else if (field.type === 'dropdown' && field.forDay) {
                        return (
                            <Dropdown
                                key={field.name}
                                options={field.options}
                                selectedOptions={othPlanData !== undefined && othPlanData[index] !== undefined && othPlanData[index][i] !== undefined &&othPlanData[index][i][field.name] !== undefined? { value: othPlanData[index][i][field.name], label: daysArr[othPlanData[index][i][field.name] - 1] } : []}
                                onSelectFunction={(e) => SetValues(i, field.name, e, field.type, index)}
                                CustomStyle={field.style}
                                title={field.title}
                                required={field.required}
                                isMulti={field.isMulti}
                            ></Dropdown>
                        )
                    } else if (field.type === 'time') {
                        return (
                            <TimeInput
                                key={field.name}
                                title={field.title}
                                setTime={(e) => SetValues(i, field.name, e, field.type, index)}
                                value={othPlanData !== undefined && othPlanData[index] !== undefined && othPlanData[index][i] !== undefined && othPlanData[index][i] !== undefined ? othPlanData[index][i][field.name] : ''}
                                type={field.type}
                                index={i}
                                required={field.required}
                                customStyle={field.style}
                            ></TimeInput>
                        )
                    } else if (field.type === 'dropdown') {
                        return (
                            <Dropdown
                                key={field.name}
                                options={ field.name === 'location_id'?field.options:( workstationsList?.length > 0 ? workstationsList : workstationsList[locationsData !== undefined &&locationsData[index] !== undefined &&locationsData[index][i] !== undefined? locationsData[index][i].value:""])}
                                selectedOptions={
                                    field.name === 'location_id'
                                        ? (locationsData !== undefined &&locationsData[index] !== undefined &&locationsData[index][i] !== undefined? locationsData[index][i] : [])
                                        : (workstationsData !== undefined && workstationsData[index] !== undefined && workstationsData[index][i] !== undefined? workstationsData[index][i] : [])
                                }
                                onSelectFunction={(e) => SetValues(i, field.name, e, field.type, index)}
                                CustomStyle={field.style}
                                title={field.title}
                                required={field.required}
                                isMulti={field.isMulti}
                            ></Dropdown>
                        )
                    }
                })}
                {othPlanData !== undefined && othPlanData[index] !== undefined && othPlanData[index][i] !== undefined && <img src={CancelIcon} className={i === 0 ? "box mt-5" : "box mt-2"} alt="Clear" onClick={() => SetValues(i, 'clear', '', '', index)}></img>}
        </form>
    )
}
