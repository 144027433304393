import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import FormsNew from "./FormsNew";
import { Button } from "react-bootstrap";
import CustomCheckBox from "../atoms/formFields/CustomCheckBox";
import RegenerateQRIcon from "../../static/icons/RegenerateQRcode.svg"
import RefreshIcon from "../../static/icons/refresh.png"
import { ToastContainer, toast } from "react-toastify"
import { t } from "../../translations/Translation";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetQRConfigurationsApiUrl, ChangeQRConfigurationApiUrl, GenerateNewQRcode, DownloadQRcodeForTypeDailyApiUrl, DownloadQRcodeForTypeMAnualApiUrl } from "../../routes/ApiEndPoints";
import DownloadQRIcon from "../../static/icons/DownloadQR.svg"
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function QRConfigurationPopup(props) {
    const [errors, setErrors] = useState([]);
    const [listData, setListData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [formData, setFormData] = useState({});
    const [locationId, setLocationId] = useState('');
    const [locationIndex, setLocationIndex] = useState("");
    const [isRefreshTypeDaily, setIsRefreshTypeDaily] = useState(false);
    const [blockCheckbox, setBlockCheckbox] = useState(false);

    useEffect(() => {

        AXIOS.service(GetQRConfigurationsApiUrl, "GET")
            .then((result) => {
                if (result?.success) {
                    setListData(result.data)
                }
            }).catch((error) => {
                console.log(error);
            })

    }, [refresh])

    const handleCheckBox = (data, index) => {
        let newData = { ...data }
        let CheckoxData = [...listData]
        CheckoxData[index]['refresh_type'] = data.refresh_type === 'daily' ? 'manual' : 'daily'
        setListData(CheckoxData);
        setBlockCheckbox(true);
        newData.refresh_type = newData.refresh_type === 'daily' ? 'manual' : 'daily'
        AXIOS.service(ChangeQRConfigurationApiUrl, "POST", newData)
            .then((result) => {
                if (result?.success) {
                    setRefresh(!refresh)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setBlockCheckbox(false);
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const refreshQR = (rowData) => {

        AXIOS.service(GenerateNewQRcode, 'POST', rowData)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }



    const setValue = (index, name, value, field) => {

        let newData = { ...formData }
        newData[name] = value
        setFormData(newData);

    }

    const downloadAction = (data, index) => {

        setLocationId(data.location_id)
        setLocationIndex(index)
        if (data.refresh_type === "daily") {
            setIsRefreshTypeDaily(true)
            setFormData({ from_date: "", to_date: "" })
        } else {
            setIsRefreshTypeDaily(false)
            downloadQr(data)
        }

    }

    const downloadQr = (data) => {

        if (data.refresh_type === 'manual') {

            AXIOS.service(DownloadQRcodeForTypeMAnualApiUrl, "POST", data)
                .then((result) => {
                    if (result.success) {
                        let link = document.createElement("a");
                        link.href = result.url;
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)

                    } else {
                        setErrors(result.message)
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } else if (data.refresh_type === 'daily') {
            let newData = { ...formData }
            newData.location_id = locationId

            AXIOS.service(DownloadQRcodeForTypeDailyApiUrl, "POST", newData)
                .then((result) => {
                    if (result.success) {
                        let link = document.createElement("a");
                        link.href = result.url;
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        setLocationIndex("")

                    } else {
                        setErrors(result.message)
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }

    }

    const fieldsArray = [
        { title: t("FROM_DATE"), name: "from_date", required: true, type: "date", style: " col-md-6 mt-2 float-left", placeholder: "DD-MM-YYYY" },
        { title: t("TO_DATE"), name: "to_date", required: true, type: "date", style: " col-md-6 mt-2 float-left", placeholder: "DD-MM-YYYY" },
    ]

    return (
        <Modal
            show={props.show}
            onHide={() => props.onHide(false)}
            size="xl"
            className=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={true}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='container' >
                    {errors !== undefined && errors.length !== 0 && <ErrorPopup
                        title={t("VALIDATION_ERROR") + ("!")}
                        body={(errors)}
                        onHide={() => setErrors([])}
                    ></ErrorPopup>}
                    {t("QR_CONFIGURATION")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                {errors !== undefined && errors.length !== 0 && <ErrorPopup
                    title={t("VALIDATION_ERROR") + ("!")}
                    body={(errors)}
                    onHide={() => setErrors([])}
                ></ErrorPopup>}
                {/* <Table columns={tableHeaders} rows={listData} tableName="qr_configuration"></Table> */}
                <div className="col-md-12 p-0"> <table className="col-md-12 table border" >
                    <thead>
                        <tr className="table-head-bg py-3 mb-2">
                            <th className="py-3 col-6">{t("LOCATION")}</th>
                            <th className="py-3 col-6">{t("MANUAL_REFRESH")}</th>
                            <th className="py-3 col-6">{t("ACTIONS")}</th>
                        </tr>
                    </thead>
                    {listData.length !== 0 ? listData.map((val, index) => {
                        return (
                            <>
                                <tr key={val.location_id} className="my-2">
                                    <td className="col-6 "><h6 className="my-auto">{val.location_name}</h6></td>
                                    <td className="col-6 "> <CustomCheckBox key={val.id} checkboxList={[{ key: val.location_id, value: val.location_id, checked: val.refresh_type == "manual" ? true : false }]} changeCheckbox={() => handleCheckBox(val, index)} disable={blockCheckbox}></CustomCheckBox> </td>
                                    <td className="col-6 ">
                                        <img className=" mr-2 planning-icon" data-tooltip-id={t("DOWNLOAD")} src={DownloadQRIcon} onClick={() => downloadAction(val, index)}></img>
                                        <ReactTooltip
                                            id={t("DOWNLOAD")}
                                            place="top"
                                            globalEventOff="click"
                                            content={t("DOWNLOAD")}
                                        />
                                        {val.refresh_type === 'manual' && <><img className="planning-icon-forword" data-tooltip-id={t("NEW_QR")} src={RegenerateQRIcon} onClick={() => refreshQR(val)}></img>
                                            <ReactTooltip
                                                id={t("NEW_QR")}
                                                place="top"
                                                globalEventOff="click"
                                                content={t("NEW_QR")}
                                            />
                                        </>}
                                    </td>
                                </tr>
                                {
                                    (locationId === val.location_id && index === locationIndex && isRefreshTypeDaily) &&
                                    <tr className="border-top-0 ">
                                        <td colSpan="3" className="border-top-0">
                                            <div className="w-100 d-flex border align-items-center pr-4">

                                                <FormsNew
                                                    data={fieldsArray}
                                                    SetValues={setValue}
                                                    formattedData={formData}
                                                    classname="w-100"
                                                />
                                                <div className="d-flex">
                                                    <Button className='button-style mt-2' onClick={() => { downloadQr(val); }}>
                                                        {t('DOWNLOAD')}
                                                    </Button>
                                                    <Button className='button-style mt-2 ml-1' onClick={() => setLocationIndex("")}>
                                                        {t('CANCEL')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </>)
                    }) : <td colSpan="3" className="text-center">No data...</td>}
                </table >
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='button-style' onClick={() => props.onHide(false)}>
                    {t('CLOSE')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}