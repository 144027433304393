import React from "react";
// import RequiredIcon from "../../../static/icons/exclamation.png"
import RequiredIcon from "../../../static/icons/exclamation-mark1.png"
import EyeIcon from "../../../static/icons/Eye.png"
import ClosedEyeIcon from "../../../static/icons/ClosedEye.png"
import { useState } from "react";


export default function TextInput({ title, name, placeholder, required, CustomStyle, value, setValue, error, styleMargin, age, disabled, eyeIcon }) {

    const [show, setShow] = useState(false)
    const handleClick = () => {
        setShow(!show)
    }

    return (
        <div className={"font-weight-bold " + CustomStyle}>
            <div className={"d-flex justify-content-between " + (error ? '' : styleMargin)} >
                <label className="row mx-0 my-auto">{title} {required && <p className="text-danger my-auto">&nbsp;*</p>} </label>
                {error && <p className="pt-1 pb-0 px-4 m-1 text-danger required-bg rounded font-weight-normal">
                    <img className="box mr-1 mb-1" src={RequiredIcon} alt="Required"></img>
                    {error}
                </p>}
            </div>
            <div className="input-group ">
                {eyeIcon && <input type={show ? "text" : "password"} className="form-control" placeholder={placeholder} name={name} value={value} onChange={(e) => setValue(e.target.value)} disabled={disabled} />}
                {!eyeIcon && <input type="text" className="form-control" placeholder={placeholder} name={name} value={value} onChange={(e) => setValue(e.target.value)} disabled={disabled} />}                {age && <div className="input-group-append">
                    <span className="input-group-text">%</span>
                </div>}
                {eyeIcon && <img className="planning-icon mr-1 mt-1 pointer eye_icon" onClick={() => handleClick()} title={""} src={show ? EyeIcon : ClosedEyeIcon} alt={""}></img>}
            </div>
        </div>
    )
}
