import React from 'react'

const DeviceActivate = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="118.663" height="87.661" viewBox="0 0 118.663 87.661">
        <path id="Device_activate" data-name="Device activate" d="M26.974,87.661a3.371,3.371,0,1,1,0-6.742H37.483l.844-6.743H10.113A10.128,10.128,0,0,1,0,64.059V53.945H84.56a17.542,17.542,0,0,0,6.9,20.218c-.144.009-.283.013-.425.013H62.823l.844,6.743H74.178a3.371,3.371,0,0,1,0,6.742Zm20.231-23.6a3.371,3.371,0,1,0,3.37-3.371A3.378,3.378,0,0,0,47.206,64.059ZM92.163,71.32a15.524,15.524,0,1,1,10.98,4.548A15.418,15.418,0,0,1,92.163,71.32Zm2.355-12.737a1.1,1.1,0,0,0-.495.679,1.107,1.107,0,0,0,.14.836l3.9,6.27.607.973,14.43-9.86a1.1,1.1,0,0,0-.622-2.009,1.055,1.055,0,0,0-.614.191l-12.54,8.56-3.285-5.286a1.1,1.1,0,0,0-.942-.523A1.072,1.072,0,0,0,94.518,58.583ZM0,50.574V10.113A10.119,10.119,0,0,1,10.113,0h80.92A10.117,10.117,0,0,1,101.15,10.113V42.06a17.612,17.612,0,0,0-15.024,8.514Z" fill="#073763" />
      </svg>


    </>
  )
}

export default DeviceActivate