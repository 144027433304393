import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { t } from "../../translations/Translation";
import CompanyOverviews from "../molecules/CompanyOverviews";
import 'react-tabs/style/react-tabs.css';

import BackIcon from "../../static/icons/BackIcon.png"

import { ToastContainer } from 'react-toastify';
import Rules from "../molecules/Rules";
import AddCompany from "../../static/icons/AddCompany";
import AddLocation from "../../static/icons/AddLocation";
import Workstation from "../../static/icons/Workstation";
import Salaries from "../../static/icons/Salaries";
import ContractType from "../../static/icons/ContractType";
import Switch from "../../components/atoms/Switch"
import AddCompanyGroup from "../../static/icons/AddCompanyGroup";

export default function OverviewTabs({ setCompany }) {

    const [addIcon, setAddIcon] = useState(<AddCompany />);
    const [addTitle, setAddTitle] = useState(t("ADD_COMPANY"));
    const [addUrl, setAddUrl] = useState('/manage-companies/company/0');
    const [tabIndex, setTabIndex] = useState(0);
    const [TabsData, setTabsData] = useState([{ tabHeading: t("COMPANY"), tabName: 'company', permission: "companies" }])

    const [companySelected, setCompanySelected] = useState(false);
    const [isArchived, setIsArchived] = useState(false);
    const [showAllCompanies, setShowAllCompanies] = useState(false);
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];


    const [addPermission, setAddPermission] = useState(UserPermissions?.includes('create_company') ? true : false);
    const [addGroup, setAddGroup] = useState(UserPermissions?.includes('create_group') ? true : true);
    const [addGroupTitles, setAddGroupTitles] = useState(t('ADD_GROUP'))
    const [addGroupUrl, setAddGroupUrl] = useState('/manage-company-groups')
    const [addGroupIcon, setAddGroupIcon] = useState(<AddCompanyGroup/>)


    const getRightHeaderContent = (tabName) => {

        if (tabName === 'company') {
            setAddIcon(<AddCompany />);
            setAddPermission(UserPermissions?.includes('create_company'))
            setAddTitle(t("ADD_COMPANY"));
            setAddUrl('/manage-companies/company/0');
        } else if (tabName === 'location') {
            setAddIcon(<AddLocation />);
            setAddTitle(t("ADD_LOCATION"));
            setAddPermission(UserPermissions?.includes('modify_locations'))
            setAddUrl('/manage-companies/location/0')
        } else if (tabName === 'workstation') {
            setAddIcon(<Workstation />);
            setAddPermission(UserPermissions?.includes('modify_workstations'))
            setAddTitle(t("ADD_WORKSTATION"));
            setAddUrl('/manage-companies/workstation/0')
        } else if (tabName === 'responsible_person') {
            setAddIcon(<Workstation />);
            setAddPermission(UserPermissions?.includes('modify_responsible_persons'))
            setAddTitle(t("ADD_RESPONSIBLE_PERSON"));
            setAddUrl('/manage-companies/responsible_person/0')
        } else if (tabName === 'cost center') {
            setAddIcon(<Salaries />);
            setAddPermission(UserPermissions?.includes('modify_cost_centers'))
            setAddTitle(t("ADD_COST_CENTER"));
            setAddUrl('/manage-companies/cost_center/0')
        } else if (tabName === 'company contract') {
            setAddIcon(<ContractType />)
            setAddPermission(UserPermissions?.includes('modify_contracts'))
            setAddTitle(t("ADD_CONTRACT"));
            setAddUrl('/add-contracts-template/company_contract');
        } else {
            setAddIcon('');
            setAddTitle('');
            setAddPermission(false);
        }
    }

    // const TabsData = [
    //     { tabHeading: t("COMPANY"), tabName: 'company' },
    //     { tabHeading: t("LOCATIONS"), tabName: 'location' },
    //     { tabHeading: t("WORKSTATION"), tabName: 'workstation' },
    //     { tabHeading: t("COST_CENTER"), tabName: 'cost center' },
    //     { tabHeading: t("CONTRACTS"), tabName: 'contracts' },
    //     { tabHeading: t("DIMONA"), tabName: 'dimona' },
    //     { tabHeading: t("RULES"), tabName: 'rules' },
    // ]

    useEffect(() => {
        if (window.location.hash !== '' && window.location.hash !== '#') {
            onCompanyView(false, true)
            if (window.location.hash === '#location') {
                setCompanySelected(true);
                setTabIndex(0);
                getRightHeaderContent('location');
                window.location.hash = ''
            } else if (window.location.hash === '#workstation') {
                setTabIndex(1);
                getRightHeaderContent('workstation');
                setCompanySelected(true);
                window.location.hash = ''
            } else if (window.location.hash === '#responsible_person') {
                setTabIndex(2);
                getRightHeaderContent('responsible_person');
                setCompanySelected(true);
                window.location.hash = ''
            } else if (window.location.hash === '#cost_center') {
                setTabIndex(3)
                getRightHeaderContent('cost center');
                setCompanySelected(true);
                window.location.hash = ''
            } else if (window.location.hash === '#company_contract') {
                setTabIndex(4)
                getRightHeaderContent('company contract');
                setCompanySelected(true);
                window.location.hash = ''
            } else if (window.location.hash === '#company' || window.location.hash === '#company-single' || window.location.hash === '#company-view') {
                onCompanyView(false, false)
                setCompanySelected(false);
                setTabIndex(0);
                getRightHeaderContent('company');
                window.location.hash = ''
            }
            window.location.hash = ''
        }
    }, [])

    const onCompanyView = (company_status, status) => {
        if (company_status || status) {
            if (!status) { getRightHeaderContent('location'); }

            setTabsData([
                { tabHeading: t("LOCATIONS"), tabName: 'location', permission: 'locations_overview' },
                { tabHeading: t("WORKSTATION"), tabName: 'workstation', permission: 'workstations_overview' },
                { tabHeading: t("RESP_PERSON"), tabName: 'responsible_person', permission: 'responsible_persons_overview' },
                { tabHeading: t("COST_CENTER"), tabName: 'cost center', permission: 'cost_centers_overview' },
                { tabHeading: t("CONTRACTS"), tabName: 'company contract', permission: 'cost_centers_overview' },
                { tabHeading: t("DIMONA"), tabName: 'dimona', permission: 'dimona_activation_overview' },
                { tabHeading: t("RULES"), tabName: 'rules', permission: 'rules_overview' },
                { tabHeading: t("CONFIGURATION"), tabName: 'configuration', permission: 'reporting_configuration_overview' },
            ])
        } else {
            getRightHeaderContent('company')
            // setTabIndex(0)
            setTabsData([
                { tabHeading: t("COMPANY"), tabName: 'company', permission: 'companies' },
            ])
        }
    }


    return (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <TabList>
                <div className="d-flex align-items-center manage_company_header">
                    <div className="w-100 d-flex">
                        {TabsData.map((val) => {
                            if (UserPermissions?.includes(val.permission)) {
                                return (
                                    <Tab className="manage_company_tabs" key={val.tabName} onClick={() => getRightHeaderContent(val.tabName)} >{val.tabHeading}</Tab>
                                )
                            }
                        })}
                    </div>

                    {addIcon && <div className=" border-0  add_company_block">
                        <div className="d-flex justify-content-end mx-3">
                            {addTitle === t("ADD_COMPANY") && !isArchived && <div><Switch label={t("SHOW_ALL_COMPANIES")} id="switch4" lableClick={true} styleClass="text-nowrap mb-0 pr-3 " checked={showAllCompanies} onChange={() => setShowAllCompanies(!showAllCompanies)} ></Switch></div>}
                            {addTitle === t("ADD_COMPANY") && <div><Switch label={t("ARCHIVED")} id="switch3" lableClick={true} styleClass="text-nowrap mb-0 px-3 " onChange={() => setIsArchived(!isArchived)} checked={isArchived}></Switch></div>}
                            {/* {addTitle !== t("ADD_COMPANY") && <div><p className="mb-0 text-dark text-nowrap pr-3" onClick={() => setCompanySelected(false)}><img className="header-icon mr-2 pointer" src={BackIcon}></img>{t("COMPANY_OVERVIEW")}</p></div>} */}
                            {addTitle===t('ADD_COMPANY')&&addGroup && <div className="align-self-center mx-2"><a href={addGroupUrl}><span className="mb-0 text-nowrap mt-5 add_btn">{addGroupIcon}<span>{addGroupTitles}</span></span></a></div>}
                            {addPermission && <div className="align-self-center"><a href={addUrl}><span className="mb-0 text-nowrap mt-5 add_btn">{addIcon}<span>{addTitle}</span></span></a></div>}
                            {/* {addTitle !== 'Add company' &&<p className="mb-0 mr-3 text-dark"><img className="header-icon mr-2" src={addIcon}></img>{addTitle}</p>} */}
                            {/* <img src={FilterIcon} className="header-icon ml-2"></img>
                        <img src={ExportIcon} className="header-icon ml-2"></img> */}
                        </div>
                    </div>}
                    {/* {!addIcon && <div className=" border-0  add_company_block">
                        <div className="d-flex justify-content-end mx-3">
                            <div><p className="mb-0 text-dark text-nowrap pr-3" onClick={() => {setCompanySelected(false);setTabIndex(0)}}><img className="header-icon mr-2 pointer" src={BackIcon}></img>{t("COMPANY_OVERVIEW")}</p></div>
                        </div>
                    </div>
                    } */}
                </div>

            </TabList>

            <div>
                {!companySelected && <TabPanel>
                    <div className="tablescroll"><CompanyOverviews setCompany={setCompany} overviewContent={'company'} setCompanySelected={setCompanySelected} onCompanyView={onCompanyView} isArchived={isArchived} showAllCompanies={showAllCompanies} EditPermission={UserPermissions?.includes('modify_company_details')}></CompanyOverviews></div>
                </TabPanel>}

                <TabPanel>
                    <div className="tablescroll"><CompanyOverviews setCompany={setCompany} overviewContent={'location'} EditPermission={UserPermissions?.includes('modify_locations')}></CompanyOverviews></div>
                </TabPanel>

                <TabPanel>
                    <div className="tablescroll"><CompanyOverviews setCompany={setCompany} overviewContent={'workstation'} EditPermission={UserPermissions?.includes('modify_workstations')}></CompanyOverviews></div>
                </TabPanel>
                <TabPanel>
                    <div className="tablescroll"><CompanyOverviews setCompany={setCompany} overviewContent={'responsible_person'} EditPermission={UserPermissions?.includes('modify_responsible_persons')}></CompanyOverviews></div>
                </TabPanel>
                <TabPanel>
                    <div className="tablescroll"><CompanyOverviews setCompany={setCompany} overviewContent={'cost center'} EditPermission={UserPermissions?.includes('modify_cost_centers')}></CompanyOverviews></div>
                </TabPanel>

                <TabPanel>
                    <h3 className="text-center mt-3"><CompanyOverviews setCompany={setCompany} overviewContent={'contracts'} EditPermission={UserPermissions?.includes('modify_contracts')}></CompanyOverviews></h3>
                </TabPanel>

                <TabPanel>
                    <div className="text-center mt-3 "><CompanyOverviews setCompany={setCompany} overviewContent={'dimona'} setCompanySelected={setCompanySelected} setTabIndex={setTabIndex} EditPermission={UserPermissions?.includes('modify_dimona_activation')}></CompanyOverviews></div>
                </TabPanel>

                <TabPanel>
                    {/* <div className="tablescroll"> */}
                    <Rules overviewContent={'rules'} EditPermission={UserPermissions?.includes('modify_rules')}></Rules>
                    {/* </div> */}
                </TabPanel>
                <TabPanel>
                    <div className="bg-transparent"><CompanyOverviews setCompany={setCompany} overviewContent={'configuration'} setCompanySelected={setCompanySelected} setTabIndex={setTabIndex} EditPermission={UserPermissions?.includes('modify_reporting_configuration')}></CompanyOverviews></div>
                </TabPanel>
            </div>
        </Tabs>
    )
}
