import React, { useEffect, useState } from "react";
import ModalPopup from "../../utilities/popup/Popup";
import { t } from "../../translations/Translation";
import DateInput from "../atoms/formFields/DateInput";
import Table from "../atoms/Table";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetDimonaPlansApiUrl, SendDimonaApiUrl } from "../../routes/ApiEndPoints";
import { toast } from "react-toastify";
import ErrorPopup from "../../utilities/popup/ErrorPopup";



export default function SendDimonaPopup({ setDimonaStatus, date, setDate, selectedLocation }) {

    const [listData, setListData] = useState([]);
    const [dimonaData, setDimonaData] = useState([]);
    const [dateArray, setDateArray] = useState([date.toString()]);
    const [errors, setErrors] = useState([]);

    const headers = [
        {
            title: t("EMPLOYEE_TITLE"),
            field: 'name',
            size: 200,
        },
        {
            title: t("EMPLOYEE_TYPE"),
            field: 'employee_type',
            size: 200,
        },
        {
            title: t("PLAN_DATE"),
            field: 'date',
            size: 200,
        },
        {
            title: t("PLAN_TIMINGS"),
            field: 'timings',
            size: 200,
        },
    ]

    useEffect(() => {
        let requestData = {
            "location_id": selectedLocation?.value,
            "dates": dateArray
        }
        AXIOS.service(GetDimonaPlansApiUrl, 'POST', requestData)
            .then((result) => {
                if (result?.success) {
                    setListData(result.data)
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [dateArray.length, selectedLocation])

    const SendDimona = () => {
        let plans = []
        dimonaData.map((val, i) => {
            plans.push(val.id)
        })
        let req_data = {
            "plans": plans,
        }
        AXIOS.service(SendDimonaApiUrl, 'POST', req_data)
            .then((result) => {
                if (result?.success) {
                    setDimonaStatus(false)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result?.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const setValue = (value) => {
        // let currentDate = date.toString();
        let arr = [...dateArray]
        if (value !== null) {
            value.map((date, i) => {
                let formattedDate = date?.format("DD-MM-YYYY")
                if (!arr.includes(formattedDate)) {
                    arr.push(formattedDate);
                } else if (arr.includes(formattedDate)) {
                    arr = arr.filter(item => item === formattedDate);
                }
            })
        }
        setDateArray(arr);
    }

    // const isDateEqualOrGreater = (dateString, minDateString) => {
    //     const [day, month, year] = dateString.split("-");
    //     const [minDay, minMonth, minYear] = minDateString.split("-");
    //     const date = new Date(year, month - 1, day);
    //     const minDate = new Date(minYear, minMonth - 1, minDay);
    //     return date >= minDate;
    // }


    return (
        <ModalPopup
            size="lg"
            title={t('SEND_DIMONA')}
            body={
                <div>
                    {errors !== undefined && errors.length !== 0 && <ErrorPopup
                        title={t("VALIDATION_ERROR") + ("!")}
                        body={(errors)}
                        onHide={() => setErrors([])}
                    ></ErrorPopup>}
                    <DateInput
                        key={'date'}
                        title={'Date'}
                        name={'date'}
                        CustomStyle={'w-50 mb-2'}
                        value={dateArray}
                        setValue={(e) => setValue(e)}
                        isMulti={true}
                        // minDate={date}
                    ></DateInput>
                    <Table tableName={'send_dimona'} columns={headers} rows={listData} setDimonaData={setDimonaData} ></Table>
                </div>
            }
            onConfirm={() => SendDimona()}
            startplanButton={t('SEND')}
            onHide={() => { setDimonaStatus(false) }}
            close={true}
        ></ModalPopup>
    )
}
