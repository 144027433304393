import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { t } from "../../translations/Translation";
import { toast } from 'react-toastify';
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import FormsNew from "./FormsNew";

export default function UpdateIdCardPopup(props) {

    const [errors, setErrors] = useState([])
    const [formData, setFormData] = useState({
        "id_card_front": "",
        "id_card_back": "",
    })

    const setValues = (index, name, value) => {
        let newdata = { ...formData }
        newdata[name] = value
        setFormData(newdata)

    }

    const onConfirm = () => {

      let fileData = new FormData()
      fileData.append("employee_profile_id", props.eid)
      fileData.append('id_card_front', formData.id_card_front)
      fileData.append('id_card_back', formData.id_card_back)
    //     AXIOS.service(url, "POST", fileData, true)
    //         .then((result) => {
    //             if (result?.success) {
    //                 props.setShow(false)
    //                 props.setDataRefresh(!props.dataRefresh)
    //                 toast.success(result.message[0], {
    //                     position: "top-center",
    //                     autoClose: 2000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "colored",
    //                 });
    //             } else {
    //                 setErrors(result.message)
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    }

    let fieldsArray = [
        { title: t("ID_CARD_FRONT"), name: "id_card_front", required: false, type: "file", style: "col-md-6 mt-4 float-left" },
        { title: t("ID_CARD_BACK"), name: "id_card_back", required: false, type: "file", style: "col-md-6 mt-4 float-left" },
    ];


    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="xl"
            className=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('UPDATE_ID_CARD')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="popup-tabs border">
                    {errors !== undefined && errors.length !== 0 && <ErrorPopup
                        title={t("VALIDATION_ERROR") + ("!")}
                        body={(errors)}
                        onHide={() => setErrors([])}
                    ></ErrorPopup>}
                    <FormsNew
                        formTitle={''}
                        formattedData={formData}
                        data={fieldsArray}
                        SetValues={setValues}
                    ></FormsNew>

                </div>
            </Modal.Body>
            <Modal.Footer>
               <Button className='button-style float-left' onClick={() => onConfirm()}>
                    {t("SAVE")}
                </Button>
                <Button className='button-style' onClick={props.onHide}>
                    {props.buttonName ? (props.buttonName) : t("CANCEL")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
